/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "client/resources/actionExecutionLocation";
import { ExpandableFormSection, Summary, SummaryNode } from "components/form";
import Note from "components/form/Note/Note";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import { repository } from "clientInstance";
import { TeamResource } from "client/resources/teamResource";
import Text, { DebounceText } from "components/form/Text/Text";
import Select from "components/form/Select/Select";
import StringEditList from "../../EditList/StringEditList";
import { TargetRoles } from "areas/projects/components/Process/types";
import { VariableLookupText } from "components/form/VariableLookupText";

class DockerNetworkActionSummary extends BaseComponent<ActionSummaryProps, any> {
    render() {
        return (
            <div>
                Create a Docker Network
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

interface DockerNetworkProperties {
    "Octopus.Action.Docker.Args": string;
    "Octopus.Action.Docker.NetworkType": string;
    "Octopus.Action.Docker.NetworkCustomDriver": string;
    "Octopus.Action.Docker.NetworkSubnet": string;
    "Octopus.Action.Docker.NetworkIPRange": string;
    "Octopus.Action.Docker.NetworkGateway": string;
}

interface DockerNetworkEditState {
    teams: TeamResource[];
}
const NetworkTypes = {
    bridge: {
        name: "Bridge (Default)",
        description: "Connect the container to the bridge via veth interfaces.",
    },
    other: {
        name: "Custom",
        description: "Use an installed third party or own custom network driver.",
    },
};

class DockerNetworkAction extends BaseComponent<ActionEditProps<DockerNetworkProperties>, DockerNetworkEditState> {
    constructor(props: ActionEditProps<DockerNetworkProperties>) {
        super(props);
        this.state = {
            teams: [],
        };
    }

    async componentDidMount() {
        if (!this.props.properties["Octopus.Action.Docker.NetworkType"]) {
            this.props.setProperties({ ["Octopus.Action.Docker.NetworkType"]: "bridge" }, true);
        }

        await this.props.doBusyTask(async () => {
            const teams = await repository.Teams.all();
            this.setState({ teams });
        });
    }

    render() {
        return (
            <div>
                <ExpanderSectionHeading title="Networking Options" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Docker.NetworkType"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Network Type"
                    summary={this.networkTypeSummary()}
                    help="Proxy used to communicate with this deployment target."
                >
                    <Select
                        label="Network type"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Docker.NetworkType"]: val })}
                        value={this.props.properties["Octopus.Action.Docker.NetworkType"]}
                        items={Object.keys(NetworkTypes).map(nt => ({ value: nt, text: (NetworkTypes as any)[nt].name }))}
                    />
                    {this.props.properties["Octopus.Action.Docker.NetworkType"] && <Note>{(NetworkTypes as any)[this.props.properties["Octopus.Action.Docker.NetworkType"]].description}</Note>}
                    {this.props.properties["Octopus.Action.Docker.NetworkType"] === "other" && (
                        <DebounceText label="Custom Driver" onChange={val => this.props.setProperties({ ["Octopus.Action.Docker.NetworkCustomDriver"]: val })} value={this.props.properties["Octopus.Action.Docker.NetworkCustomDriver"]} />
                    )}
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="Octopus.Action.Docker.NetworkSubnet"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Subnets"
                    summary={this.propertySummary("Octopus.Action.Docker.NetworkSubnet", "No specific subnet specified")}
                    help={"Subnet in CIDR format that represents a network segment. On a bridge network you can only specify a single subnet"}
                >
                    <StringEditList
                        items={this.props.properties["Octopus.Action.Docker.NetworkSubnet"]}
                        label="Subnet"
                        hintText="CIDR format (e.g. 172.28.5.0/24)"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Docker.NetworkSubnet"]: val })}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Docker.NetworkIPRange"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="IP Range"
                    summary={this.propertySummary("Octopus.Action.Docker.NetworkIPRange", "No specific IP range specified")}
                    help={"Allocate container ip from a sub-range."}
                >
                    <StringEditList
                        items={this.props.properties["Octopus.Action.Docker.NetworkIPRange"]}
                        label="IP range"
                        hintText="CIDR format (e.g. 172.28.5.0/24)"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Docker.NetworkIPRange"]: val })}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Docker.NetworkGateway"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Network Gateway"
                    summary={this.propertySummary("Octopus.Action.Docker.NetworkGateway", "No specific network gateway specified")}
                    help={"IPv4 or IPv6 gateway for the master subnet. By default the Docker engine will select one from inside a preferred pool."}
                >
                    <StringEditList
                        items={this.props.properties["Octopus.Action.Docker.NetworkGateway"]}
                        label="Network gateway"
                        hintText="IPv4 or IPv6 (e.g. 172.28.5.1)"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Docker.NetworkGateway"]: val })}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Docker.Args"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Additional Arguments"
                    summary={this.propertySummary("Octopus.Action.Docker.Args", "No additional arguments specified")}
                    help={
                        <span>
                            Provide any other arguments that will be passed to the <code>docker network create</code> command.
                        </span>
                    }
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        label="Additional arguments"
                        multiLine={true}
                        value={this.props.properties["Octopus.Action.Docker.Args"]}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Docker.Args"]: val })}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    propertySummary(property: string, defaultValue: any): SummaryNode {
        const subnet = (this.props.properties as any)[property];
        return subnet ? Summary.summary(subnet) : Summary.placeholder(defaultValue);
    }

    networkTypeSummary(): SummaryNode {
        const type = this.props.properties["Octopus.Action.Docker.NetworkType"];
        if (!type) {
            return Summary.default(NetworkTypes.bridge.description);
        }
        if (type === "other") {
            return Summary.summary(this.props.properties["Octopus.Action.Docker.NetworkCustomDriver"] + " (custom)");
        }
        return Summary.summary((NetworkTypes as any)[type].description);
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.DockerNetwork",
    targetRoleOption: action => TargetRoles.Optional,
    summary: (properties, targetRolesAsCSV) => <DockerNetworkActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: step => true,
    canBeChild: true,
    edit: DockerNetworkAction,
});
