/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { defaultExpanderContainer, expanderActions, ExpanderContainer, UseInitialValueOfContainer } from "components/form/Sections/reducers/expanders";

interface StateProps {
    expanders: { [errorKey: string]: boolean | undefined };
    getExpanderValueForKey(key: string): boolean | undefined;
}

interface GlobalDispatchProps {
    onExpandedChanged(key: string, isExpanded: boolean): void;
    registerAllExpanders(keys: string[]): void;
}

export type GroupedExpandableProps = StateProps & GlobalDispatchProps;

// HOC that hooks up a component that manages expansion for multiple child components
// to the redux store
export default function Expandable<Props extends any>(containerKey: string, Comp: React.ComponentClass<Props & GroupedExpandableProps>) {
    type InternalProps = StateProps & GlobalDispatchProps & Props;
    type ExternalProps = Omit<InternalProps, keyof GroupedExpandableProps>;

    class ExpandableInternal extends React.Component<InternalProps> {
        render() {
            return <Comp {...this.props} />;
        }
    }

    function mapGlobalStateToProps(state: GlobalState): StateProps {
        const container: ExpanderContainer = state.expanders?.[containerKey] ?? defaultExpanderContainer();
        const expanders = Object.keys(container.expanderValues).reduce((values: { [key: string]: boolean }, expanderKey) => {
            values[expanderKey] = getExpanderValueForKey(expanderKey);
            return values;
        }, {});

        function getExpanderValueForKey(expanderKey: string) {
            const lowercaseKey = expanderKey.toLowerCase();
            const storedValue = container.expanderValues[lowercaseKey];
            return storedValue === UseInitialValueOfContainer ? container.initialState : storedValue;
        }

        return { expanders, getExpanderValueForKey };
    }

    function mapGlobalActionDispatchersToProps(dispatch: Dispatch<Action<any>>, props: InternalProps): GlobalDispatchProps {
        return {
            registerAllExpanders: (keys: string[]) => {
                dispatch(expanderActions.onAllExpandersCreated({ containerKey, keys: keys.map(x => x.toLowerCase()), expanded: false }));
            },
            onExpandedChanged: (key: string, state: boolean) => {
                dispatch(expanderActions.onExpanderStateChanged({ containerKey, key: key.toLowerCase(), expanded: state }));
            },
        };
    }

    return (connect<StateProps, GlobalDispatchProps, Props>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ExpandableInternal) as any) as React.ComponentType<ExternalProps>;
}
