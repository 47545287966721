/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import * as PropTypes from "prop-types";
import DropDownMenu from "./DropDownMenu";
import { normal } from "fontWeights";
import { OctopusTheme, withTheme } from "components/Theme";

function getStyles(theme: OctopusTheme): Record<string, React.CSSProperties> {
    return {
        root: {
            marginTop: 0,
        },
        label: {
            paddingLeft: 0,
            top: 8,
        },
        floatingLabelStyle: {
            position: "absolute", // This gives us the floating label.
            fontWeight: normal,
            color: theme.secondaryText,
            lineHeight: "0.75rem",
            transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
            fontSize: "0.75rem",
        },
        hintLabelStyle: {
            position: "absolute",
            fontWeight: normal,
            color: theme.secondaryText,
            lineHeight: "1rem",
            fontSize: "1rem",
            top: "1.5rem",
        },
        icon: {
            right: 0,
            top: 0,
            marginTop: 0,
            fill: theme.secondaryText,
        },
        hideDropDownUnderline: {
            borderTop: "none",
        },
        dropDownMenu: {
            display: "block",
            borderBottom: `1px solid ${theme.secondaryText}`,
        },
        error: {
            color: theme.dangerText,
        },
        container: {
            position: "relative", // Needed for absolute positioned elements.
            margin: "0 0 1rem 0", // <-- this causes issues with the alignment of our various filters
            //at the top of pages since they're all different types of component with different
            // vertical margins and padding, but is needed to vertically space out the advanced filters list.
        },
    };
}

interface SelectFieldProps {
    floatingLabelText?: string | JSX.Element;
    errorText?: string;
    hintText?: string;
    className?: string;
    allowClear?: boolean;
    autoWidth?: boolean;
    multiple?: boolean;
    children?: any;
    style?: any;
    labelStyle?: any;
    iconStyle?: any;
    id?: string;
    underlineDisabledStyle?: any;
    menuItemStyle?: any;
    selectedMenuItemStyle?: any;
    dropDownMenuProps?: any;
    disabled?: boolean;
    listStyle?: any;
    maxHeight?: number;
    menuStyle?: any;
    onFocus?: any;
    onBlur?: any;
    onChange?: any;
    selectionRenderer?: any;
    value: string;
    type?: any;
    filter?: any;
    autoFocus?: boolean;
}

class SelectField extends React.Component<SelectFieldProps, any> {
    static defaultProps: Partial<SelectFieldProps> = {
        autoWidth: false,
        disabled: false,
        multiple: false,
    };

    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    render() {
        return withTheme(theme => {
            const {
                allowClear,
                autoWidth,
                multiple,
                children,
                style,
                labelStyle,
                iconStyle,
                id,
                underlineDisabledStyle,
                menuItemStyle,
                selectedMenuItemStyle,
                dropDownMenuProps,
                disabled,
                listStyle,
                maxHeight,
                menuStyle,
                onFocus,
                onBlur,
                onChange,
                selectionRenderer,
                value,
                type,
                filter,
                autoFocus,
                floatingLabelText,
                errorText,
                hintText,
                ...rest
            } = this.props;

            if (selectedMenuItemStyle && !selectedMenuItemStyle.color) {
                selectedMenuItemStyle.color = theme.primary;
            }

            const styles = getStyles(theme);

            const floatingLabelTextElement = floatingLabelText && <div style={styles.floatingLabelStyle}>{floatingLabelText}</div>;

            const hintLabelTextElement = !value && hintText && <div style={styles.hintLabelStyle}>{hintText}</div>;

            const errorTextElement = errorText && <div style={styles.error}>{errorText}</div>;

            return (
                <div style={styles.container} className={this.props.className} role="combobox">
                    {floatingLabelTextElement}
                    {hintLabelTextElement}
                    <DropDownMenu
                        allowClear={allowClear}
                        disabled={disabled}
                        style={{ ...styles.dropDownMenu, ...menuStyle }}
                        labelStyle={{ ...styles.label, ...labelStyle }}
                        iconStyle={{ ...styles.icon, ...iconStyle }}
                        menuItemStyle={menuItemStyle}
                        selectedMenuItemStyle={selectedMenuItemStyle}
                        underlineStyle={styles.hideDropDownUnderline}
                        listStyle={listStyle}
                        autoWidth={autoWidth}
                        value={value}
                        onChange={onChange}
                        maxHeight={maxHeight}
                        multiple={multiple}
                        selectionRenderer={selectionRenderer}
                        filter={filter}
                        autoFocus={autoFocus}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        id={id}
                        {...rest}
                        {...dropDownMenuProps}
                    >
                        {children}
                    </DropDownMenu>
                    {errorTextElement}
                </div>
            );
        });
    }
}

export default SelectField;
