import * as React from "react";
import { TagResource, TagSetResource } from "client/resources";
import * as tenantTagsets from "components/tenantTagsets";
import Tag from "components/Tag";
import { MissingChip, ChipIcon } from "components/Chips";

export interface SmallTagsListProps {
    tags: string[];
}

interface SmallTagsListState {
    loaded: boolean;
    tagIndex: { [canonicalTagName: string]: TagResource };
    tagSets: TagSetResource[];
}

export default class TenantTagsList extends React.Component<SmallTagsListProps, SmallTagsListState> {
    constructor(props: SmallTagsListProps) {
        super(props);
        this.state = { tagIndex: {}, tagSets: [], loaded: false };
    }

    async componentDidMount() {
        const tagSets = await tenantTagsets.getAll();
        this.setState({
            tagIndex: tenantTagsets.getTagIndexForTagSets(tagSets),
            tagSets,
            loaded: true,
        });
    }

    render() {
        if (!this.state.tagIndex || !this.props.tags || this.props.tags.length === 0 || !this.state.loaded) {
            return null;
        }

        const groupedTagsets = tenantTagsets.groupAndOrderByTagSetAndTag(this.props.tags, this.state.tagSets);

        const items: React.ReactNode[] = [];
        groupedTagsets.forEach(tagSet => {
            const missingTags = tagSet.tags;
            if (items.length > 0) {
                items.push(<span key={items.length}> and </span>);
            }
            if (missingTags.length === 1) {
                const tag = this.state.tagIndex[missingTags[0]];

                if (!tag) {
                    items.push(<MissingChip lookupId={missingTags[0]} type={ChipIcon.Tenant} />);
                    return;
                }

                items.push(<Tag key={items.length} description={tag.Description} tagName={tag.Name} tagColor={tag.Color} small={false} />);
                return;
            }

            if (groupedTagsets.length > 1) {
                items.push(<span key={items.length}>{`(`}</span>);
            }

            for (let index = 0; index < missingTags.length; index++) {
                const tag = this.state.tagIndex[missingTags[index]];
                if (index === missingTags.length - 1) {
                    items.push(<span key={items.length}> or </span>);
                } else if (index !== 0) {
                    items.push(<span key={items.length}>, </span>);
                }

                if (!tag) {
                    items.push(<MissingChip lookupId={missingTags[index]} type={ChipIcon.Tenant} />);
                    return;
                }

                items.push(<Tag key={items.length} tagName={tag.Name} description={tag.Description} tagColor={tag.Color} small={false} />);
            }

            if (groupedTagsets.length > 1) {
                items.push(<span key={items.length}>)</span>);
            }
        });

        return <span>{items}</span>;
    }
}
