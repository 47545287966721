/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { flatten, compact } from "lodash";
import { repository } from "clientInstance";
import { ScopeValues, VariableSetResource } from "client/resources/variableSetResource";
import { LibraryVariableSetResource } from "client/resources/libraryVariableSetResource";
import { ProjectResource } from "client/resources/projectResource";
import { FilterableVariableDisplayer } from "areas/variables/VariableDisplayer/FilterableVariableDisplayer";
import mergeScopeValues from "areas/variables/MergeScopeValues";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { createErrorsFromOctopusError, Errors } from "components/DataBaseComponent/Errors";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { ISnapshotResource, isReleaseResource, isRunbookSnapshotResource } from "client/resources/releaseResource";
import { CalloutType, default as Callout } from "components/Callout/Callout";
import { OctopusError, ProcessType } from "client/resources";
import Note from "components/form/Note/Note";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import { Section } from "components/Section/Section";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { convertVariableResourcesToVariablesWithSource } from "../../../../variables/convertVariableResourcesToVariablesWithSource";
import { VariableWithSource } from "../../../../variables/VariableDisplayer";
import ActionList from "components/ActionList/ActionList";
import { UpdateVariablesMessage } from "areas/projects/components/Releases/UpdateVariables/UpdateVariables";

interface VariableSnapshotProps {
    projectId: string;
    snapshot: ISnapshotResource;
    doBusyTask: DoBusyTask;
    updateVariablesRefreshKey: string;
    onUpdate?(): void;
}

interface VariableSnapshotState {
    model?: Model;
    showConfirmationDialog: boolean;
    variableSetErrors?: Errors;
    showVariables: boolean;
}

interface Model {
    readonly project: ProjectResource;
    readonly release: ISnapshotResource;
    readonly projectVariables: VariableSetResource;
    readonly libraryVariableSetVariables: ReadonlyArray<VariableSetResource>;
    readonly libraryVariableSets: ReadonlyArray<LibraryVariableSetResource>;
}

export default class VariableSnapshot extends BaseComponent<VariableSnapshotProps, VariableSnapshotState> {
    constructor(props: VariableSnapshotProps) {
        super(props);
        this.state = { showConfirmationDialog: false, showVariables: false };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            await this.loadData(this.props.snapshot);
        });
    }

    async componentWillReceiveProps(nextProps: VariableSnapshotProps) {
        if (nextProps.updateVariablesRefreshKey !== this.props.updateVariablesRefreshKey) {
            await this.props.doBusyTask(async () => {
                await this.loadData(nextProps.snapshot);
            });
        }
    }

    render() {
        const hasVariables = this.getVariables().length > 0;
        const showHideSnapshot = (
            <ActionButton
                label={this.state.showVariables ? "Hide Snapshot" : "Show Snapshot"}
                type={ActionButtonType.Ternary}
                onClick={() => {
                    this.setState({ showVariables: !this.state.showVariables });
                }}
            />
        );
        const updateSnapshot = (
            <ActionButton
                label="Update variables"
                onClick={() => {
                    this.setState({ showConfirmationDialog: true });
                }}
            />
        );

        let processType: ProcessType | undefined;
        if (isReleaseResource(this.props.snapshot)) {
            processType = ProcessType.Deployment;
        } else if (isRunbookSnapshotResource(this.props.snapshot)) {
            processType = ProcessType.Runbook;
        }
        const snapshotTerm = processType === ProcessType.Deployment ? "release" : "snapshot";
        const owner = processType === ProcessType.Deployment ? "project" : "runbook";
        return (
            <div>
                <FormSectionHeading title="Variable Snapshot" />
                <Section sectionHeader="">
                    <Note>
                        <p>
                            When this {snapshotTerm} was created, a snapshot of the project variables was taken. You can overwrite the variable snapshot by re-importing the variables from the {owner}.
                        </p>
                        {hasVariables ? (
                            <ActionList actions={[showHideSnapshot, updateSnapshot]} />
                        ) : (
                            <>
                                <p>No variable snapshot exists.</p>
                                <ActionList actions={[updateSnapshot]} />
                            </>
                        )}
                    </Note>
                </Section>
                {this.state.showVariables && (
                    <div>
                        {this.state.variableSetErrors && (
                            <Callout type={CalloutType.Warning} title={this.state.variableSetErrors.message}>
                                {this.state.variableSetErrors.errors}
                            </Callout>
                        )}
                        <FilterableVariableDisplayer availableScopes={this.availableScopes} isProjectScoped={true} variableSections={[this.getVariables()]} doBusyTask={this.props.doBusyTask} />
                    </div>
                )}
                <ConfirmationDialog
                    title="Confirm Variable Update"
                    continueButtonLabel="Update variables"
                    continueButtonBusyLabel="Saving..."
                    open={this.state.showConfirmationDialog}
                    onClose={() => this.setState({ showConfirmationDialog: false })}
                    onContinueClick={() => this.updateVariables()}
                >
                    <UpdateVariablesMessage processType={processType!} />
                </ConfirmationDialog>
            </div>
        );
    }

    private async loadData(release: ISnapshotResource) {
        const project = await repository.Projects.get(this.props.projectId);
        const projectVariablesPromise = await this.loadProjectVariables(release);
        const libraryVariableSetVariables = await this.loadLibraryVariableSetVariables(release)!;
        const libraryVariableSets = await repository.LibraryVariableSets.all({ ids: libraryVariableSetVariables.map(v => v!.OwnerId) }!);
        this.setState({
            model: {
                project: project!,
                release,
                projectVariables: projectVariablesPromise!,
                libraryVariableSetVariables,
                libraryVariableSets,
            },
        });
    }

    private getVariables(): ReadonlyArray<VariableWithSource> {
        if (!this.state.model) {
            return [];
        }

        const projectSource = {
            projectName: this.state.model.project.Name,
            projectId: this.props.projectId,
        };

        const projectVariables = convertVariableResourcesToVariablesWithSource(this.state.model.projectVariables.Variables, projectSource);
        const libraryVariables = flatten(
            this.state.model.libraryVariableSetVariables.map(vSet => {
                const libraryVariableSet = this.state.model!.libraryVariableSets.find(s => s.Id === vSet.OwnerId)!;
                const variableSetSource = {
                    variableSetName: libraryVariableSet.Name,
                    variableSetId: libraryVariableSet.Id,
                };
                return convertVariableResourcesToVariablesWithSource(vSet.Variables, variableSetSource);
            })
        );
        return [...projectVariables, ...libraryVariables];
    }

    private loadProjectVariables(release: ISnapshotResource) {
        return repository.Variables.get(release.ProjectVariableSetSnapshotId);
    }

    private async loadLibraryVariableSetVariables(release: ISnapshotResource) {
        const variableSets = await Promise.all(
            release.LibraryVariableSetSnapshotIds.map(async id => {
                try {
                    return await repository.Variables.get(id);
                } catch (ex) {
                    if (ex instanceof OctopusError) {
                        const errors = createErrorsFromOctopusError(ex);
                        this.setState({ variableSetErrors: errors });
                    }
                    return null;
                }
            })
        );
        return compact(variableSets);
    }

    private get availableScopes(): ScopeValues {
        const allScopeValues: ScopeValues[] = this.state.model ? [this.state.model.projectVariables.ScopeValues, ...this.state.model.libraryVariableSetVariables.map(set => set.ScopeValues)] : [];
        return mergeScopeValues(allScopeValues);
    }

    private async updateVariables() {
        await this.props.doBusyTask(async () => {
            if (isReleaseResource(this.props.snapshot)) {
                const updatedRelease = await repository.Releases.snapshotVariables(this.props.snapshot);
                await this.loadData(updatedRelease);
            } else if (isRunbookSnapshotResource(this.props.snapshot)) {
                const updatedRelease = await repository.RunbookSnapshots.snapshotVariables(this.props.snapshot);
                await this.loadData(updatedRelease);
            }
            this.setState({ showConfirmationDialog: false }, () => {
                if (this.props.onUpdate) {
                    this.props.onUpdate();
                }
            });
        });
    }
}
