import { DoBusyTask, Errors, useDoBusyTaskEffect } from "components/DataBaseComponent";
import { ProcessContextProps, loadProcess, ProcessContext, ProcessContextLookupState, useBoundProcessActions } from "./ProcessContext";
import { ProcessPageSupportedActions, ProcessContextModelState } from "../types";
import React from "react";
import { IProcessResource, Permission, ProcessType } from "client/resources";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import FeedResource from "client/resources/feedResource";
import { repository } from "clientInstance";
import { ProcessStateSelectors, getSelectors, processContextModelStateReducer, getProcessContextModelInitialState } from "./ProcessContextState";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { ProcessSearchFilterController } from "./ProcessSearchFilter/ProcessSearchFilterContext";
import { ProcessErrorsController } from "./ProcessErrors/ProcessErrorsContext";

interface ProcessControllerProps {
    id: string;
    doBusyTask: DoBusyTask;
    children: (renderProps: ProcessContextProps) => React.ReactNode;
    processType: ProcessType;
    projectId: string;
    layoutActions: ProcessPageSupportedActions;
    errors?: Errors;
}

const useProcessState = () => {
    return React.useState<ProcessContextLookupState>({
        actionTemplates: [],
        feeds: [],
    });
};

const useLoadProcessEffect = (processType: ProcessType, id: string, doBusyTask: DoBusyTask, onLoaded: (process: IProcessResource) => void) => {
    return useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            if (!id) {
                return;
            }

            const result: IProcessResource = await loadProcess(processType, id);

            if (onLoaded) {
                onLoaded(result);
            }
        },
        [id, processType]
    );
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>) => {
    return {
        onActionTemplatesUpdated: (actionTemplates: ActionTemplateSearchResource[]) => setState(current => ({ ...current, actionTemplates })),
        onFeedsUpdated: (feeds: FeedResource[]) => setState(current => ({ ...current, feeds })),
    };
};

const useProcessModificationsEffect = (callback: (process: IProcessResource) => void, dependencies: React.DependencyList) => {
    const deploymentProcessSubscribeKey = "ProcessContextDeploymentProcess";
    React.useEffect(() => {
        //Logger.info("Subscribing to data modifications for the DeploymentProcess.");
        repository.DeploymentProcesses.subscribeToDataModifications(deploymentProcessSubscribeKey, callback);
        return () => {
            repository.DeploymentProcesses.unsubscribeFromDataModifications(deploymentProcessSubscribeKey);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    const runbookProcessSubscribeKey = "ProcessContextRunbookProcess";
    React.useEffect(() => {
        //Logger.info("Subscribing to data modifications for the RunbookProcess.");
        repository.RunbookProcess.subscribeToDataModifications(runbookProcessSubscribeKey, callback);
        return () => {
            repository.RunbookProcess.unsubscribeFromDataModifications(runbookProcessSubscribeKey);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

const useSelectors = (state: ProcessContextModelState): ProcessStateSelectors => {
    return React.useMemo(() => getSelectors(state), [state]);
};

export const ProcessController: React.FC<ProcessControllerProps> = ({ children, doBusyTask, id, processType, projectId, layoutActions }) => {
    const [state, dispatch] = React.useReducer(processContextModelStateReducer, getProcessContextModelInitialState(processType));
    const selectors = useSelectors(state);

    const [lookupsState, setState] = useProcessState();

    const boundActions = useBoundProcessActions(dispatch);

    const refreshFromServer = useLoadProcessEffect(processType, id, doBusyTask, process => {
        boundActions.setProcess(process, true);
    });

    const stateUpdaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    useProcessModificationsEffect(
        updatedProcess => {
            if (updatedProcess && id === updatedProcess.Id) {
                boundActions.setProcess(updatedProcess, true);
            }
        },
        [stateUpdaters, id]
    );

    const refreshActionTemplates = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const templates = await repository.ActionTemplates.search();
            stateUpdaters.onActionTemplatesUpdated(templates);
        },
        []
    );

    const refreshFeeds = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const feeds = isAllowed({ permission: Permission.FeedView, project: projectId, wildcard: true }) ? await repository.Feeds.all() : [];
            stateUpdaters.onFeedsUpdated(feeds);
        },
        []
    );

    const contextValue: ProcessContextProps = {
        lookupsState,
        state,
        actions: {
            ...boundActions,
            onActionTemplatesUpdated: stateUpdaters.onActionTemplatesUpdated,
            onFeedsUpdated: stateUpdaters.onFeedsUpdated,
            refreshFromServer,
            refreshActionTemplates,
            refreshFeeds,
            ...layoutActions,
        },
        selectors,
    };

    return (
        <ProcessContext.Provider value={contextValue}>
            <ProcessSearchFilterController processType={contextValue.state.processType} selectors={contextValue.selectors}>
                {() => {
                    return <ProcessErrorsController>{children(contextValue)}</ProcessErrorsController>;
                }}
            </ProcessSearchFilterController>
        </ProcessContext.Provider>
    );
};
