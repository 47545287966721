/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository } from "clientInstance";
import { RadioButton, BooleanRadioButtonGroup, Text } from "components/form";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import Note from "components/form/Note/Note";
const styles = require("./style.less");
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";
import { BuiltInFeedResource } from "client/resources/feedResource";

interface EditBuiltInRepositoryRetentionProps {
    afterConfigurationUpdated(updated: BuiltInFeedResource): void;
}

interface EditBuiltInRepositoryRetentionState extends DataBaseComponentState {
    loaded: boolean;
    keepFor?: number;
    keepForever: boolean;
}

class EditBuiltInRepositoryRetention extends DataBaseComponent<EditBuiltInRepositoryRetentionProps, EditBuiltInRepositoryRetentionState> {
    constructor(props: EditBuiltInRepositoryRetentionProps) {
        super(props);

        this.state = {
            loaded: false,
            keepForever: false,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(() => this.load());
    }

    async load() {
        const config = await repository.Feeds.getBuiltIn();
        this.setState({
            loaded: true,
            keepFor: config.DeleteUnreleasedPackagesAfterDays,
            keepForever: !config.DeleteUnreleasedPackagesAfterDays,
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            const config = await repository.Feeds.getBuiltIn();
            config.DeleteUnreleasedPackagesAfterDays = this.state.keepForever ? null! : this.state.keepFor;
            const updated = await repository.Feeds.modify(config);
            this.props.afterConfigurationUpdated(updated as BuiltInFeedResource);
        });
    }

    render() {
        const daysField = this.state.loaded && !this.state.keepForever && <Text label="days" type="number" min={1} className={styles.daysField} value={this.state.keepFor!.toString()} onChange={v => this.setState({ keepFor: parseFloat(v) })} />;

        const body = this.state.loaded && (
            <div>
                <span className={styles.cardSubTitle}>How long after publishing should unassociated packages be kept?</span>
                <BooleanRadioButtonGroup onChange={v => (v ? this.setState({ keepForever: true }) : this.setState({ keepForever: false, keepFor: 30 }))} value={this.state.keepForever} autoFocus>
                    <RadioButton value={true} label="Forever" />
                    <RadioButton value={false} label="A limited time" />
                </BooleanRadioButtonGroup>
                {daysField}

                <Note style={{ marginTop: "1rem" }}>
                    Packages are deleted automatically from the built-in repository after the period specified above if they are not associated with a release. This includes packages that were associated with a release that has since been deleted
                    (for example by its lifecycle's retention policy). See our <ExternalLink href="RetentionPolicies">documentation</ExternalLink> for further information.
                </Note>
            </div>
        );

        return (
            <SaveDialogLayout title="Built-in Repository Retention" busy={this.state.busy} errors={this.state.errors} onSaveClick={() => this.save()}>
                {body}
            </SaveDialogLayout>
        );
    }
}

export default EditBuiltInRepositoryRetention;
