import * as React from "react";
import { ReferenceDataItem, ProcessReferenceDataItem } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { ProcessChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import { Item } from "components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";

const ProcessTypedMultiSelect = MultiSelect<ProcessReferenceDataItem>();

interface ProcessMultiSelectProps extends FormFieldProps<string[]> {
    items: ProcessReferenceDataItem[];
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    multiSelectRef?(component: FocusableComponent | null): void;
}

const ProcessMultiSelect: React.FC<ProcessMultiSelectProps> = props => {
    const chipRenderer = React.useCallback(
        (r: ProcessReferenceDataItem, onRequestDelete: (event: object) => void) => {
            return (
                <LookupReferenceDataItemChip
                    lookupCollection={props.items}
                    lookupId={r.Id}
                    onMissingChipRequestDelete={onRequestDelete}
                    chipRender={item => <ProcessChip processType={r.ProcessType} onRequestDelete={onRequestDelete} name={r.Name} />}
                />
            );
        },
        [props.items]
    );

    const itemRender = React.useCallback((r: ProcessReferenceDataItem): Item => ({ primaryText: r.Name, secondaryText: r.ProcessType }), []);

    return <ProcessTypedMultiSelect renderItem={itemRender} fieldName="processes" renderChip={chipRenderer} {...props} />;
};

export default ProcessMultiSelect;
