/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ExpandableFormSection, Summary } from "components/form";
import { VariableLookupText } from "components/form/VariableLookupText";
import Note from "components/form/Note/Note";
import getSensitiveResetValue from "components/form/Sensitive/getSensitiveResetValue";
import { BoundSelect } from "components/form/Select/Select";
import isBound from "components/form/BoundField/isBound";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { required } from "components/form";

interface ApplicationPoolProps {
    localNames: string[];
    projectId: string;
    pathToRootProperty: string;
    properties: any;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    setProperties(properties: any): void;
}

const applicationPool = (props: ApplicationPoolProps) => {
    const fullPath = (propertyName: string) => {
        return props.pathToRootProperty + "." + propertyName;
    };

    const frameworkVersions = [
        { value: "v4.0", text: "v4.0" },
        { value: "v2.0", text: "v2.0" },
        { value: "No Managed Code", text: "No Managed Code" },
    ];

    const appPoolIdentities = [
        { value: "ApplicationPoolIdentity", text: "Application Pool Identity" },
        { value: "LocalService", text: "Local Service" },
        { value: "LocalSystem", text: "Local System" },
        { value: "NetworkService", text: "Network Service" },
        { value: "SpecificUser", text: "Custom user..." },
    ];

    const applicationPoolSummary = () => {
        if (!props.properties[fullPath("ApplicationPoolName")]) {
            return Summary.placeholder("The Application Pool has not been configured");
        }
        const nodes = [];
        nodes.push(
            <span>
                Application Pool <strong>{props.properties[fullPath("ApplicationPoolName")]}</strong> will be created if required and used.
            </span>
        );

        if (props.properties[fullPath("ApplicationPoolFrameworkVersion")] === "No Managed Code") {
            nodes.push(<span> It will be configured with no .NET CLR support</span>);
        } else {
            nodes.push(
                <span>
                    {" "}
                    The .NET framework version will be <strong>{props.properties[fullPath("ApplicationPoolFrameworkVersion")]}</strong>
                </span>
            );
        }
        const identity = appPoolIdentities.find(p => props.properties[fullPath("ApplicationPoolIdentityType")] === p.value);
        if (identity) {
            if (identity.value === "SpecificUser") {
                if (!props.properties[fullPath("ApplicationPoolUsername")]) {
                    nodes.push(<span>, and the pool will run as a custom user that has not been configured</span>);
                } else {
                    nodes.push(
                        <span>
                            , and the pool will run as <strong>{props.properties[fullPath("ApplicationPoolUsername")]}</strong>
                        </span>
                    );
                }
            } else {
                nodes.push(
                    <span>
                        , and the pool will run as <strong>{identity.text}</strong>
                    </span>
                );
            }
        }
        return Summary.summary(React.Children.toArray(nodes));
    };

    return (
        <ExpandableFormSection
            errorKey={fullPath("ApplicationPoolName")}
            isExpandedByDefault={props.expandedByDefault}
            title="Application Pool"
            summary={applicationPoolSummary()}
            help="Configure the details of the IIS Application Pool that will be created and used."
        >
            <VariableLookupText
                localNames={props.localNames}
                value={props.properties[fullPath("ApplicationPoolName")]}
                onChange={x => props.setProperties({ [fullPath("ApplicationPoolName")]: x })}
                label="Application Pool name"
                error={props.getFieldError(fullPath("ApplicationPoolName"))}
                validate={required("Please enter an Application Pool name")}
            />
            <Note>Name of the application pool in IIS to create or reconfigure.</Note>

            <BoundSelect
                variableLookup={{
                    localNames: props.localNames,
                }}
                resetValue={"v4.0"}
                value={props.properties[fullPath("ApplicationPoolFrameworkVersion")]}
                onChange={x => props.setProperties({ [fullPath("ApplicationPoolFrameworkVersion")]: x })}
                items={frameworkVersions}
                error={props.getFieldError(fullPath("ApplicationPoolFrameworkVersion"))}
                hintText=".NET CLR version"
                label=".NET CLR version"
            />
            <Note>
                The version of the .NET common language runtime that this application pool will use. Choose <strong>v2.0</strong> for applications built against .NET 2.0, 3.0 or 3.5. Choose <strong>v4.0</strong> for .NET 4.0 or 4.5
            </Note>

            <BoundSelect
                variableLookup={{
                    localNames: props.localNames,
                }}
                resetValue={"ApplicationPoolIdentity"}
                value={props.properties[fullPath("ApplicationPoolIdentityType")]}
                onChange={x => props.setProperties({ [fullPath("ApplicationPoolIdentityType")]: x })}
                items={appPoolIdentities}
                error={props.getFieldError(fullPath("ApplicationPoolIdentityType"))}
                hintText="Identity"
                label="Identity"
            />
            <Note>Which built-in account will the application pool run under.</Note>

            {(props.properties[fullPath("ApplicationPoolIdentityType")] === "SpecificUser" || isBound(props.properties[fullPath("ApplicationPoolIdentityType")])) && (
                <div>
                    <VariableLookupText
                        localNames={props.localNames}
                        value={props.properties[fullPath("ApplicationPoolUsername")]}
                        onChange={x => props.setProperties({ [fullPath("ApplicationPoolUsername")]: x })}
                        error={props.getFieldError(fullPath("ApplicationPoolUsername"))}
                        label="Username"
                    />
                    <Note>
                        The Windows/domain account of the custom user that the application pool will run under. Example: <code>YOURDOMAIN\YourAccount</code>. You will need to ensure that this user has permissions to run as an application pool.
                    </Note>
                    <BoundSensitive
                        variableLookup={{
                            localNames: props.localNames,
                        }}
                        resetValue={getSensitiveResetValue(props.properties[fullPath("ApplicationPoolPassword")])}
                        value={props.properties[fullPath("ApplicationPoolPassword")] as any}
                        onChange={x => props.setProperties({ [fullPath("ApplicationPoolPassword")]: x as any })}
                        label="Password"
                    />
                    <Note>The password for the custom account given above.</Note>
                </div>
            )}

            <BoundStringCheckbox
                variableLookup={{
                    localNames: props.localNames,
                }}
                resetValue={"False"}
                value={props.properties["Octopus.Action.IISWebSite.StartApplicationPool"]}
                onChange={x => props.setProperties({ ["Octopus.Action.IISWebSite.StartApplicationPool"]: x })}
                label="Start IIS Application Pool"
            />
            <Note>Whether the deployment step should start the IIS Application Pool after a successful deployment or not.</Note>
        </ExpandableFormSection>
    );
};

export default applicationPool;
