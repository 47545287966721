import * as React from "react";
import { ListItem } from "material-ui";
import Theme, { withTheme } from "components/Theme";

interface FocusListItemProps {
    containerElement?: JSX.Element;
    primaryText: React.ReactNode;
    secondaryText?: React.ReactNode;
    secondaryTextLines?: 1 | 2;
    leftIcon?: JSX.Element;
    isFocused: boolean;
    onRequestClose: (event?: React.MouseEvent<{}, MouseEvent>) => void;
}

export default class FocusListItem extends React.Component<FocusListItemProps> {
    element: ListItem;

    componentDidMount() {
        if (this.props.isFocused) {
            this.element.applyFocusState("keyboard-focused");
        }
    }

    componentDidUpdate(prevProps: FocusListItemProps, prevState: FocusListItemProps) {
        if (prevProps.isFocused === this.props.isFocused) {
            return;
        }

        if (this.props.isFocused) {
            this.element.applyFocusState("keyboard-focused");
        }
    }

    handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && this.props.isFocused) {
            this.props.onRequestClose();
        }
    };

    render() {
        return withTheme(theme => (
            <ListItem
                ref={this.setRef}
                containerElement={this.props.containerElement}
                primaryText={this.props.primaryText}
                secondaryText={this.props.secondaryText}
                secondaryTextLines={this.props.secondaryTextLines}
                leftIcon={this.props.leftIcon}
                onKeyUp={this.handleKeyUp}
                onClick={this.props.onRequestClose}
                hoverColor={theme.hover}
            />
        ));
    }

    private setRef = (el: ListItem) => {
        this.element = el;
    };
}
