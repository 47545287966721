/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import * as PropTypes from "prop-types";
import * as ReactDOM from "react-dom";
import { shallowEqual } from "recompose";
import transitions from "material-ui/styles/transitions";
import EnhancedTextarea from "./EnhancedTextarea";
import TextFieldHint from "material-ui/TextField/TextFieldHint";
import TextFieldLabel from "material-ui/TextField/TextFieldLabel";
import TextFieldUnderline from "material-ui/TextField/TextFieldUnderline";
import { baseSizeInPx } from "fontWeights";
import { withTheme, OctopusTheme } from "components/Theme";
const warning = require("warning");

const getStyles = (props: any, context: any, state: any, theme: OctopusTheme) => {
    const {
        baseTheme,
        textField: { floatingLabelColor, focusColor, textColor, backgroundColor, errorColor },
    } = context.muiTheme;

    const styles: any = {
        root: {
            fontSize: baseSizeInPx,
            lineHeight: "1.5rem",
            width: props.fullWidth ? "100%" : 256,
            height: (props.rows - 1) * 24 + (!props.multiLine && props.floatingLabelText ? 62 : 48), // #MultiLineHacks2018
            display: "inline-block",
            position: "relative",
            backgroundColor,
            fontFamily: baseTheme.fontFamily,
            transition: transitions.easeOut("200ms", "height"),
            cursor: props.disabled ? "not-allowed" : "auto",
            marginTop: 0,
        },
        error: {
            position: "relative",
            bottom: 2,
            fontSize: 12,
            lineHeight: "12px",
            color: errorColor,
            transition: transitions.easeOut(),
        },
        floatingLabel: {
            color: props.disabled ? theme.disabledButtonText : floatingLabelColor,
            pointerEvents: "none",
            top: 28,
        },
        input: {
            padding: 0,
            position: "relative",
            width: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            color: props.disabled ? theme.disabledButtonText : textColor,
            cursor: "inherit",
            font: "inherit",
            WebkitOpacity: 1,
            WebkitTapHighlightColor: "transparent", // Remove mobile color flashing (deprecated style).
        },
        inputNative: {
            appearance: "textfield", // Improve type search style.
        },
        underlineStyle: {
            borderColor: theme.secondaryText,
        },
        disabledTextColor: {
            color: theme.secondaryText,
        },
    };
    styles.textarea = {
        ...styles.input,
        paddingTop: props.floatingLabelText ? 36 : 12, // Using padding (not margin) to improve usability/click area.
        marginBottom: props.floatingLabelText ? -36 : -12,
        boxSizing: "border-box",
        font: "inherit",
    };

    // Do not assign a height to the textarea as he handles it on his own.
    styles.input.height = "100%";

    if (state.isFocused) {
        styles.floatingLabel.color = focusColor;
    }

    if (props.floatingLabelText) {
        styles.input.boxSizing = "border-box";

        if (!props.multiLine) {
            styles.input.marginTop = 0;
        } else {
            // Don't ask, multilines are a pita >< #MultiLineHacks2018
            styles.textarea.paddingTop = 24; // Using padding (not margin) to improve usability/click area.
            styles.textarea.marginBottom = -16;
        }

        if (state.errorText) {
            styles.error.bottom = !props.multiLine ? styles.error.fontSize + 3 : 3;
        }
    }

    if (state.errorText) {
        if (state.isFocused) {
            styles.floatingLabel.color = styles.error.color;
        }
    }

    return styles;
};

/*
 * Check if a value is valid to be displayed inside an input.
 * @param The value to check.
 * @param value
 * @returns True if the string provided is valid, false otherwise.
 */
function isValid(value: any) {
    return value !== "" && value !== undefined && value !== null && !(Array.isArray(value) && value.length === 0);
}

class TextField extends React.Component<any, any> {
    static propTypes = {
        children: PropTypes.node,
        /**
         * The css class name of the root element.
         */
        className: PropTypes.string,
        /**
         * The text string to use for the default value.
         */
        defaultValue: PropTypes.any,
        /**
         * Disables the text field if set to true.
         */
        disabled: PropTypes.bool,
        /**
         * The style object to use to override error styles.
         */
        errorStyle: PropTypes.object,
        /**
         * The error content to display.
         */
        errorText: PropTypes.node,
        /**
         * If true, the floating label will float even when there is no value.
         */
        floatingLabelFixed: PropTypes.bool,
        /**
         * The style object to use to override floating label styles when focused.
         */
        floatingLabelFocusStyle: PropTypes.object,
        /**
         * The style object to use to override floating label styles when shrunk.
         */
        floatingLabelShrinkStyle: PropTypes.object,
        /**
         * The style object to use to override floating label styles.
         */
        floatingLabelStyle: PropTypes.object,
        /**
         * The content to use for the floating label element.
         */
        floatingLabelText: PropTypes.node,
        /**
         * If true, the field receives the property width 100%.
         */
        fullWidth: PropTypes.bool,
        /**
         * Override the inline-styles of the TextField's hint text element.
         */
        hintStyle: PropTypes.object,
        /**
         * The hint content to display.
         */
        hintText: PropTypes.node,
        /**
         * The id prop for the text field.
         */
        id: PropTypes.string,
        /**
         * Override the inline-styles of the TextField's input element.
         * When multiLine is false: define the style of the input element.
         * When multiLine is true: define the style of the container of the textarea.
         */
        inputStyle: PropTypes.object,
        /**
         * If true, a textarea element will be rendered.
         * The textarea also grows and shrinks according to the number of lines.
         */
        multiLine: PropTypes.bool,
        /**
         * Name applied to the input.
         */
        name: PropTypes.string,
        /** @ignore */
        onBlur: PropTypes.func,
        /**
         * Callback function that is fired when the textfield's value changes.
         *
         * @param {object} event Change event targeting the text field.
         * @param {string} newValue The new value of the text field.
         */
        onChange: PropTypes.func,
        /** @ignore */
        onFocus: PropTypes.func,
        /**
         * Number of rows to display when multiLine option is set to true.
         */
        rows: PropTypes.number,
        /**
         * Maximum number of rows to display when
         * multiLine option is set to true.
         */
        rowsMax: PropTypes.number,
        /**
         * Override the inline-styles of the root element.
         */
        style: PropTypes.object,
        /**
         * Override the inline-styles of the TextField's textarea element.
         * The TextField use either a textarea or an input,
         * this property has effects only when multiLine is true.
         */
        textareaStyle: PropTypes.object,
        /**
         * Specifies the type of input to display
         * such as "password" or "text".
         */
        type: PropTypes.string,
        /**
         * Override the inline-styles of the
         * TextField's underline element when disabled.
         */
        underlineDisabledStyle: PropTypes.object,
        /**
         * Override the inline-styles of the TextField's
         * underline element when focussed.
         */
        underlineFocusStyle: PropTypes.object,
        /**
         * If true, shows the underline for the text field.
         */
        underlineShow: PropTypes.bool,
        /**
         * Override the inline-styles of the TextField's underline element.
         */
        underlineStyle: PropTypes.object,
        /**
         * The value of the text field.
         */
        value: PropTypes.any,
    };

    static defaultProps = {
        disabled: false,
        floatingLabelFixed: false,
        multiLine: false,
        fullWidth: false,
        type: "text",
        underlineShow: true,
        rows: 1,
    };

    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };
    uniqueId: string;
    input: any;

    state = {
        isFocused: false,
        errorText: undefined as any,
        hasValue: false,
    };

    componentWillMount() {
        const { children, name, hintText, floatingLabelText, id } = this.props as any;

        const propsLeaf = children ? children.props : this.props;

        this.setState({
            errorText: this.props.errorText,
            hasValue: isValid(propsLeaf.value) || isValid(propsLeaf.defaultValue),
        });

        warning(
            name || hintText || floatingLabelText || id,
            `Material-UI: We don't have enough information
      to build a robust unique id for the TextField component. Please provide an id or a name.`
        );

        const uniqueId = `${name}-${hintText}-${floatingLabelText}-${Math.floor(Math.random() * 0xffff)}`;
        this.uniqueId = uniqueId.replace(/[^A-Za-z0-9-]/gi, "");
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.disabled && !this.props.disabled) {
            this.setState({
                isFocused: false,
            });
        }

        if (nextProps.errorText !== this.props.errorText) {
            this.setState({
                errorText: nextProps.errorText,
            });
        }

        if (nextProps.children && nextProps.children.props) {
            nextProps = nextProps.children.props;
        }

        if (nextProps.hasOwnProperty("value")) {
            const hasValue = isValid(nextProps.value);

            this.setState({
                hasValue,
            });
        }
    }

    shouldComponentUpdate(nextProps: any, nextState: any, nextContext: any) {
        return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState) || !shallowEqual(this.context, nextContext);
    }

    blur() {
        if (this.input) {
            this.getInputNode().blur();
        }
    }

    focus() {
        if (this.input) {
            this.getInputNode().focus();
        }
    }

    select() {
        if (this.input) {
            this.getInputNode().select();
        }
    }

    getValue() {
        return this.input ? this.getInputNode().value : undefined;
    }

    getInputNode() {
        // eslint-disable-next-line react/no-find-dom-node
        return this.props.children || this.props.multiLine ? this.input.getInputNode() : ReactDOM.findDOMNode(this.input);
    }

    handleInputBlur = (event: any) => {
        this.setState({ isFocused: false });
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    handleInputChange = (event: any) => {
        if (!this.props.hasOwnProperty("value")) {
            this.setState({ hasValue: isValid(event.target.value) });
        }
        if (this.props.onChange) {
            this.props.onChange(event, event.target.value);
        }
    };

    handleInputFocus = (event: any) => {
        if (this.props.disabled) {
            return;
        }
        this.setState({ isFocused: true });
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    handleHeightChange = (event: any, height: any) => {
        let newHeight = height;
        if (this.props.multiLine && this.props.floatingLabelText) {
            newHeight += 8; // Be VERY careful when changing anything here, this component is a nightmare when switching between single vs multiline.
        } else if (this.props.floatingLabelText) {
            newHeight += 24;
        }
        // eslint-disable-next-line react/no-find-dom-node
        (ReactDOM.findDOMNode(this) as any).style.height = `${newHeight}px`;
    };

    _isControlled() {
        return this.props.hasOwnProperty("value");
    }

    render() {
        return withTheme(theme => {
            const {
                children,
                className,
                disabled,
                errorStyle,
                errorText, // eslint-disable-line no-unused-vars
                floatingLabelFixed,
                floatingLabelFocusStyle,
                floatingLabelShrinkStyle,
                floatingLabelStyle,
                floatingLabelText,
                fullWidth, // eslint-disable-line no-unused-vars
                hintText,
                hintStyle,
                id,
                inputStyle,
                multiLine,
                onBlur, // eslint-disable-line no-unused-vars
                onChange, // eslint-disable-line no-unused-vars
                onFocus, // eslint-disable-line no-unused-vars
                style,
                type,
                underlineDisabledStyle,
                underlineFocusStyle,
                underlineShow,
                underlineStyle,
                rows,
                rowsMax,
                textareaStyle,
                ...other
            } = this.props as any;

            const { prepareStyles } = this.context.muiTheme;
            const styles = getStyles(this.props, this.context, this.state, theme);
            const inputId = id || this.uniqueId;

            const errorTextElement = this.state.errorText && <div style={prepareStyles({ ...styles.error, ...errorStyle })}>{this.state.errorText}</div>;

            const floatingLabelTextElement = floatingLabelText && (
                <TextFieldLabel
                    muiTheme={this.context.muiTheme}
                    style={{ ...styles.floatingLabel, ...floatingLabelStyle, ...(this.state.isFocused ? floatingLabelFocusStyle : null) }}
                    shrinkStyle={floatingLabelShrinkStyle}
                    htmlFor={inputId}
                    shrink={this.state.hasValue || this.state.isFocused || floatingLabelFixed}
                    disabled={disabled}
                >
                    {floatingLabelText}
                </TextFieldLabel>
            );

            const inputProps = {
                id: inputId,
                ref: (elem: any) => (this.input = elem),
                disabled: this.props.disabled,
                onBlur: this.handleInputBlur,
                onChange: this.handleInputChange,
                onFocus: this.handleInputFocus,
            };

            const childStyleMerged = { ...styles.input, ...inputStyle };

            let inputElement;
            if (children) {
                inputElement = React.cloneElement(children, {
                    ...inputProps,
                    ...children.props,
                    style: { ...childStyleMerged, ...children.props.style },
                });
            } else {
                inputElement = multiLine ? (
                    <EnhancedTextarea
                        style={childStyleMerged}
                        textareaStyle={{ ...styles.textarea, ...styles.inputNative, ...textareaStyle }}
                        rows={rows}
                        rowsMax={rowsMax}
                        hintText={hintText}
                        {...other}
                        {...inputProps}
                        onHeightChange={this.handleHeightChange}
                    />
                ) : (
                    <input type={type} style={prepareStyles({ ...styles.inputNative, ...childStyleMerged })} {...other} {...inputProps} />
                );
            }

            let rootProps = {};

            if (children) {
                rootProps = other;
            }

            return (
                <div {...rootProps} className={className} style={prepareStyles({ ...styles.root, ...style })}>
                    {floatingLabelTextElement}
                    {hintText ? (
                        <TextFieldHint
                            muiTheme={this.context.muiTheme}
                            show={!(this.state.hasValue || (floatingLabelText && !this.state.isFocused)) || (!this.state.hasValue && floatingLabelText && floatingLabelFixed && !this.state.isFocused)}
                            style={hintStyle}
                            text={hintText}
                        />
                    ) : null}
                    {inputElement}
                    {underlineShow ? (
                        <TextFieldUnderline
                            disabled={disabled}
                            disabledStyle={underlineDisabledStyle}
                            error={!!this.state.errorText}
                            errorStyle={errorStyle}
                            focus={this.state.isFocused}
                            focusStyle={underlineFocusStyle}
                            muiTheme={this.context.muiTheme}
                            style={underlineStyle}
                        />
                    ) : null}
                    {errorTextElement}
                </div>
            );
        });
    }
}

export default TextField;
