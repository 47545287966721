import * as React from "react";
import LinearProgress from "material-ui/LinearProgress";
import CircularProgress from "material-ui/CircularProgress";
import { busyIndicatorClass } from "uiTestClasses";
import { withTheme } from "components/Theme";
const styles = require("./style.less");

interface BusyIndicatorProps {
    show: boolean;
    inline?: boolean;
}

const BusyIndicator: React.FC<BusyIndicatorProps> = props =>
    withTheme(theme => {
        if (props.inline) {
            return (
                <>
                    {props.show && (
                        <span role="progressbar" className={`${busyIndicatorClass} ${styles.circularBusyIndicator}`}>
                            <CircularProgress mode="indeterminate" color={theme.primaryLight} size={22} thickness={2} />
                        </span>
                    )}
                </>
            );
        } else {
            return props.show ? (
                <div role="progressbar" className={`${busyIndicatorClass} ${styles.busyIndicator}`}>
                    <LinearProgress mode="indeterminate" color={theme.loadingIndicator} />
                </div>
            ) : (
                <div className={styles.busyIndicatorHiddenSpacer} />
            );
        }
    });

BusyIndicator.displayName = "BusyIndicator";

export default BusyIndicator;
