/* eslint-disable @typescript-eslint/no-explicit-any */

import BasicRepository, { ListArgs } from "./basicRepository";
import Client from "../client";
import FeedResource, { FeedType, BuiltInFeedResource, OctopusProjectFeedResource } from "../resources/feedResource";
import { ResourceCollection, BuiltInFeedStatsResource } from "../resources";
import { PackageDescriptionResource, PackageVersionResource } from "../resources/packageResource";

type FeedRepositoryListArgs = {
    feedType?: FeedType[];
} & ListArgs;

interface PackageSearchOptions extends ListArgs {
    includePreRelease?: boolean;
    preReleaseTag?: string;
    versionRange?: string;
    includeReleaseNotes?: boolean;
    filter?: string;
}

class FeedRepository extends BasicRepository<FeedResource, FeedResource, FeedRepositoryListArgs> {
    constructor(client: Client) {
        super("Feeds", client);
    }

    async getBuiltIn() {
        const result = await this.client.get<ResourceCollection<FeedResource>>(this.client.getLink("Feeds"), { feedType: FeedType.BuiltIn });

        return result.Items[0] as BuiltInFeedResource;
    }

    async getOctopusProject() {
        const result = await this.client.get<ResourceCollection<FeedResource>>(this.client.getLink("Feeds"), { feedType: FeedType.OctopusProject });

        return result.Items[0] as OctopusProjectFeedResource;
    }

    async getBuiltInStatus() {
        return this.client.get<BuiltInFeedStatsResource>(this.client.getLink("BuiltInFeedStats"));
    }

    searchPackages(
        feed: FeedResource,
        searchOptions: {
            term: string;
            take?: number;
            skip?: number;
        }
    ): Promise<ResourceCollection<PackageDescriptionResource>> {
        return this.client.get(feed.Links.SearchPackagesTemplate, searchOptions);
    }

    searchPackageVersions(feed: FeedResource, packageId: string, searchOptions: PackageSearchOptions): Promise<ResourceCollection<PackageVersionResource>> {
        return this.client.get(feed.Links["SearchPackageVersionsTemplate"], { packageId, ...searchOptions });
    }

    getNotes(feed: FeedResource, packageId: string, version: any) {
        return this.client.getRaw(feed.Links["NotesTemplate"], { packageId, version });
    }
}

export { PackageSearchOptions };
export default FeedRepository;
