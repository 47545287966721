import * as React from "react";
import { repository } from "clientInstance";
import { EnvironmentResource, EnvironmentsSummaryResource, DeploymentTargetResource, MachineResource, TenantResource, TaskRestrictedTo, ResourceCollection } from "client/resources";
import { OctopusIcon, OctopusIconType } from "components/Icon";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { isEqual, each } from "lodash";
import routeLinks from "../../../../routeLinks";
import { CardTitle, UnstructuredFormSection } from "components/form/Sections";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import Permission from "client/resources/permission";
import BaseAllMachinesSummary, { HealthStatusRecord } from "./BaseAllMachinesSummary";
import { BaseAllMachinesSummaryProps, BaseAllMachinesSummaryState } from "./BaseAllMachinesSummary";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { ReactNode } from "react";
import { TagIndex } from "components/tenantTagsets";
import MachineRow from "../MachineRow/MachineRow";
import { withTheme } from "components/Theme";
import FormPage from "components/FormPage/FormPage";
import { createMachineHealthMap, createMachinesListRequestArgs } from "./MachineFilter";
import { orderedHealthStatuses } from "areas/infrastructure/InfrastructureDetails";
import { EnvironmentSummaryFilter, defaultEnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import InfrastructureLayout from "../InfrastructureLayout";

const styles = require("./style.less");

interface DeploymentTargetsSummarySectionProps extends BaseAllMachinesSummaryProps<EnvironmentSummaryFilter> {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    filter: EnvironmentSummaryFilter;
}

interface DeploymentTargetsSummarySectionInnerProps extends DeploymentTargetsSummarySectionProps {
    initialData: InitialData;
}

interface InitialData {
    machinesResponse: ResourceCollection<MachineResource>;
    machineHealthStatusFastLookup: HealthStatusRecord;
}

const DeploymentTargetsSummarySectionFormPage = FormPage<InitialData>();
const DeploymentTargetsSummarySection: React.FC<DeploymentTargetsSummarySectionProps> = (props: DeploymentTargetsSummarySectionProps) => {
    return (
        <DeploymentTargetsSummarySectionFormPage
            title={"Deployment Targets"}
            load={async () => {
                const machineRequestArgs = createMachinesListRequestArgs(defaultEnvironmentSummaryFilter, null, true);
                const machinesResponse = await repository.Machines.list(machineRequestArgs);
                const machineHealthStatusFastLookup = createMachineHealthMap(machinesResponse, repository.takeDefaultPageSize);

                return { machinesResponse, machineHealthStatusFastLookup };
            }}
            renderWhenLoaded={data => <DeploymentTargetsSummarySectionInner initialData={data} {...props} />}
        />
    );
};

class DeploymentTargetsSummarySectionInner extends BaseAllMachinesSummary<DeploymentTargetsSummarySectionInnerProps, EnvironmentSummaryFilter, BaseAllMachinesSummaryState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: DeploymentTargetsSummarySectionInnerProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            machinesResponse: this.props.initialData.machinesResponse,
            machineHealthStatusFastLookup: this.props.initialData.machineHealthStatusFastLookup,
        };
    }

    componentDidMount() {
        this.reloadDataAndCurrentPageIndex();
    }

    componentWillReceiveProps(nextProps: BaseAllMachinesSummaryProps<EnvironmentSummaryFilter>) {
        if (!isEqual(this.props.filter, nextProps.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        const environmentsSummary = this.props.environmentsSummary;

        const machineStatusesLinks = orderedHealthStatuses.map(status => this.renderMachineSummaryLinks(environmentsSummary, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(environmentsSummary);
        const summaryComponents = [...machineStatusesLinks, machinesDisabledLinks];

        const componentKey = "allMachines";
        const overflowMenuItems = [];

        // Only show machine-related actions if they actually have some machines in this environment.
        if (environmentsSummary.TotalMachines > 0) {
            const machineIds = this.state.machinesResponse && this.state.machinesResponse.Items.map(x => x.Id);
            overflowMenuItems.push(
                OverflowMenuItems.item(`Check Health for ${machineIds && machineIds.length} Deployment Target${machineIds && machineIds.length === 1 ? "" : "s"}`, () => this.performHealthCheck(TaskRestrictedTo.DeploymentTargets, machineIds), {
                    permission: Permission.MachineEdit,
                    wildcard: true,
                })
            );
            const tentacleIds = environmentsSummary.MachineIdsForTentacleUpgrade;
            if (tentacleIds && tentacleIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade ${tentacleIds.length} Tentacle${tentacleIds.length === 1 ? "" : "s"}`, () => this.performTentacleUpgrade(TaskRestrictedTo.DeploymentTargets, tentacleIds), {
                        permission: Permission.MachineEdit,
                        wildcard: true,
                    })
                );
            }
            const calamariIds = environmentsSummary.MachineIdsForCalamariUpgrade;
            if (calamariIds && calamariIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${calamariIds.length} Deployment Target${calamariIds.length === 1 ? "" : "s"}`, () => this.performCalamariUpgradeOnTargets(calamariIds), {
                        permission: Permission.MachineEdit,
                        wildcard: true,
                    })
                );
            }
        }
        const titleContainer = (
            <div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    {withTheme(theme => (
                        <OctopusIcon iconType={OctopusIconType.Machine} color={theme.iconDark} />
                    ))}
                </div>
                <div className={styles.environmentName}>Deployment targets</div>
                <div className={styles.environmentMachinesCount}>({environmentsSummary.TotalMachines && environmentsSummary.TotalMachines.toLocaleString()})</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems} />
                </div>
            </div>
        );

        return (
            <PaperLayout key={componentKey} busy={this.state.busy} errors={this.state.errors} className={styles.paperLayoutOverride}>
                <CardTitle title={titleContainer} />
                <UnstructuredFormSection stretchContent={true}>{this.renderMachinesList(environmentsSummary)}</UnstructuredFormSection>
            </PaperLayout>
        );
    }

    protected async loadData() {
        const machineArgs = createMachinesListRequestArgs(this.props.filter, this.state.healthStatusFilter, this.state.isDisabledFilter);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Machines.list(machineArgs), machinesResponse => this.setMachineResponseState(machinesResponse));
    }

    protected renderMachine(machine: MachineResource, needsUpgrading: boolean = false): ReactNode {
        return <MachineRow machine={machine as DeploymentTargetResource} environments={this.props.environments} tenants={this.props.tenants} tagIndex={this.props.tagIndex} needsUpgrading={needsUpgrading} />;
    }
}

export default DeploymentTargetsSummarySection;
