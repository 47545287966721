import * as React from "react";
import { ExpandableFormSection, Summary, FormSectionHeading, Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import Text from "../../../../components/form/Text/Text";
import { AccountType, AzureServicePrincipalAccountResource, AzureSubscriptionAccountResource, AzureEnvironment, AccountResource } from "client/resources/";
import AccountEditBase, { AccountEditModel } from "./AccountEditBase";
import { SensitiveValue } from "../../../../client/resources/propertyValueResource";
import SensitiveFileUpload from "../../../../components/form/SensitiveFileUpload/SensitiveFileUpload";
import RadioButtonGroup from "../../../../components/form/RadioButton/RadioButtonGroup";
import RadioButton from "../../../../components/form/RadioButton/RadioButton";
import Sensitive, { ObfuscatedPlaceholder } from "../../../../components/form/Sensitive/Sensitive";
import { Callout } from "components/Callout";
import { CalloutType } from "../../../../components/Callout/Callout";
import { client } from "clientInstance";
const styles = require("./style.less");
import { ActionButton } from "components/Button/ActionButton";
import Dialog from "components/Dialog/Dialog";
import { SaveAndTestAzureAccountDialog } from "./SaveAndTestAccountDialog";
import Checkbox from "components/form/Checkbox/Checkbox";
import { Select } from "components/form";
import { cloneDeep, omit } from "lodash";
import { Item } from "components/form/Select/Select";

interface ServicePrincipalAuth {
    clientId: string;
    tenantId: string;
    password: SensitiveValue;
    resourceManagementBaseUri: string;
    activeDirectoryBaseUri: string;
}

interface ManagementCertAuth {
    certificateBytes: SensitiveValue; // optional cannot send null to API
    certificateThumbprint: string;
    serviceManagementBaseUri: string;
    serviceManagementEndpointSuffix: string;
}

interface AzureModel extends AccountEditModel {
    subscriptionNumber: string;
    azureEnvironment: string;
    usingAzureEnvironment: boolean;
    accountType: AzureAccountTypes;
    authentication: ServicePrincipalAuth | ManagementCertAuth;
}

const defaultServicePrincipal: ServicePrincipalAuth = {
    password: {
        HasValue: false,
    },
    clientId: "",
    tenantId: "",
    activeDirectoryBaseUri: "",
    resourceManagementBaseUri: "",
};

const defaultManagementCertificate: ManagementCertAuth = {
    certificateBytes: {
        HasValue: false,
    },
    certificateThumbprint: "",
    serviceManagementBaseUri: "",
    serviceManagementEndpointSuffix: "",
};

type AzureAccountTypes = AccountType.AzureSubscription | AccountType.AzureServicePrincipal;
type AzureAccountResources = AzureServicePrincipalAccountResource | AzureSubscriptionAccountResource;

class AzureAccountEdit extends AccountEditBase<AzureAccountResources, AzureModel> {
    getPartialModel(account?: AzureAccountResources): Partial<AzureModel> | undefined {
        if (!account) {
            return {
                accountType: AccountType.AzureServicePrincipal,
                authentication: cloneDeep(defaultServicePrincipal),
                azureEnvironment: "",
            };
        }

        const model = {
            accountType: account.AccountType as AzureAccountTypes,
            subscriptionNumber: account.SubscriptionNumber,
            azureEnvironment: account.AzureEnvironment,
        };

        if (account.AccountType === AccountType.AzureServicePrincipal) {
            const spAccount = account as AzureServicePrincipalAccountResource;
            const usingAzureEnvironment = !!account.AzureEnvironment || !!spAccount.ActiveDirectoryEndpointBaseUri || !!spAccount.ResourceManagementEndpointBaseUri;
            return {
                ...model,
                usingAzureEnvironment,
                authentication: {
                    password: spAccount.Password,
                    tenantId: spAccount.TenantId,
                    clientId: spAccount.ClientId,
                    activeDirectoryBaseUri: spAccount.ActiveDirectoryEndpointBaseUri,
                    resourceManagementBaseUri: spAccount.ResourceManagementEndpointBaseUri,
                },
            };
        } else if (account.AccountType === AccountType.AzureSubscription) {
            const mgtCertAccount = account as AzureSubscriptionAccountResource;
            const usingAzureEnvironment = !!account.AzureEnvironment || !!mgtCertAccount.ServiceManagementEndpointBaseUri || !!mgtCertAccount.ServiceManagementEndpointSuffix;
            return {
                ...model,
                usingAzureEnvironment,
                authentication: {
                    certificateBytes: mgtCertAccount.CertificateBytes,
                    certificateThumbprint: mgtCertAccount.CertificateThumbprint,
                    serviceManagementBaseUri: mgtCertAccount.ServiceManagementEndpointBaseUri,
                    serviceManagementEndpointSuffix: mgtCertAccount.ServiceManagementEndpointSuffix,
                },
            };
        }
    }

    isManagementCertificateAccount(variable: object): variable is ManagementCertAuth {
        return (variable as ManagementCertAuth).certificateThumbprint !== undefined;
    }

    isServicePrincipalAccount(variable: object): variable is ServicePrincipalAuth {
        return (variable as ServicePrincipalAuth).clientId !== undefined;
    }

    getPartialResource(): (Partial<AzureAccountResources> & { AccountType: AccountType }) | undefined {
        const resource = {
            AccountType: this.state.model.accountType,
            AzureEnvironment: this.state.model.azureEnvironment,
            SubscriptionNumber: this.state.model.subscriptionNumber,
        };

        if (this.isManagementCertificateAccount(this.state.model.authentication)) {
            return {
                ...resource,
                CertificateBytes: this.state.model.authentication.certificateBytes,
                ServiceManagementEndpointBaseUri: this.state.model.authentication.serviceManagementBaseUri,
                ServiceManagementEndpointSuffix: this.state.model.authentication.serviceManagementEndpointSuffix,
            };
        } else {
            return {
                ...resource,
                Password: this.state.model.authentication.password,
                TenantId: this.state.model.authentication.tenantId,
                ClientId: this.state.model.authentication.clientId,
                ActiveDirectoryEndpointBaseUri: this.state.model.authentication.activeDirectoryBaseUri,
                ResourceManagementEndpointBaseUri: this.state.model.authentication.resourceManagementBaseUri,
            };
        }
    }

    subscriptionSummary() {
        return this.state.model.subscriptionNumber ? Summary.summary(this.state.model.subscriptionNumber) : Summary.placeholder("No subscription provided");
    }

    clientIdSummary(model: ServicePrincipalAuth) {
        return model.clientId ? Summary.summary(model.clientId) : Summary.placeholder("No client\\application ID provided");
    }

    tenantIdSummary(model: ServicePrincipalAuth) {
        return model.tenantId ? Summary.summary(model.tenantId) : Summary.placeholder("No tenant ID provided");
    }

    passwordSummary(model: ServicePrincipalAuth) {
        return model.password && model.password.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No password provided");
    }

    resourceManagementUriSummary(model: ServicePrincipalAuth) {
        return model.resourceManagementBaseUri ? Summary.summary(model.resourceManagementBaseUri) : Summary.placeholder("The default endpoint is not being overridden");
    }

    activeDirectoryUriSummary(model: ServicePrincipalAuth) {
        return model.activeDirectoryBaseUri ? Summary.summary(model.activeDirectoryBaseUri) : Summary.placeholder("The default endpoint is not being overridden");
    }

    serviceManagementUriSummary(model: ManagementCertAuth) {
        return model.serviceManagementBaseUri ? Summary.summary(model.serviceManagementBaseUri) : Summary.placeholder("The default base URI is not being overridden");
    }

    serviceManagementEndpointSuffixSummary(model: ManagementCertAuth) {
        return model.serviceManagementEndpointSuffix ? Summary.summary(model.serviceManagementEndpointSuffix) : Summary.placeholder("The default endpoint suffix is not being overridden");
    }

    managementCertificateSummary(model: ManagementCertAuth) {
        if (!model.certificateBytes) {
            return Summary.placeholder("No certificate provided");
        }

        if (!model.certificateBytes.HasValue) {
            return Summary.placeholder("No certificate provided");
        }

        if (!model.certificateBytes.NewValue) {
            return Summary.summary(model.certificateThumbprint);
        }
        return Summary.summary("New certificate selected to be uploaded");
    }

    azureEnvironmentCheckboxSummary() {
        return this.state.model.azureEnvironment !== ""
            ? Summary.summary(
                  <span>
                      Selected the <b>{this.state.model.azureEnvironment}</b> Azure Environment
                  </span>
              )
            : Summary.placeholder(
                  <span>
                      Using the default <b>AzureCloud</b> Environment
                  </span>
              );
    }

    resourceManagementUriLabel() {
        return this.state.model.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Resource Management Endpoint Base Uri";
    }

    authenticationMethodSummary() {
        return this.state.model.accountType === null
            ? Summary.placeholder("Select the Azure authentication method")
            : this.state.model.accountType === AccountType.AzureServicePrincipal
            ? Summary.summary(<span>Use a Service Principal</span>)
            : Summary.summary(<span>Use a Management Certificate</span>);
    }

    activeDirectoryURILabel() {
        return this.state.model.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Active Directory Endpoint Base Uri";
    }

    serviceManagementBaseUriLabel() {
        return this.state.model.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Service Management Endpoint Base Uri";
    }

    serviceManagementEndpointSuffixLabel() {
        return this.state.model.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Service Management Endpoint Suffix";
    }

    customSecondaryAction(): React.ReactElement | null {
        return (
            <div>
                <ActionButton onClick={() => this.handleSaveClick(true)} label="Save and Test" />
                {this.state.accountData && (
                    <Dialog open={this.state.showTestDialog}>
                        <SaveAndTestAzureAccountDialog onOkClick={() => this.testDone()} accountId={this.state.accountData.account.Id} />
                    </Dialog>
                )}
            </div>
        );
    }

    customExpandableFormSections(): React.ReactElement[] {
        const baseElements: React.ReactElement[] = [];
        const isNew = this.state.accountData === undefined;
        const sectionHeading = isNew ? "Azure Details" : this.state.model.accountType === AccountType.AzureServicePrincipal ? "Service Principal Details" : "Management Certificate Details";

        baseElements.push(
            <FormSectionHeading title={sectionHeading} key={"header"} />,
            <ExpandableFormSection errorKey="SubscriptionNumber" key="subscriptionID" title="Subscription ID" summary={this.subscriptionSummary()} help="Your Azure subscription ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.">
                <Text value={this.state.model.subscriptionNumber} onChange={subscriptionNumber => this.setModelState({ subscriptionNumber })} label="Subscription" error={this.getFieldError("SubscriptionNumber")} />
            </ExpandableFormSection>
        );

        if (isNew) {
            baseElements.push(
                <ExpandableFormSection
                    isExpandedByDefault={true}
                    key="AuthenticationMethod"
                    title="Authentication Method"
                    errorKey="accountType"
                    summary={this.authenticationMethodSummary()}
                    help="Select the Azure authentication method to show the relevant form inputs below."
                >
                    <RadioButtonGroup
                        value={this.state.model.accountType}
                        onChange={x => {
                            const model = x === AccountType.AzureServicePrincipal ? cloneDeep(defaultServicePrincipal) : cloneDeep(defaultManagementCertificate);
                            this.setState({
                                model: {
                                    ...this.state.model,
                                    accountType: x as AzureAccountTypes,
                                    authentication: {
                                        ...model,
                                    },
                                },
                            });
                        }}
                    >
                        <RadioButton value={AccountType.AzureServicePrincipal} label="Use a Service Principal" isDefault={true} />
                        <RadioButton value={AccountType.AzureSubscription} label="Use a Management Certificate" />
                    </RadioButtonGroup>
                </ExpandableFormSection>
            );
        }

        if (this.isServicePrincipalAccount(this.state.model.authentication)) {
            baseElements.push(this.servicePrincipalExpandableFormSections(this.state.model.authentication));
        }

        if (this.isManagementCertificateAccount(this.state.model.authentication)) {
            baseElements.push(this.managementCertificateExpandableFormSections(this.state.model.authentication));
        }

        baseElements.push(
            <FormSectionHeading title="Azure Environment" key={"EnvironmentHeader"} />,
            <ExpandableFormSection
                errorKey="AzureEnvironment"
                key={"AzureEnvironment"}
                title="Azure Environment"
                summary={this.azureEnvironmentCheckboxSummary()}
                help={
                    <span>
                        Check this box only if you are using an isolated Azure Environment. <ExternalLink href="AzureEnvironments">Learn more about them here</ExternalLink>.
                    </span>
                }
            >
                <Checkbox label="Configure Isolated Azure Environment connection." value={this.state.model.usingAzureEnvironment} onChange={v => this.setAzureEnvironmentCheckbox(v)} />
                {this.state.model.usingAzureEnvironment && (
                    <div>
                        <Select value={this.state.model.azureEnvironment} onChange={v => this.setAzureEnvironmentFields(v)} items={this.toItemArray(this.props.azureEnvironments)} label="Azure Environment" allowClear={true} />
                    </div>
                )}
            </ExpandableFormSection>
        );

        if (this.isServicePrincipalAccount(this.state.model.authentication) && this.state.model.usingAzureEnvironment) {
            baseElements.push(this.servicePrincipalExpandableEnvironmentFormSections(this.state.model.authentication));
        }

        if (this.isManagementCertificateAccount(this.state.model.authentication) && this.state.model.usingAzureEnvironment) {
            baseElements.push(this.managementCertificateExpandableEnvironmentFormSections(this.state.model.authentication));
        }

        return baseElements;
    }

    servicePrincipalExpandableFormSections(model: ServicePrincipalAuth): React.ReactElement {
        return (
            <div key="SP">
                <ExpandableFormSection errorKey="TenantId" key="tenantid" title="Tenant ID" summary={this.tenantIdSummary(model)} help={`Your Azure Active Directory Tenant ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`}>
                    <Text value={model.tenantId} onChange={value => this.setServicePrincipalAuth({ tenantId: value })} label="Active Directory Tenant ID" error={this.getFieldError("TenantId")} />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="ClientId"
                    key="clientId"
                    title="Application ID"
                    summary={this.clientIdSummary(model)}
                    help={`Your Azure Active Directory Application ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.
                    This value is known as Application ID in the Azure Portal and the API, and previously referred to as Client ID in the old Azure Portal.`}
                >
                    <Text value={model.clientId} onChange={value => this.setServicePrincipalAuth({ clientId: value })} label="Active Directory Appication ID" error={this.getFieldError("ClientId")} />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="password"
                    key="password"
                    title="Application Password / Key"
                    summary={this.passwordSummary(model)}
                    help={`The password for the Azure Active Directory application. This value is known as Key in the Azure Portal, and Password in the API.`}
                >
                    <Sensitive value={model.password} label="Active Directory Password\\Key" onChange={value => this.setServicePrincipalAuth({ password: value })} />
                </ExpandableFormSection>
            </div>
        );
    }

    managementCertificateExpandableFormSections(model: ManagementCertAuth): JSX.Element {
        return (
            <div key="MC">
                <ExpandableFormSection
                    errorKey="managementCert"
                    key="managementCert"
                    title="Management certificate (.pfx)"
                    summary={this.managementCertificateSummary(model)}
                    help="Leave blank to let Octopus generate a new certificate or provide a password free .pfx file."
                >
                    <SensitiveFileUpload availablePlaceholder={model.certificateThumbprint} label="Management certificate (.pfx)" value={model.certificateBytes} onChange={value => this.setManagementCertAuth({ certificateBytes: value })} />
                    <Callout type={CalloutType.Information} title="Using your certificate">
                        <p>To give permission for Octopus to deploy to your Azure subscription, upload the public key portion (.cer) of the certificate to the Azure portal:</p>
                        <ol className={styles.certInstructionList}>
                            <li>
                                Download the certificate public key
                                <br />
                                {this.state.accountData !== undefined && <ExternalLink href={client.resolve(this.state.accountData.account.Links.PublicKey)}>{model.certificateThumbprint}</ExternalLink>}
                            </li>
                            <li>
                                Log in to the <ExternalLink href="ManageAzure">Azure management portal</ExternalLink>
                            </li>
                            <li>
                                Click <strong>All services</strong>
                            </li>
                            <li>
                                Click <strong>Subscriptions</strong> from the list, then select the subscription that you want to associate with the certificate
                            </li>
                            <li>
                                Click <strong>Management certificates</strong>
                            </li>
                            <li>
                                Click the <strong>Upload</strong> toolbar button
                            </li>
                            <li>
                                Click <strong>Upload</strong> and wait for your certificate to upload
                            </li>
                        </ol>
                    </Callout>
                </ExpandableFormSection>
            </div>
        );
    }

    servicePrincipalExpandableEnvironmentFormSections(model: ServicePrincipalAuth): JSX.Element {
        return (
            <div key="SPEE">
                <ExpandableFormSection
                    errorKey="ADbaseUri"
                    key="ADbaseUri"
                    title="AD Endpoint Base Uri"
                    summary={this.activeDirectoryUriSummary(model)}
                    help="Set this only if you need to override the default Active Directory Endpoint. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={model.activeDirectoryBaseUri}
                        onChange={value => this.setServicePrincipalAuth({ activeDirectoryBaseUri: value })}
                        label={this.activeDirectoryURILabel()}
                        disabled={this.state.model.azureEnvironment === ""}
                        error={this.getFieldError("ActiveDirectoryEndpointBaseUri")}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="RMbaseUri"
                    key="RMbaseUri"
                    title="Resource Management Base Uri"
                    summary={this.resourceManagementUriSummary(model)}
                    help="Set this only if you need to override the default Resource Management Endpoint. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={model.resourceManagementBaseUri}
                        onChange={value => this.setServicePrincipalAuth({ resourceManagementBaseUri: value })}
                        label={this.resourceManagementUriLabel()}
                        disabled={this.state.model.azureEnvironment === ""}
                        error={this.getFieldError("ResourceManagementEndpointBaseUri")}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    managementCertificateExpandableEnvironmentFormSections(model: ManagementCertAuth): JSX.Element {
        return (
            <div key="MCEE">
                <ExpandableFormSection
                    errorKey="SMbaseUri"
                    key="SMbaseUri"
                    title="Service Management Base Uri"
                    summary={this.serviceManagementUriSummary(model)}
                    help="Set this only if you need to override the default Service Management Base URI. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={model.serviceManagementBaseUri}
                        onChange={value => this.setManagementCertAuth({ serviceManagementBaseUri: value })}
                        label={this.serviceManagementBaseUriLabel()}
                        disabled={this.state.model.azureEnvironment === ""}
                        error={this.getFieldError("ServiceManagementEndpointBaseUri")}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="SMendpointSuffix"
                    key="SMendpointSuffix"
                    title="Storage Endpoint Suffix"
                    summary={this.serviceManagementEndpointSuffixSummary(model)}
                    help="Set this only if you need to override the default Storage Endpoint Suffix. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={model.serviceManagementEndpointSuffix}
                        onChange={value => this.setManagementCertAuth({ serviceManagementEndpointSuffix: value })}
                        label={this.serviceManagementEndpointSuffixLabel()}
                        disabled={this.state.model.azureEnvironment === ""}
                        error={this.getFieldError("ServiceManagementEndpointSuffix")}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    setManagementCertAuth<K extends keyof ManagementCertAuth>(state: Pick<ManagementCertAuth, K>) {
        this.setState(prev => ({
            model: {
                ...prev.model,
                authentication: {
                    ...prev.model.authentication,
                    ...state,
                },
            },
        }));
    }

    setServicePrincipalAuth<K extends keyof ServicePrincipalAuth>(state: Pick<ServicePrincipalAuth, K>) {
        this.setState(prev => ({
            model: {
                ...prev.model,
                authentication: {
                    ...prev.model.authentication,
                    ...state,
                },
            },
        }));
    }

    clearAzureEnvironmentFields() {
        this.setModelState({ azureEnvironment: "" });

        if (this.isManagementCertificateAccount(this.state.model.authentication)) {
            this.setManagementCertAuth({ serviceManagementEndpointSuffix: "", serviceManagementBaseUri: "" });
        }

        if (this.isServicePrincipalAccount(this.state.model.authentication)) {
            this.setServicePrincipalAuth({ activeDirectoryBaseUri: "", resourceManagementBaseUri: "" });
        }
    }

    setAzureEnvironmentCheckbox(v: boolean) {
        if (this.state.model.usingAzureEnvironment === true && v === false) {
            this.clearAzureEnvironmentFields();
        }
        this.setModelState({ usingAzureEnvironment: v });
    }

    setAzureEnvironmentFields(environmentName: string | undefined) {
        if (!environmentName) {
            this.clearAzureEnvironmentFields();
        } else {
            const environment = this.props.azureEnvironments.find(x => x.Name === environmentName);

            this.setModelState({ azureEnvironment: environmentName });

            if (!environment) {
                return;
            }

            if (this.isServicePrincipalAccount(this.state.model.authentication)) {
                this.setServicePrincipalAuth({ activeDirectoryBaseUri: environment.AuthenticationEndpoint });
                this.setServicePrincipalAuth({ resourceManagementBaseUri: environment.ResourceManagerEndpoint });
            }

            if (this.isManagementCertificateAccount(this.state.model.authentication)) {
                this.setManagementCertAuth({ serviceManagementEndpointSuffix: environment.StorageEndpointSuffix });
                this.setManagementCertAuth({ serviceManagementBaseUri: environment.ManagementEndpoint });
            }
        }
    }

    toItemArray(azureEnvironments: AzureEnvironment[]): Item[] {
        const items: Item[] = [];
        azureEnvironments.forEach(ae => {
            items.push({ value: ae.Name, text: ae.DisplayName });
        });
        return items;
    }
}

export default AzureAccountEdit;
