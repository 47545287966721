/* eslint-disable @typescript-eslint/no-explicit-any */

import * as _ from "lodash";
import { flatten, groupBy } from "lodash";
import { DeploymentTemplateStep, DeploymentPreviewBaseResource } from "client/resources/deploymentPreviewResource";
import { ReferenceDataItem, MachineModelHealthStatus } from "client/resources";

export class ActionInfo {
    ActionId: string;
    ActionName: string;
    ActionNumber: string;
    CanBeSkipped: boolean;
    IsDisabled: boolean;
    Machines: MachineDeploymentPreview[];
    UnavailableMachines: ReferenceDataItem[];
    HasNoApplicableMachines: boolean;
    HasOldCalamariVersion: boolean;
}

export class ActionToggleInfo {
    Id: string;
    Name: string;
    details: ActionInfo;
}

export class MachineDeploymentPreview {
    Id: string;
    Name: string;
    HasLatestCalamari: boolean;
    isUnavailable: boolean;
    HealthStatus?: MachineModelHealthStatus;
}

class DeploymentStepsWorker {
    static getDeploymentSteps(previews: DeploymentPreviewBaseResource[]) {
        const stepGroups = groupBy(flatten(previews.map(p => p.StepsToExecute)), "ActionId");

        const steps = _.chain(stepGroups)
            .map((sts: DeploymentTemplateStep[], actionId: string) => {
                const firstStep = sts[0];
                let applicable = false;
                _.each(sts, step => {
                    if (!step.HasNoApplicableMachines) {
                        applicable = true;
                    }
                });
                const unavailableIds: any = {};
                const unavailableMachines = _.chain(sts)
                    .map(s => {
                        return s.UnavailableMachines;
                    })
                    .flatten<ReferenceDataItem>()
                    .uniqBy(m => {
                        return m.Id;
                    })
                    .each(m => {
                        unavailableIds[m.Id] = m;
                    })
                    .value();

                const allMachines = _.chain(sts)
                    .flatMap(s => s.Machines)
                    .uniqBy(m => {
                        return m.Id;
                    })
                    .map(m => {
                        return { ...m, isUnavailable: unavailableIds[m.Id] };
                    })
                    .value();

                const hasOldCalamariVersion = !!_.find(allMachines, m => {
                    return !m.HasLatestCalamari;
                });
                const result: ActionToggleInfo = {
                    Id: firstStep.ActionId,
                    Name: firstStep.ActionName,
                    details: {
                        ActionId: firstStep.ActionId,
                        ActionName: firstStep.ActionName,
                        ActionNumber: firstStep.ActionNumber,
                        CanBeSkipped: firstStep.CanBeSkipped,
                        IsDisabled: firstStep.IsDisabled,
                        Machines: allMachines,
                        UnavailableMachines: unavailableMachines,
                        HasNoApplicableMachines: !applicable,
                        HasOldCalamariVersion: hasOldCalamariVersion,
                    },
                };
                return result;
            })
            .sortBy(s => {
                return parseInt(s.details.ActionNumber, NaN);
            })
            .value();

        return steps;
    }
}

export default DeploymentStepsWorker;
