import * as React from "react";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ToolTip from "components/ToolTip/index";
import { LocationDescriptor } from "history";
const styles = require("./style.less");
import { VariablesTab } from "../../tenants/TenantsRoutes/tenantRouteLinks";
import routeLinks from "../../../routeLinks";
import { LibraryVariable } from "../../../components/Images/Images/ReactPortal/LibraryVariable";
import { LibraryVariableTemplate } from "../../../components/Images/Images/ReactPortal/LibraryVariableTemplate";
import { ProjectVariable } from "../../../components/Images/Images/ReactPortal/ProjectVariable";
import { ProjectVariableTemplate } from "../../../components/Images/Images/ReactPortal/ProjectVariableTemplate";

export interface TenantProjectVariableSourceProps {
    tenantId: string;
    tenantName: string;
    type: "project";
}

export interface TenantLibraryVariableSetSourceProps {
    tenantId: string;
    tenantName: string;
    type: "library";
}

export interface LibraryVariableSetSourceProps {
    variableSetName: string;
    variableSetId: string;
}

export interface ProjectVariableSourceProps {
    projectName: string;
    projectId: string;
}

export type ValueSource = TenantProjectVariableSourceProps | TenantLibraryVariableSetSourceProps | LibraryVariableSetSourceProps | ProjectVariableSourceProps;

interface SourceLinkProps {
    source: ValueSource;
}

const SourceLink: React.SFC<SourceLinkProps> = (props: SourceLinkProps) => {
    return (
        <InternalLink to={getLinkLocation(props.source)}>
            <div className={styles.tooltipWrapper}>
                <ToolTip content={sourceLinkDescription(props.source)}>
                    <div className={styles.link}>
                        {getIcon(props.source)}
                        <span className={styles.text}>{getSourceLinkName(props.source)}</span>
                    </div>
                </ToolTip>
            </div>
        </InternalLink>
    );
};

function sourceLinkDescription(source: ValueSource): string {
    if (isProjectVariableSource(source)) {
        return `Project Variables: ${source.projectName}`;
    } else if (isLibraryVariableSetSource(source)) {
        return `Library Variable Set: ${source.variableSetName}`;
    } else if (isTenantProjectVariableSource(source)) {
        return `Tenant Project Variables: ${source.tenantName}`;
    } else {
        // Tenant library variable set case
        return `Tenant Common Variables: ${source.tenantName}`;
    }
}

export function getSourceLinkName(source: ValueSource): string {
    if (isProjectVariableSource(source)) {
        return source.projectName;
    } else if (isLibraryVariableSetSource(source)) {
        return source.variableSetName;
    } else if (isTenantProjectVariableSource(source)) {
        return source.tenantName;
    } else {
        // Tenant library variable set case
        return source.tenantName;
    }
}

function getLinkLocation(source: ValueSource): LocationDescriptor {
    if (isProjectVariableSource(source)) {
        return routeLinks.project(source.projectId).variables.root;
    } else if (isLibraryVariableSetSource(source)) {
        return routeLinks.library.variableSet(source.variableSetId);
    } else if (isTenantProjectVariableSource(source)) {
        return routeLinks.tenant(source.tenantId).variables(VariablesTab.ProjectVariables);
    } else {
        // Tenant library variable set case
        return routeLinks.tenant(source.tenantId).variables(VariablesTab.CommonVariables);
    }
}

function getIcon(source: ValueSource): JSX.Element {
    if (isProjectVariableSource(source)) {
        return <ProjectVariable className={styles.icon} />;
    } else if (isLibraryVariableSetSource(source)) {
        return <LibraryVariable className={styles.icon} />;
    } else if (isTenantProjectVariableSource(source)) {
        return <ProjectVariableTemplate className={styles.icon} />;
    } else {
        // Tenant library variable set case
        return <LibraryVariableTemplate className={styles.icon} />;
    }
}

export function isProjectVariableSource(source: ValueSource): source is ProjectVariableSourceProps {
    return (source as ProjectVariableSourceProps).projectId !== undefined;
}

export function isLibraryVariableSetSource(source: ValueSource): source is LibraryVariableSetSourceProps {
    return (source as LibraryVariableSetSourceProps).variableSetId !== undefined;
}

export function isTenantProjectVariableSource(source: ValueSource): source is TenantProjectVariableSourceProps {
    return (source as TenantProjectVariableSourceProps).type === "project";
}

export default SourceLink;
