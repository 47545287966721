import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import { RedirectAs404 } from "components/NotFound/NotFound";
import { ProcessStepsPage, ProcessListLayoutPage } from "../Pages";
import routeLinks from "routeLinks";
import InternalRedirect from "components/Navigation/InternalRedirect";
import * as H from "history";
import { ProcessType } from "client/resources";
import URI from "urijs";

type ProcessRouteProps = {
    path: string;
    processType: ProcessType;
};

type Props = ProcessRouteProps & RouteComponentProps<{ projectSlug: string }>;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function nextStepReloadPathWithQueryStrings(location: H.Location): string {
    if (!location) {
        console.error("Failed to find location prop. This should never happen.");
        return "/"; // Boot them back to root.
    }

    const parsedCurrentSearch = new URI(location.search).search(true);
    parsedCurrentSearch["reloadKey"] = DateFormatter.timestamp();

    const newSearch = new URI().search(parsedCurrentSearch).search();
    return `${location.pathname}${newSearch}`;
}

class ProcessRoute extends React.Component<Props> {
    render() {
        return (
            <Switch>
                <ReloadableRoute path={`${this.props.path}/steps`} exact={true} render={props => <ProcessStepsPage processType={this.props.processType} {...props} />} />
                <ReloadableRoute path={`${this.props.path}`} exact={true} render={props => <ProcessListLayoutPage processType={this.props.processType} {...props} />} />
                <OldProcessRedirectRoute {...this.props} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

class OldProcessRedirectRoute extends React.Component<Props> {
    render() {
        // If anyone has bookmarked an older step route, we redirect them back to the list. We want to K.I.S.S. and avoid mapping the old route parameters to the new query-string parameters (that felt like overkill).
        const redirectToProcessList = <InternalRedirect to={routeLinks.project(this.props.match.params.projectSlug).deployments.process.root} />;
        return (
            <Switch>
                <ReloadableRoute path={`${this.props.path}/step/new/:actionType/:template?`} render={props => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/new/:actionType/:template?`} render={props => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/:reloadKey?`} render={props => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/steptemplates`} render={props => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/childsteptemplates/:parentStepId`} render={props => redirectToProcessList} />
            </Switch>
        );
    }
}

const EnhancedProcessRoute = withRouter(ProcessRoute);

export default EnhancedProcessRoute;
