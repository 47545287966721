/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import Dialog from "components/Dialog/Dialog";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import EventListener from "react-event-listener";
import Callout, { CalloutType } from "../Callout";
//We use the dialog content here, because we are using the dialog directly without a layout. Layouts already have padding applied.
import DialogContent from "@material-ui/core/DialogContent";

interface Props {
    message: React.ReactNode;
    callback(navigate: boolean): void;
    onSaveClick(): Promise<boolean>;
}

interface State {
    show: boolean;
    success: boolean;
}

class ConfirmNavigate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show: true,
            success: true,
        };
    }

    handleTouchTap = (navigate: boolean) => {
        this.setState({
            show: false,
        });
        this.props.callback(navigate);
    };

    handleKeyDown = (event: any) => {
        if (event.keyCode === 27 /*esc*/) {
            this.handleTouchTap(false);
        }
    };

    render() {
        const actions = [
            <ActionButton style={{ marginRight: "5px" }} key="SaveChanges" label="Save Changes" type={ActionButtonType.Secondary} onClick={this.onSaveClick} />,
            <ActionButton style={{ marginRight: "5px" }} key="discard" label="Discard Changes" type={ActionButtonType.Secondary} onClick={() => this.handleTouchTap(true)} />,
            <ActionButton key="cancel" label="Cancel" type={ActionButtonType.Primary} keyboardFocused={true} onClick={() => this.handleTouchTap(false)} />,
        ];
        return (
            <Dialog title="Unsaved Changes" actions={actions} open={this.state.show}>
                <EventListener target="document" onKeyDown={this.handleKeyDown} />
                <DialogContent>
                    {this.props.message}
                    {!this.state.success && (
                        <Callout title="Errors found" type={CalloutType.Danger}>
                            Please cancel this dialog to review the errors (or discard your changes).
                        </Callout>
                    )}
                </DialogContent>
            </Dialog>
        );
    }

    private onSaveClick = async () => {
        const success: boolean = await this.props.onSaveClick();
        this.setState({ success });
        if (!!success) {
            this.handleTouchTap(true);
        }
    };
}

export default ConfirmNavigate;
