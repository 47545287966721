import * as React from "react";
import CustomSaveDialogLayout from "components/DialogLayout/Custom/CustomSaveDialogLayout";
import CodeEditor, { TextFormat, Language } from "components/CodeEditor/CodeEditor";
import CopyToClipboard from "components/CopyToClipboardButton";
import { CustomSaveDialogActions, CustomSaveDialogTitleBar } from "components/DialogLayout/Custom";
import { CustomDialogActions, CustomFlexDialogContent } from "components/DialogLayout/Custom";
import { CustomDialog, RenderProps } from "components/Dialog/CustomDialog";
import { ScriptingLanguage } from "components/scriptingLanguage";
import { OctopusError } from "client/resources";

interface SourceCodeDialogProps extends RenderProps {
    title?: string;
    value: string;
    language: ScriptingLanguage | Language | TextFormat;
    autocomplete: Array<{ display: string; code: string }>;
    validate?(value: string): Promise<OctopusError> | OctopusError | Error | null;
    saveDone(value: string): void;
}

const SourceCodeDialog: React.FC<SourceCodeDialogProps> = props => {
    const [errors, setErrors] = React.useState<OctopusError | Error | null>(null);
    const [sourceCode, setSourceCode] = React.useState<string>(props.value);

    React.useEffect(() => {
        setErrors(null);
        setSourceCode(props.value);
    }, [props.value]);

    const editorRef = React.useRef<CodeEditor>(null);

    const save = async () => {
        if (props.validate !== undefined) {
            const validationErrors = await props.validate(sourceCode);

            if (validationErrors) {
                setErrors(validationErrors);
                return false;
            } else {
                props.saveDone(sourceCode);
                return true;
            }
        } else {
            props.saveDone(sourceCode);
            return true;
        }
    };

    const onFocusedEditorEscapePressed = () => {
        editorRef?.current?.blur();
    };

    return (
        <CustomDialog
            open={props.open}
            close={props.close}
            render={customDialogRenderProps => (
                <CustomSaveDialogLayout
                    {...customDialogRenderProps}
                    renderTitle={() => <CustomSaveDialogTitleBar title={props.title ? props.title : "Edit Source"} />}
                    {...(errors && isOctopusError(errors) && errors.Errors ? { errors: { errors: errors.Errors.map((x: string) => x.toString()) ?? [], message: errors.ErrorMessage, fieldErrors: {}, details: {} } } : {})}
                    onSaveClick={() => save()}
                    renderActions={renderProps => (
                        <CustomDialogActions
                            actions={<CustomSaveDialogActions saveButtonLabel="Done" close={renderProps.close} onSaveClick={renderProps.onSaveClick} savePermission={renderProps.savePermission} />}
                            additionalActions={<CopyToClipboard value={sourceCode} />}
                        />
                    )}
                    renderContent={() => (
                        <CustomFlexDialogContent>
                            <CodeEditor
                                value={sourceCode}
                                ref={editorRef}
                                language={props.language}
                                allowFullScreen={false}
                                onChange={value => {
                                    setSourceCode(value);
                                }}
                                autoComplete={props.autocomplete}
                                autoFocus={props.value == null || props.value === undefined || props.value.length === 0}
                                onEscPressed={() => onFocusedEditorEscapePressed()}
                            />
                        </CustomFlexDialogContent>
                    )}
                />
            )}
        />
    );
};

const isOctopusError = (error: OctopusError | Error): error is OctopusError => {
    if ((error as OctopusError).ErrorMessage) {
        return true;
    }
    return false;
};

export default SourceCodeDialog;
