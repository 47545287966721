import * as React from "react";

const styles = require("./style.less");

import ActionButton, { ActionButtonType, NavigationButton } from "components/Button";
import { Text, Note, ErrorPanel } from "components/form";
import { required } from "components/form/Validators";
import { DialogLayout } from "components/DialogLayout/DialogLayout";
import { Grid, CircularProgress, Stepper, Step, StepLabel, Dialog } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Errors } from "components/DataBaseComponent";
import { withTheme } from "components/Theme";
import routeLinks from "routeLinks";
import { ProjectResource } from "client/resources";

export enum CommitDialogStep {
    CommitMessage = 1,
    Cloning = 2,
    CloneCompleted = 3,
}

interface CommitDialogProps {
    open: boolean;
    step: CommitDialogStep;
    errors?: Errors;
    project: ProjectResource;
    onStepChange: (step: CommitDialogStep) => void;
    onClose: () => void;
    onNext: (commitMessage: string) => Promise<boolean | undefined>;
}

const CommitMessageStep: React.FC<{ commitMessage: string; onChange: (newValue: string) => void }> = ({ commitMessage, onChange }) => {
    return (
        <React.Fragment>
            <h2>1. Add your first commit message</h2>
            <Note className={styles.commitNote}>Your initial commit will include any data stored in Octopus for this project's process and variables. If there is nothing stored the commit will be empty</Note>
            <div>
                <Text key="commitMessage" id="commitMessage" className={styles.commitMessage} value={commitMessage} onChange={onChange} label="Commit message" validate={required("Please enter a commit message.")} />
            </div>
        </React.Fragment>
    );
};

const CloningStep: React.FC<{ errors?: Errors }> = ({ errors }) => {
    return !errors ? (
        <React.Fragment>
            <h2>2. We are cloning your repository</h2>
            <CircularProgress />
        </React.Fragment>
    ) : (
        <React.Fragment>
            <ErrorPanel {...errors} />
        </React.Fragment>
    );
};

const CompletedStep: React.FC<{ project: ProjectResource }> = ({ project }) => {
    return withTheme(theme => {
        return (
            <React.Fragment>
                <CheckCircleIcon fontSize="large" color="primary" style={{ fill: theme.successText }} />
                <h2>Your project now supports version control.</h2>

                <Note className={styles.completedNote}>You can now create branches and commit different versions to your repo.</Note>
                <br />
                <Note className={styles.completedNote}>
                    We have created a folder called <code>.octopus</code> in your Git repository where <br />
                    this project's process and variables will be stored as code.
                </Note>
                <br />
                <div>
                    <NavigationButton label="Go to Variables" href={routeLinks.project(project.Id).variables.root} />
                    <NavigationButton label="Go to Process" href={routeLinks.project(project.Id).deployments.process.root} />
                </div>
            </React.Fragment>
        );
    });
};

const CommitDialog: React.FC<CommitDialogProps> = ({ open, step, errors, project, onClose, onNext, onStepChange }) => {
    const [activeStep, setStep] = React.useState(step);
    const [commitMessage, setCommitMessage] = React.useState("");

    const changeStep = (value: CommitDialogStep) => {
        setStep(value);
        onStepChange(value);
    };

    const commitChanges = async () => {
        changeStep(CommitDialogStep.Cloning);

        const success = await onNext(commitMessage);

        if (success) {
            changeStep(CommitDialogStep.CloneCompleted);
        }
    };

    const steps: Record<CommitDialogStep, JSX.Element> = {
        [CommitDialogStep.CommitMessage]: <CommitMessageStep commitMessage={commitMessage} onChange={(value: string) => setCommitMessage(value)} />,
        [CommitDialogStep.Cloning]: <CloningStep errors={errors} />,
        [CommitDialogStep.CloneCompleted]: <CompletedStep project={project} />,
    };

    const stepper = (
        <div className={styles.stepper}>
            <Stepper activeStep={activeStep - 1}>
                <Step key="commitMessage">
                    <StepLabel>{""}</StepLabel>
                </Step>
                <Step key="cloning">
                    <StepLabel>{""}</StepLabel>
                </Step>
            </Stepper>
        </div>
    );

    const actions = () => {
        if (activeStep === CommitDialogStep.CommitMessage) {
            return [<ActionButton key="Cancel" label="Cancel" title="Cancel" onClick={onClose} />, <ActionButton key="Next" label="Next" title="Next" onClick={commitChanges} type={ActionButtonType.Save} disabled={!commitMessage} />];
        }

        if (activeStep === CommitDialogStep.CloneCompleted) {
            return [<ActionButton key="Close" label="Close" title="Close" onClick={onClose} type={ActionButtonType.Primary} />];
        }

        if (errors) {
            return [<ActionButton key="Cancel" label="Cancel" title="Cancel" onClick={onClose} />];
        }
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogLayout actions={actions()} title={"Configure Project"} closeDialog={onClose}>
                {open && (
                    <Grid container direction="column" alignItems="center" className={styles.commitDialog}>
                        {activeStep !== CommitDialogStep.CloneCompleted && stepper}
                        {steps[activeStep]}
                    </Grid>
                )}
            </DialogLayout>
        </Dialog>
    );
};

export default CommitDialog;
