import { DynamicWorkerPoolResource } from "client/resources";
import WorkerPoolEditBase, { WorkerPoolEditState } from "./WorkerPoolEditBase";
import { cloneDeep } from "lodash";
import { repository } from "clientInstance";
import { DynamicWorkerType } from "client/resources/workerPoolsSupportedTypesResouce";
import { Summary, Select, ExpandableFormSection } from "components/form";
import React = require("react");

interface DynamicWorkerPoolEditState extends WorkerPoolEditState<DynamicWorkerPoolResource> {
    workerTypes: DynamicWorkerType[];
}

class DynamicWorkerPoolEdit extends WorkerPoolEditBase<DynamicWorkerPoolResource, DynamicWorkerPoolEditState> {
    getDefaultState(): DynamicWorkerPoolEditState {
        return {
            deleted: false,
            model: this.props.workerPool,
            cleanModel: cloneDeep(this.props.workerPool),
            workerTypes: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const dynamicWorkerTypes = await repository.WorkerPools.getDynamicWorkerTypes();
            this.setState({
                workerTypes: dynamicWorkerTypes,
            });
        });
    }

    customExpandableFormSections(): JSX.Element[] {
        const baseElements: JSX.Element[] = [];
        const items = this.state.workerTypes ? this.state.workerTypes.map(t => ({ value: t.Id, text: t.Description })) : [];

        baseElements.push(
            <ExpandableFormSection key="WorkerType" errorKey="WorkerType" title="Worker Image" summary={this.workerTypeSummary()} help="The worker base image">
                <Select value={this.state.model.WorkerType} onChange={this.handleWorkerTypeChange} items={items} />
            </ExpandableFormSection>
        );

        return baseElements;
    }

    workerTypeSummary() {
        return this.state.model.WorkerType ? Summary.summary(this.state.workerTypes.find(x => x.Id == this.state.model.WorkerType)?.Description) : Summary.placeholder("No worker image selected");
    }

    private handleWorkerTypeChange = (value: string) => {
        const workerType = this.state.workerTypes.find(l => l.Id === value);
        if (!workerType) {
            throw Error("Could not locate selected worker image");
        }

        this.setModelState({ WorkerType: workerType.Id });
    };
}

export default DynamicWorkerPoolEdit;
