/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { LocationDescriptor } from "history";
import { exposeComponentAsClass } from "../Navigation/exposeComponentAsClass";
import { RouteComponentProps, withRouter } from "react-router";
import { resolvePathWithSpaceId } from "../Navigation/resolvePathWithSpaceId";

interface OverflowMenuLinkComponentProps {
    to: LocationDescriptor;
    resolve?: boolean;
    downloadFileName?: string;
}

type OverflowMenuLinkProps = OverflowMenuLinkComponentProps & RouteComponentProps<{ spaceId: string }>;

const OverflowMenuLink: React.SFC<OverflowMenuLinkProps> = (props: OverflowMenuLinkProps) => {
    const resolvedTo = props.resolve ? resolvePathWithSpaceId(props.to, props.match.params.spaceId) : props.to;
    const href = typeof resolvedTo === "string" ? resolvedTo : props.history.createHref(resolvedTo);

    const download = props.downloadFileName ? { download: props.downloadFileName, target: "_blank", rel: "noopener noreferrer" } : {};

    // Ideally we would not use spread operator here. Unfortunately, material-ui injects bunch of properties here
    // and we need to pass all of them so the UI looks consistent.
    const { staticContext, resolve, to, downloadFileName, ...rest } = props as any;
    return <a {...rest} href={href} {...download} />;
};

OverflowMenuLink.defaultProps = {
    resolve: true,
};

export default exposeComponentAsClass(withRouter(OverflowMenuLink));
