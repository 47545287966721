/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import {
    ScheduleIntervalResource,
    TriggerScheduleIntervalResource,
    TriggerScheduleResource,
    ServerTimezoneResource,
    TriggerScheduleIntervalType,
    ContinuousDailyTriggerScheduleResource,
    TriggerFilterType,
    OnceDailyTriggerScheduleResource,
    DayOfWeek,
} from "client/resources";
import ScheduleInterval from "./ScheduleInterval";
import ScheduleStart from "./ScheduleStart";
import { ExpandableFormSection, Summary, Checkbox } from "components/form";
import cn from "classnames";
const styles = require("../style.less");
import { Moment } from "moment";
import moment from "moment";
import { ReactNode } from "react";
import ScheduleTimezone from "./ScheduleTimezone";
import { trigger } from "areas/projects/components/routeLinkHelpers";
import ScheduleRunAfterAndRunUntil from "./ScheduleRunAfterAndRunUntil";

interface DailyScheduledTriggerEditorProps {
    triggerFilterType: TriggerFilterType;
    onceDailySchedule: OnceDailyTriggerScheduleResource;
    continuousDailySchedule: ContinuousDailyTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onOnceDailyScheduleChange(schedule: OnceDailyTriggerScheduleResource): void;
    onContinuousDailyScheduleChange(schedule: ContinuousDailyTriggerScheduleResource): void;
    onScheduleTypeChange(type: TriggerFilterType): void;
}

interface ScheduleDays {
    Monday?: boolean;
    Tuesday?: boolean;
    Wednesday?: boolean;
    Thursday?: boolean;
    Friday?: boolean;
    Saturday?: boolean;
    Sunday?: boolean;
}
interface DailyScheduleTriggerEditorState {
    scheduleDays: ScheduleDays;
    scheduleInterval: TriggerScheduleIntervalResource;
    scheduleStartTime: Moment;
    scheduleRunAfter: Moment;
    scheduleRunUntil: Moment;
    scheduleTimezone: string;
}

export function isContinuousDailyTriggerScheduleResource(resource: OnceDailyTriggerScheduleResource | ContinuousDailyTriggerScheduleResource): resource is ContinuousDailyTriggerScheduleResource {
    if ((resource as ContinuousDailyTriggerScheduleResource).Interval) {
        return true;
    }
    return false;
}

export class DailyScheduledTriggerEditor extends React.Component<DailyScheduledTriggerEditorProps, DailyScheduleTriggerEditorState> {
    constructor(props: DailyScheduledTriggerEditorProps) {
        super(props);
        this.state = this.initState(this.props.onceDailySchedule);
    }

    componentWillReceiveProps(newProps: DailyScheduledTriggerEditorProps) {
        if (newProps.triggerFilterType === TriggerFilterType.OnceDailySchedule) {
            if (newProps.onceDailySchedule.StartTime !== this.props.onceDailySchedule.StartTime || newProps.onceDailySchedule.Timezone !== this.props.onceDailySchedule.Timezone) {
                this.setState(this.initState<OnceDailyTriggerScheduleResource>(newProps.onceDailySchedule));
            }
        } else if (newProps.triggerFilterType === TriggerFilterType.ContinuousDailySchedule) {
            if (
                newProps.continuousDailySchedule.Interval !== this.props.continuousDailySchedule.Interval ||
                newProps.continuousDailySchedule.HourInterval !== this.props.continuousDailySchedule.HourInterval ||
                newProps.continuousDailySchedule.MinuteInterval !== this.props.continuousDailySchedule.MinuteInterval ||
                newProps.continuousDailySchedule.RunAfter !== this.props.continuousDailySchedule.RunAfter ||
                newProps.continuousDailySchedule.RunUntil !== this.props.continuousDailySchedule.RunUntil ||
                newProps.continuousDailySchedule.Timezone !== this.props.continuousDailySchedule.Timezone
            ) {
                this.setState(this.initState<ContinuousDailyTriggerScheduleResource>(newProps.continuousDailySchedule));
            }
        }
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="ScheduleDaysOfWeek" title="Run Days" summary={this.getRunOnDaysSummary()} help="Select what days of the week the schedule should execute.">
                    <div className={cn(styles.checkboxGroup, styles.horizontal)}>
                        <div className={styles.checkbox}>
                            <Checkbox label="Monday" value={this.state.scheduleDays.Monday!} onChange={checked => this.onScheduleDaysChange({ Monday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Tuesday" value={this.state.scheduleDays.Tuesday!} onChange={checked => this.onScheduleDaysChange({ Tuesday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Wednesday" value={this.state.scheduleDays.Wednesday!} onChange={checked => this.onScheduleDaysChange({ Wednesday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Thursday" value={this.state.scheduleDays.Thursday!} onChange={checked => this.onScheduleDaysChange({ Thursday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Friday" value={this.state.scheduleDays.Friday!} onChange={checked => this.onScheduleDaysChange({ Friday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Saturday" value={this.state.scheduleDays.Saturday!} onChange={checked => this.onScheduleDaysChange({ Saturday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Sunday" value={this.state.scheduleDays.Sunday!} onChange={checked => this.onScheduleDaysChange({ Sunday: checked })} />
                        </div>
                    </div>
                </ExpandableFormSection>
                <ScheduleTimezone timezones={this.props.timezones} timezone={this.state.scheduleTimezone} onTimezoneChanged={this.onScheduleTimezoneChange} />
                <ScheduleInterval interval={this.state.scheduleInterval} onChange={this.onScheduleIntervalChange} />
                {this.props.triggerFilterType === TriggerFilterType.OnceDailySchedule ? (
                    <ScheduleStart startTime={this.state.scheduleStartTime} onStartTimeChanged={this.onScheduleStartChange} />
                ) : (
                    <ScheduleRunAfterAndRunUntil runAfter={this.state.scheduleRunAfter} runUntil={this.state.scheduleRunUntil} onRunAfterChanged={this.onScheduleRunAfterChange} onRunUntilChanged={this.onScheduleRunUntilChange} />
                )}
            </div>
        );
    }

    initState<T>(value?: T) {
        const schedule = (value as unknown) as OnceDailyTriggerScheduleResource | ContinuousDailyTriggerScheduleResource;

        const currentDate = new Date();

        const defaultScheduleDays: ScheduleDays = {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
        };

        return {
            scheduleDays: schedule.DaysOfWeek.reduce((scheduleDays: ScheduleDays, day) => {
                scheduleDays[day] = true;
                return scheduleDays;
            }, defaultScheduleDays),
            scheduleInterval: {
                Interval: TriggerScheduleIntervalType.OnceDaily,
            },
            scheduleStartTime: this.state && this.state.scheduleStartTime ? this.state.scheduleStartTime : moment(new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 9, 0, 0))).utc(),
            scheduleRunAfter: this.state && this.state.scheduleRunAfter ? this.state.scheduleRunAfter : moment(new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0))).utc(),
            scheduleRunUntil: this.state && this.state.scheduleRunUntil ? this.state.scheduleRunUntil : moment(new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 0))).utc(),
            ...(isContinuousDailyTriggerScheduleResource(schedule)
                ? {
                      scheduleInterval: {
                          HourInterval: schedule.HourInterval,
                          MinuteInterval: schedule.MinuteInterval,

                          Interval: schedule.Interval,
                      },

                      scheduleRunAfter: moment(schedule.RunAfter).utc(),
                      scheduleRunUntil: moment(schedule.RunUntil).utc(),
                  }
                : {}),
            ...(!isContinuousDailyTriggerScheduleResource(schedule)
                ? {
                      scheduleStartTime: moment(schedule.StartTime).utc(),
                  }
                : {}),
            scheduleTimezone: schedule.Timezone,
        };
    }

    private getRunOnDaysSummary() {
        if (this.noDaysSelected()) {
            return Summary.placeholder("Select what days of the week the schedule should execute.");
        }
        let summary: ReactNode = "";
        if (this.runsOnWeekdays()) {
            summary = (
                <span>
                    Scheduled to run on <strong>weekdays</strong>
                </span>
            );
        } else if (this.runsOnWeekends()) {
            summary = (
                <span>
                    Scheduled to run on <strong>weekends</strong>
                </span>
            );
        } else {
            summary = (
                <span>
                    Scheduled to run on <strong>{this.getDaysScheduledToRunOn().join(", ")}</strong>
                </span>
            );
        }
        return Summary.summary(summary);
    }

    private noDaysSelected() {
        return (
            !this.state.scheduleDays.Monday &&
            !this.state.scheduleDays.Tuesday &&
            !this.state.scheduleDays.Wednesday &&
            !this.state.scheduleDays.Thursday &&
            !this.state.scheduleDays.Friday &&
            !this.state.scheduleDays.Saturday &&
            !this.state.scheduleDays.Sunday
        );
    }
    private runsOnWeekdays(): boolean {
        return (this.state.scheduleDays.Monday &&
            this.state.scheduleDays.Tuesday &&
            this.state.scheduleDays.Wednesday &&
            this.state.scheduleDays.Thursday &&
            this.state.scheduleDays.Friday &&
            !this.state.scheduleDays.Saturday &&
            !this.state.scheduleDays.Sunday) as boolean;
    }

    private runsOnWeekends(): boolean {
        return (this.state.scheduleDays.Saturday &&
            this.state.scheduleDays.Sunday &&
            !this.state.scheduleDays.Monday &&
            !this.state.scheduleDays.Tuesday &&
            !this.state.scheduleDays.Wednesday &&
            !this.state.scheduleDays.Thursday &&
            !this.state.scheduleDays.Friday) as boolean;
    }

    private getDaysScheduledToRunOn(): DayOfWeek[] {
        const daysToRunOn = [];
        if (this.state.scheduleDays.Monday) {
            daysToRunOn.push(DayOfWeek.Monday);
        }
        if (this.state.scheduleDays.Tuesday) {
            daysToRunOn.push(DayOfWeek.Tuesday);
        }
        if (this.state.scheduleDays.Wednesday) {
            daysToRunOn.push(DayOfWeek.Wednesday);
        }
        if (this.state.scheduleDays.Thursday) {
            daysToRunOn.push(DayOfWeek.Thursday);
        }
        if (this.state.scheduleDays.Friday) {
            daysToRunOn.push(DayOfWeek.Friday);
        }
        if (this.state.scheduleDays.Saturday) {
            daysToRunOn.push(DayOfWeek.Saturday);
        }
        if (this.state.scheduleDays.Sunday) {
            daysToRunOn.push(DayOfWeek.Sunday);
        }
        return daysToRunOn;
    }

    private onScheduleDaysChange = (value: ScheduleDays) => {
        this.setState(
            prev => ({
                scheduleDays: {
                    ...prev.scheduleDays,
                    ...value,
                },
            }),
            () => this.raiseChange()
        );
    };

    private onScheduleIntervalChange = (interval: TriggerScheduleIntervalResource) => {
        this.setState(
            {
                scheduleInterval: { ...interval },
            },
            () => {
                this.raiseChange();
            }
        );
    };

    private onScheduleStartChange = (startTime: Moment) => {
        this.setState(
            {
                scheduleStartTime: startTime,
            },
            () => this.raiseChange()
        );
    };

    private resetToCurrentDay = (datetime: Moment) => {
        const dayFormat = "DD/MM/YYYY";
        const timeFormat = "HH:mm";
        const day = moment(Date.now()).format(dayFormat);
        const time = datetime.clone().format(timeFormat);
        return moment(day + time, dayFormat + timeFormat);
    };

    private onScheduleRunAfterChange = (runAfter: Moment) => {
        this.setState(
            {
                scheduleRunAfter: runAfter,
                ...(this.resetToCurrentDay(runAfter).isSameOrAfter(this.resetToCurrentDay(this.state.scheduleRunUntil)) ? { scheduleRunUntil: runAfter.clone().add(1, "minute") } : { scheduleRunUntil: this.state.scheduleRunUntil }),
            },
            () => this.raiseChange()
        );
    };

    private onScheduleRunUntilChange = (runUntil: Moment) => {
        this.setState(
            {
                scheduleRunUntil: runUntil,
                ...(this.resetToCurrentDay(runUntil).isSameOrBefore(this.resetToCurrentDay(this.state.scheduleRunAfter)) ? { scheduleRunAfter: runUntil.clone().subtract(1, "minute") } : { scheduleRunAfter: this.state.scheduleRunAfter }),
            },
            () => this.raiseChange()
        );
    };

    private onScheduleTimezoneChange = (timezone: string) => {
        this.setState(
            {
                scheduleTimezone: timezone,
            },
            () => this.raiseChange()
        );
    };

    private raiseChange() {
        const interval = this.state.scheduleInterval;

        if (interval.Interval === TriggerScheduleIntervalType.OnceDaily) {
            this.props.onOnceDailyScheduleChange({
                Timezone: this.state.scheduleTimezone,
                FilterType: TriggerFilterType.OnceDailySchedule,
                DaysOfWeek: this.getDaysScheduledToRunOn(),
                StartTime: this.state.scheduleStartTime.toDate(),
            });
        } else {
            this.props.onContinuousDailyScheduleChange({
                Timezone: this.state.scheduleTimezone,
                FilterType: TriggerFilterType.ContinuousDailySchedule,
                DaysOfWeek: this.getDaysScheduledToRunOn(),
                RunAfter: this.state.scheduleRunAfter.toDate(),
                RunUntil: this.state.scheduleRunUntil.toDate(),
                Interval: this.state.scheduleInterval.Interval,
                HourInterval: this.state.scheduleInterval.HourInterval,
                MinuteInterval: this.state.scheduleInterval.MinuteInterval,
            });
        }
    }
}
