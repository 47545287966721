/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { DataBaseComponentState } from "components/DataBaseComponent";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";
import Checkbox from "../../../../../components/form/Checkbox/Checkbox";
import ToolTip from "../../../../../components/ToolTip/index";
import Markdown from "../../../../../components/Markdown/index";
import { repository } from "clientInstance";
import FeedResource, { FeedType } from "../../../../../client/resources/feedResource";
import { PackageVersionResource } from "../../../../../client/resources/packageResource";
import { DataTable, DataTableRow } from "components/DataTable";
import { DataTableHeader } from "../../../../../components/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "../../../../../components/DataTable/DataTableHeaderColumn";
import { DataTableBody } from "../../../../../components/DataTable/DataTableBody";
import { DataTableRowColumn } from "../../../../../components/DataTable/DataTableRowColumn";
import ActionButton, { ActionButtonType } from "../../../../../components/Button/ActionButton";
const styles = require("./style.less");
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import { Callout, CalloutType } from "components/Callout/Callout";
import { PackageEditInfo } from "../packageModel";
import moment from "moment";
import { ResourceCollection } from "client/resources";
import { PackageSearchOptions } from "client/repositories/feedRepository";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import Note from "components/form/Note/Note";
import cn from "classnames";

interface PackageListDialogProps {
    pack: PackageEditInfo;
    channelFilters: {
        versionRange?: string;
        preReleaseTag?: string;
    };
    onVersionSelected(selectedVersion: string): void;
}

interface PackageListDialogState extends DataBaseComponentState {
    includePrereleasePackages: boolean;
    includeReleaseNotes: boolean;
    channelRules: boolean;
    feed: FeedResource;
    selectedPackageVersion: string;
    validChannelVersions: string[];
    packageResults: ResourceCollection<PackageVersionResource>;
    take: number;
    packageFilter: string;
}

class PackageListDialogContent extends DataBaseComponent<PackageListDialogProps, PackageListDialogState> {
    constructor(props: PackageListDialogProps) {
        super(props);
        this.state = {
            includePrereleasePackages: false,
            includeReleaseNotes: false,
            channelRules: true,
            feed: null!,
            selectedPackageVersion: null!,
            validChannelVersions: [],
            packageResults: null!,
            take: 5,
            packageFilter: null!,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            if (this.props.pack) {
                const feed = await repository.Feeds.get(this.props.pack.FeedId);
                // if the API doesn't find the feed API it returns all feeds in our collection object
                if (feed.hasOwnProperty("FeedType")) {
                    this.setState({ feed });
                    this.refresh();
                } else {
                    this.setState({ errors: { message: "Unable to load the feed " + this.props.pack.FeedId, errors: [], fieldErrors: {}, details: {} } });
                }
            }
        });
    }

    render() {
        const hasChannelRules = this.props.channelFilters.versionRange || this.props.channelFilters.preReleaseTag;
        const canFeedShowReleaseNotes = this.state.feed && (this.state.feed.FeedType === FeedType.BuiltIn || this.state.feed.FeedType === FeedType.Nuget || this.state.feed.FeedType === FeedType.GitHub);
        const canFeedShowPublishDates = this.state.feed && (this.state.feed.FeedType === FeedType.BuiltIn || this.state.feed.FeedType === FeedType.Nuget || this.state.feed.FeedType === FeedType.GitHub);

        return (
            <OkDialogLayout title={`${this.props.pack.PackageId} previous versions`} onOkClick={() => this.onPackageSelected()} busy={this.state.busy} errors={this.state.errors}>
                {this.props.pack && (
                    <div>
                        <div>
                            {this.state.packageResults && (
                                <p>
                                    Prior versions of package {this.props.pack.PackageId} from feed {this.props.pack.FeedName}
                                </p>
                            )}
                            <div className={styles.optionsBar}>
                                <div className={styles.checkbox}>
                                    <Checkbox value={this.state.includePrereleasePackages} label="Pre-release packages" onChange={includePrereleasePackages => this.setState({ includePrereleasePackages }, this.refresh)} />
                                </div>
                                {hasChannelRules && (
                                    <div className={styles.checkbox}>
                                        <Checkbox value={this.state.channelRules} label="Only this channel" onChange={channelRules => this.setState({ channelRules }, this.refresh)} />
                                    </div>
                                )}
                                {canFeedShowReleaseNotes && (
                                    <div className={styles.checkbox}>
                                        <Checkbox value={this.state.includeReleaseNotes} label="Release notes" onChange={includeReleaseNotes => this.setState({ includeReleaseNotes }, this.refresh)} />
                                    </div>
                                )}
                                <div style={{ flex: 1, textAlign: "right" }}>
                                    <ActionButton label="Refresh" disabled={this.state.busy} type={ActionButtonType.Secondary} onClick={() => this.refresh()} />
                                </div>
                            </div>
                            <div className={styles.optionsBar}>
                                <div>
                                    <FilterSearchBox
                                        hintText={"Search versions..."}
                                        value={this.state.packageFilter}
                                        onChange={x => {
                                            this.setState({ packageFilter: x }, () => {
                                                this.refresh();
                                            });
                                        }}
                                        autoFocus={true}
                                    />
                                </div>
                            </div>
                            {this.state.packageResults && (
                                <DataTable>
                                    <DataTableHeader>
                                        <DataTableRow>
                                            <DataTableHeaderColumn>Version</DataTableHeaderColumn>
                                            {canFeedShowPublishDates && <DataTableHeaderColumn>Published</DataTableHeaderColumn>}
                                        </DataTableRow>
                                    </DataTableHeader>
                                    {this.state.packageResults.Items.map((row: PackageVersionResource, index: number) => (
                                        <DataTableBody key={index}>
                                            <DataTableRow>
                                                <DataTableRowColumn className={styles.tableColumn}>
                                                    <div className={styles.versionRadio} style={{ display: "flex", alignItems: "center" }}>
                                                        <div className={styles.infoCircle}>
                                                            {!this.state.validChannelVersions.includes(row.Version) && (
                                                                <ToolTip content="Package version does not satisfy channel rules">
                                                                    <span className="fa pull-right fa-info-circle" />
                                                                </ToolTip>
                                                            )}
                                                        </div>
                                                        <RadioButtonGroup value={this.state.selectedPackageVersion} onChange={() => this.setState({ selectedPackageVersion: row.Version })}>
                                                            <RadioButton value={row.Version} label={row.Version} />
                                                        </RadioButtonGroup>
                                                    </div>
                                                </DataTableRowColumn>
                                                {canFeedShowPublishDates && <DataTableRowColumn className={styles.tableColumn}>{row.Published && moment(row.Published).format("llll")}</DataTableRowColumn>}
                                            </DataTableRow>
                                            {this.state.includeReleaseNotes && row.ReleaseNotes && (
                                                <DataTableRow>
                                                    <DataTableRowColumn colSpan={3}>
                                                        <Markdown markup={row.ReleaseNotes} />
                                                    </DataTableRowColumn>
                                                </DataTableRow>
                                            )}
                                        </DataTableBody>
                                    ))}
                                </DataTable>
                            )}
                            {this.renderLoadMore()}
                            {!this.state.channelRules && (
                                <div className={styles.legend}>
                                    <Note>
                                        Packages marked <span className={cn(styles.infoIcon, "fa fa-info-circle")} /> don't match the selected channel version rules.
                                    </Note>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </OkDialogLayout>
        );
    }

    private renderLoadMore = () => {
        if (!this.state.packageResults || this.state.packageResults.ItemsPerPage >= this.state.packageResults.TotalResults) {
            return null;
        }
        return (
            <div className={styles.loadMore}>
                <ActionButton label="Load more" onClick={() => this.refresh(this.state.take * 2)} />
            </div>
        );
    };

    private onPackageSelected = () => {
        this.props.onVersionSelected(this.state.selectedPackageVersion);
        return true;
    };

    private refresh = (take: number = 20) => {
        this.setState({ take }, () => {
            return this.doBusyTask(async () => {
                if (this.state.channelRules) {
                    const packageResults = await this.getPackages(this.props.channelFilters);
                    const validChannelVersions = packageResults.Items.map(o => o.Version);
                    this.setState({ packageResults, validChannelVersions });
                } else {
                    const packageResults = await this.getPackages();
                    this.setState({ packageResults });
                }
            });
        });
    };

    private getPackages = async (packageSearchOptions?: Partial<PackageSearchOptions>): Promise<ResourceCollection<PackageVersionResource>> => {
        return repository.Feeds.searchPackageVersions(this.state.feed, this.props.pack.PackageId, {
            take: this.state.take,
            includePreRelease: this.state.includePrereleasePackages,
            includeReleaseNotes: this.state.includeReleaseNotes,
            filter: this.state.packageFilter ? this.state.packageFilter : null!,
            ...packageSearchOptions,
        });
    };
}

export default PackageListDialogContent;
