/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import { ExpandableFormSection, Summary } from "components/form";
import { TagSetResource, TagResource, Permission, MultiTenancyStatusResource } from "client/resources";
import Text from "components/form/Text/Text";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import Markdown from "components/Markdown";
import { required } from "components/form/Validators";
import { sortBy } from "lodash";
import { Callout } from "components/Callout";
import { CalloutType } from "components/Callout/Callout";
import Tag from "../../../../components/Tag/Tag";
import TagListEdit from "./TagListEdit/TagListEdit";
import TagModel from "./TagListEdit/TagModel";
import { RouteComponentProps } from "react-router";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
const styles = require("./style.less");
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, Action } from "redux";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { configurationActions } from "../../../configuration/reducers/configurationArea";
import { withTheme } from "components/Theme";

interface TagSetEditModel {
    name: string;
    description: string;
    tags: TagModel[];
}

interface GlobalDispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
}

interface TagSetEditState extends OptionalFormBaseComponentState<TagSetEditModel> {
    tagSet: TagSetResource;
    deleted: boolean;
    newId?: string;
    currentTag: Partial<TagModel>;
}

type Props = GlobalDispatchProps & RouteComponentProps<{ tagSetId: string }>;

class TagSetEdit extends FormBaseComponent<Props, TagSetEditState, TagSetEditModel> {
    private tagListEdit: TagListEdit | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            tagSet: null!,
            model: null!,
            cleanModel: null!,
            deleted: false,
            currentTag: null!,
        };
    }

    currentTagSetId(): string {
        return this.props.match.params.tagSetId;
    }

    async componentDidMount() {
        if (this.state.deleted) {
            return;
        }
        await this.doBusyTask(async () => {
            const tagSet = this.currentTagSetId() ? await repository.TagSets.get(this.currentTagSetId()) : null!;
            this.setState({ tagSet, model: this.buildModel(tagSet!), cleanModel: this.buildModel(tagSet!) });
        });
    }

    buildModel(tagSet: TagSetResource): TagSetEditModel {
        if (tagSet) {
            return {
                name: tagSet.Name,
                description: tagSet.Description,
                tags: tagSet.Tags.map(t => ({
                    originalId: t.Id,
                    localId: t.Id || Math.random() + "",
                    name: t.Name,
                    description: t.Description,
                    sortOrder: t.SortOrder,
                    color: t.Color,
                    deleted: false,
                })),
            };
        }
        return {
            name: "",
            description: "",
            tags: [],
        };
    }

    handleDeleteConfirm = async () => {
        const result = await repository.TagSets.del(this.state.tagSet);
        const status = await repository.Tenants.status();
        this.props.onSpaceMultiTenancyStatusFetched(status);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            //the child component actually has a more up to date view of the world than
            //we do, especially when a user has partially added an item and forgotten
            //to hit save. This is a... _less than ideal_ way around the problem.
            //Thes components could do with a refactor
            const tags = this.tagListEdit ? this.tagListEdit.getTagSetIncludingAnyNewTag() : this.state.model!.tags;

            let tagSet: TagSetResource = {
                ...this.state.tagSet,
                Name: this.state.model!.name,
                Description: this.state.model!.description,
                Tags: tags
                    .filter(t => !t.deleted)
                    .map(tag => ({
                        Id: tag.originalId,
                        Name: tag.name,
                        SortOrder: tag.sortOrder,
                        Color: tag.color,
                        Description: tag.description,
                        CanonicalTagName: null!,
                    })),
            };
            tagSet = await repository.TagSets.save(tagSet);
            this.setState({
                tagSet,
                model: this.buildModel(tagSet),
                cleanModel: this.buildModel(tagSet),
                newId: this.currentTagSetId() ? null! : tagSet.Id,
            });
        });
    };

    descriptionSummary() {
        return this.state.model!.description ? Summary.summary(<Markdown markup={this.state.model!.description} />) : Summary.placeholder("No description provided");
    }

    nameSummary() {
        return this.state.model!.name ? Summary.summary(this.state.model!.name) : Summary.placeholder("Please enter a name for your tag set");
    }

    tagsSummary() {
        return this.state.model!.tags && this.state.model!.tags.length > 0
            ? Summary.summary(
                  <div>
                      {sortBy(this.state.model!.tags, tag => tag.sortOrder)
                          .map(tag => this.tagModelToResource(tag))
                          .map((tag, i) => withTheme(theme => <Tag borderColor={theme.divider} tagName={tag.Name} description={tag.Description} tagColor={tag.Color} key={i} />))}
                  </div>
              )
            : Summary.placeholder("No tags have been provided");
    }

    tagModelToResource(tag: TagModel): TagResource {
        return {
            Id: tag.originalId,
            Name: tag.name,
            SortOrder: tag.sortOrder,
            Color: tag.color,
            Description: tag.description,
            CanonicalTagName: null!,
        };
    }

    render() {
        const title = !this.currentTagSetId() ? "New Tenant Tag Set" : this.state.model ? this.state.model.name : StringHelper.ellipsis;

        const overFlowActions = this.currentTagSetId()
            ? [
                  OverflowMenuItems.deleteItemDefault("tag set", this.handleDeleteConfirm, { permission: Permission.TagSetDelete }),
                  [
                      OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.currentTagSetId()]), null!, {
                          permission: Permission.EventView,
                          wildcard: true,
                      }),
                  ],
              ]
            : [];

        return (
            <LibraryLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Tenant Tag Sets"}
                    breadcrumbPath={routeLinks.library.tagSets.root}
                    saveText="Tag Set details changed"
                    busy={this.state.busy}
                    model={this.state.model}
                    errors={this.state.errors}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: !this.currentTagSetId() ? Permission.TagSetCreate : Permission.TagSetEdit }}
                    onSaveClick={this.handleSaveClick}
                    expandAllOnMount={!this.currentTagSetId()}
                    overFlowActions={overFlowActions}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.tagSets.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.library.tagSet(this.state.newId)} />}
                    {this.state.model && (
                        <div className={styles.expanderContainer}>
                            <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.nameSummary()} help="A short, memorable, unique name for this tag set. Examples: Customer Type, Hosting, Module.">
                                <Text value={this.state.model.name} onChange={name => this.setModelState({ name })} label="Tag Set name" validate={required("Please enter a tag set name")} autoFocus={true} />
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="This summary will be presented to users when tagging the tenant, or applying tags to other entities.">
                                <MarkdownEditor value={this.state.model.description} label="Tag set description" onChange={description => this.setModelState({ description })} />
                            </ExpandableFormSection>
                            <ExpandableFormSection
                                errorKey="tags"
                                title="Tags"
                                summary={this.tagsSummary()}
                                help={
                                    !!this.state.model.tags.find(t => t.deleted && !!t.originalId) ? (
                                        <Callout type={CalloutType.Warning} title={"In-use tags cannot be deleted"}>
                                            This action will fail if the tag is already in use.
                                        </Callout>
                                    ) : (
                                        <div>&nbsp;</div>
                                    )
                                }
                            >
                                <div>
                                    <TagListEdit
                                        tags={this.state.model.tags}
                                        currentTag={this.state.currentTag}
                                        onChange={tags => {
                                            this.setModelState({ tags: [...tags] });
                                        }}
                                        onClose={x => {
                                            this.setState({ currentTag: x });
                                        }}
                                        ref={tagListEdit => (this.tagListEdit = tagListEdit)}
                                    />
                                </div>
                            </ExpandableFormSection>
                        </div>
                    )}
                </FormPaperLayout>
            </LibraryLayout>
        );
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>): GlobalDispatchProps => bindActionCreators({ onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched }, dispatch);

export default connect(null, mapGlobalActionDispatchersToProps)(TagSetEdit);
