/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";

interface CloseButtonProps {
    onClose?: () => void;
    tooltip?: string;
    size?: string;
}

const CloseButton: React.SFC<CloseButtonProps> = props => {
    const style = { verticalAlign: "middle", padding: "0" } as any;
    if (props.size) {
        style.width = props.size;
        style.height = props.size;
    }

    return <IconButton style={style} onClick={props.onClose} toolTipContent={props.tooltip} icon={Icon.Cancel} />;
};

CloseButton.displayName = "CloseButton";

export default CloseButton;
