import * as React from "react";
import { WorkerPoolResource, TagSetResource, IProcessResource, isDeploymentProcessResource, isRunbookProcessResource, ProcessType } from "client/resources";
import { repository } from "clientInstance";
import { ScriptModule } from "../Process/Common/SideBar";
import { ProjectResource } from "client/resources/projectResource";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { EnvironmentResource } from "client/resources/environmentResource";
import { LifecycleResource } from "client/resources/lifecycleResource";
import { ResourcesById } from "client/repositories/basicRepository";
import { ChannelResource } from "client/resources/channelResource";
import { keyBy } from "lodash";
import * as tenantTagsets from "components/tenantTagsets";
import { RouteComponentProps } from "react-router";
import Permission from "client/resources/permission";
import { useProjectContext } from "../../context";
import { connect } from "react-redux";
import configurationSelectors from "areas/configuration/reducers/selectors";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Errors } from "components/DataBaseComponent/Errors";
import { ProcessContextFormPage } from "./Contexts/ProcessContextFormPage";
import ProcessListLayout from "./ProcessListLayout";
import StringHelper from "utils/StringHelper";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { ProcessPageSupportedActions } from "./types";
import { ProcessController } from "./Contexts/ProcessController";

interface GlobalConnectedProps {
    isActionContainersEnabled?: boolean;
    isBuiltInWorkerEnabled?: boolean;
}

interface ProcessState extends DataBaseComponentState {
    lookups: ProcessListLayoutLoaderLookupData;
}

interface ProcessProps extends RouteComponentProps<{ projectSlug: string }>, GlobalConnectedProps {
    processId: string;
    processType: ProcessType;
}

type ProcessPageProps = ProcessProps;
type Props = ProcessProps & { project: ProjectResource };

export interface ProcessListLayoutLoaderLookupData {
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource | null;
    environmentsById: ResourcesById<EnvironmentResource>;
    channelsById: ResourcesById<ChannelResource> | null;
    tagSets: TagSetResource[];
    workerPoolsById: ResourcesById<WorkerPoolResource>;
}

const loadData = async (project: ProjectResource, processType: ProcessType): Promise<ProcessListLayoutLoaderLookupData> => {
    const includedScriptModules = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" })
        ? repository.LibraryVariableSets.all({
              contentType: VariableSetContentType.ScriptModule,
          }).then(sm => sm.filter(x => project.IncludedLibraryVariableSetIds.includes(x.Id)))
        : Promise.resolve([]);
    const environmentsById = repository.Environments.allById();
    const lifecyclePreview = processType === ProcessType.Deployment && isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.get(project.LifecycleId).then(x => repository.Lifecycles.preview(x)) : Promise.resolve(null);
    const channelsById =
        processType === ProcessType.Deployment &&
        isAllowed({
            permission: Permission.ProcessView,
            project: project.Id,
            tenant: "*",
        })
            ? repository.Projects.getChannels(project).then(c => keyBy(c.Items, x => x.Id))
            : Promise.resolve(null);
    const tagSets = tenantTagsets.getAll();
    const workerPoolsById = repository.WorkerPools.allById();
    return {
        environmentsById: await environmentsById,
        includedScriptModules: await includedScriptModules,
        lifecyclePreview: await lifecyclePreview,
        channelsById: await channelsById,
        tagSets: await tagSets,
        workerPoolsById: await workerPoolsById,
    };
};

const PageLoader = ProcessContextFormPage<ProcessListLayoutLoaderLookupData>();
const ProcessListLayoutDataLoader: React.FC<ProcessPageProps> = props => {
    const {
        state: { model: project },
    } = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    return (
        <PageLoader
            processType={props.processType}
            title={props.processType === ProcessType.Deployment ? "Process" : runbookName}
            load={() => loadData(project, props.processType)}
            renderWhenLoaded={data => <EnhancedProcessListLayoutLoader initialLookups={data} project={project} {...props} />}
        />
    );
};

interface InitialLookupData {
    initialLookups: ProcessListLayoutLoaderLookupData;
}

class ProcessListLayoutLoader extends DataBaseComponent<Props & InitialLookupData, ProcessState> {
    constructor(props: Props & InitialLookupData) {
        super(props);
        this.state = {
            lookups: props.initialLookups,
        };
    }

    //TODO: @Cleanup: This is an artifact of the data-loading pattern we have here. Ideally this could potentially all be moved into context.
    supportedActions(): ProcessPageSupportedActions {
        const refreshLookupData = async () => {
            await this.doBusyTask(async () => this.setState({ lookups: await loadData(this.props.project, this.props.processType) }));
        };

        const saveOnServer = async (process: IProcessResource, setErrors: (errors: Errors) => void): Promise<IProcessResource | null> => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let processResult: IProcessResource | null = null;
            await this.doBusyTask(
                async () => {
                    processResult = await saveProcessType(process);
                },
                true,
                setErrors
            );
            return processResult;
        };

        return {
            refreshLookupData,
            saveOnServer,
        };
    }

    render() {
        return (
            <ProcessController layoutActions={this.supportedActions()} processType={this.props.processType} id={this.props.processId} doBusyTask={this.doBusyTask} projectId={this.props.project.Id}>
                {() => {
                    return (
                        <ProcessListLayout
                            processType={this.props.processType}
                            lookups={this.state.lookups}
                            errors={this.state.errors}
                            isActionContainersEnabled={this.props.isActionContainersEnabled}
                            busy={this.state.busy}
                            doBusyTask={this.doBusyTask}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            isBuiltInWorkerEnabled={this.props.isBuiltInWorkerEnabled ?? false}
                        />
                    );
                }}
            </ProcessController>
        );
    }
}

const saveProcessType = async (process: IProcessResource): Promise<IProcessResource> => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let result: IProcessResource = null!;
    if (isDeploymentProcessResource(process)) {
        result = await repository.DeploymentProcesses.modify(process);
    } else if (isRunbookProcessResource(process)) {
        result = await repository.RunbookProcess.modify(process);
    }
    return result;
};

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isBuiltInWorkerEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isBuiltInWorkerEnabled)(state),
        isActionContainersEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isActionContainersEnabled)(state),
    };
};

const EnhancedProcessListLayoutLoader = connect(mapGlobalStateToProps)(ProcessListLayoutLoader);

// We export our data-loader wrapper, as that wraps out layout and supplies the necessary data to the layout.
export default ProcessListLayoutDataLoader;
