/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { client, repository, session } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState, Refresh } from "components/DataBaseComponent/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import PaperLayout from "components/PaperLayout/PaperLayout";
import moment from "moment";
import { EnvironmentResource, OctopusServerNodeResource, ProjectResource, SpaceResource, TaskResource, TaskState, TenantResource, TaskTypeResource, RunbookResource, ProjectSummaryResource } from "client/resources";
import { SpaceMultiSelect } from "components/MultiSelect";
import { PagingDataTable } from "components/PagingDataTable/PagingDataTable";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import Select from "components/form/Select/Select";
import DateFormatter from "utils/DateFormatter";
import TaskDetails from "components/TaskDetails/TaskDetails";
import AdvancedFilterLayout, { AdvancedFilterCheckbox } from "components/AdvancedFilterLayout";
import NavigationButton, { NavigationButtonType } from "components/Button/NavigationButton";
import PermissionCheck, { hasPermission } from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { StatsResourceCollection } from "client/repositories/taskRepository";
import routeLinks from "routeLinks";
import { Feature, FeatureToggle } from "components/FeatureToggle";
import { IQuery, QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import Callout, { CalloutType } from "components/Callout";
import { orderBy } from "lodash";
import Logger from "client/logger";
import { Errors } from "components/DataBaseComponent/Errors";

const styles = require("./style.less");

export interface Filter {
    ids?: string[];
    state?: TaskFilterState;
    project?: string;
    runbook?: string;
    environment?: string;
    name?: string;
    node?: string;
    tenant?: string;
    spaces: string[];
    includeSystem: boolean;
    hasPendingInterruptions?: boolean | null;
    hasWarningsOrErrors?: boolean;
}

export interface TasksQuery extends IQuery {
    serverNode?: string;
    state?: string;
    ids?: string[];
    project?: string;
    runbook?: string;
    environment?: string;
    name?: string;
    tenant?: string;
    spaces?: string[];
    hasPendingInterruptions?: string;
    includeSystem?: string;
    hasWarningsOrErrors?: string;
}

interface TasksState extends DataBaseComponentState {
    tasks?: StatsResourceCollection;
    projects: ProjectSummaryResource[];
    runbooks: RunbookResource[];
    environments: EnvironmentResource[];
    nodes: OctopusServerNodeResource[];
    tenants: TenantResource[];
    spaces: SpaceResource[];
    taskTypes: TaskTypeResource[];
    currentPageIndex: number; // We manage our own paging due to automatic refresh / timers.
    filter: Filter;
    hasLoadedOnce?: boolean;
}

class TaskResourceDataTable extends PagingDataTable<TaskResource<any>> {}

class FilterLayout extends AdvancedFilterLayout<Filter> {}

export enum TaskFilterState {
    Incomplete = "Incomplete",
    Running = "Running",
    Completed = "Completed",
    Unsuccessful = "Unsuccessful",
    Queued = "Queued",
    Executing = "Executing",
    Cancelling = "Cancelling",
    Success = "Success",
    Canceled = "Canceled",
    TimedOut = "TimedOut",
    Failed = "Failed",
}

function getTaskStatesFromFilterState(taskFilterState: TaskFilterState) {
    switch (taskFilterState) {
        case TaskFilterState.Incomplete:
            return [TaskState.Queued, TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterState.Running:
            return [TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterState.Completed:
            return [TaskState.Canceled, TaskState.Success, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterState.Unsuccessful:
            return [TaskState.Canceled, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterState.Queued:
            return TaskState.Queued;
        case TaskFilterState.Executing:
            return TaskState.Executing;
        case TaskFilterState.Cancelling:
            return TaskState.Cancelling;
        case TaskFilterState.Success:
            return TaskState.Success;
        case TaskFilterState.Canceled:
            return TaskState.Canceled;
        case TaskFilterState.TimedOut:
            return TaskState.TimedOut;
        case TaskFilterState.Failed:
            return TaskState.Failed;
    }
}

function getTaskFilter(query: TasksQuery): Filter {
    return {
        node: query.serverNode,
        state: TaskFilterState[query.state as keyof typeof TaskFilterState],
        ids: arrayValueFromQueryString(query.ids),
        hasPendingInterruptions: query.hasPendingInterruptions === "true",
        hasWarningsOrErrors: query.hasWarningsOrErrors === "true",
        environment: query.environment,
        project: query.project,
        runbook: query.runbook,
        tenant: query.tenant,
        spaces: arrayValueFromQueryString(query.spaces),
        name: query.name,
        includeSystem: query.includeSystem === "true",
    };
}

export function getTaskQuery(filter: Filter): TasksQuery {
    return {
        serverNode: filter.node,
        state: filter.state,
        ids: filter.ids,
        environment: filter.environment,
        hasPendingInterruptions: filter.hasPendingInterruptions ? "true" : undefined,
        hasWarningsOrErrors: filter.hasWarningsOrErrors ? "true" : undefined,
        name: filter.name,
        project: filter.project,
        runbook: filter.runbook,
        tenant: filter.tenant,
        ...(filter.spaces.length !== 0 ? { spaces: filter.spaces } : {}),
        ...(filter.includeSystem ? { includeSystem: "true" } : {}),
    };
}

const TasksQueryStringFilters = QueryStringFilters.For<Filter, TasksQuery>();

export interface TasksLayoutRenderProps {
    busy: Promise<void>;
    errors: Errors;
    children: React.ReactNode;
}

export interface TaskCellRenderProps {
    task: TaskResource<any>;
}

interface TasksProps extends RouteComponentProps<any> {
    restrictToProjectId?: string;
    restrictToRunbookId?: string;
    restrictToTenantId?: string;
    hideScriptConsoleAction?: boolean;
    renderLayout?: (props: TasksLayoutRenderProps) => React.ReactElement<any>;
    renderCell?: (props: TaskCellRenderProps) => React.ReactElement<any>;
    onNewItems?(items: any[]): Promise<any[]>;
}

export class Tasks extends DataBaseComponent<TasksProps, TasksState> {
    private isRestrictedView = false;

    constructor(props: TasksProps) {
        super(props);
        this.state = {
            currentPageIndex: 0,
            filter: createEmptyTaskFilter(),
            environments: [],
            nodes: [],
            projects: [],
            runbooks: [],
            tenants: [],
            taskTypes: [],
            spaces: [],
        };
        this.isRestrictedView = this.isRestrictedDocumentView();
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            let restrictedToProject: ProjectResource | null = null;
            if (this.props.restrictToProjectId && hasPermission(Permission.ProjectView)) {
                restrictedToProject = await repository.Projects.get(this.props.restrictToProjectId);
            }

            let restrictedToRunbook: RunbookResource | null = null;
            if (this.props.restrictToRunbookId && hasPermission(Permission.RunbookView)) {
                restrictedToRunbook = await repository.Runbooks.get(this.props.restrictToRunbookId);
            }

            let getProjects = Promise.resolve([] as ProjectSummaryResource[]);
            if (hasPermission(Permission.ProjectView)) {
                if (this.props.restrictToProjectId) {
                    getProjects = Promise.resolve([restrictedToProject!]);
                } else {
                    getProjects = repository.Projects.summaries();
                }
            }

            let getRunbooks = Promise.resolve([] as RunbookResource[]);
            if (hasPermission(Permission.RunbookView)) {
                if (this.props.restrictToRunbookId) {
                    getRunbooks = Promise.resolve([restrictedToRunbook!]);
                } else {
                    if (!!restrictedToProject) {
                        const projectRunbooks = await repository.Projects.getRunbooks(restrictedToProject, { take: repository.takeAll });
                        getRunbooks = Promise.resolve(projectRunbooks.Items);
                    } else {
                        getRunbooks = repository.Runbooks.all();
                    }
                }
            }

            const getEnvironments = hasPermission(Permission.EnvironmentView) ? repository.Environments.all() : Promise.resolve([] as EnvironmentResource[]);
            const getNodes = repository.OctopusServerNodes.all();
            const getTenants = !this.props.restrictToTenantId ? repository.Tenants.all() : Promise.resolve([]);
            const getTaskTypes = repository.Tasks.taskTypes();
            const spaces = repository.Users.getSpaces(session.currentUser!);

            const runbooks = await getRunbooks;
            const sortedRunbooks = orderBy(runbooks, [x => x.ProjectId], "asc");

            this.setState({
                projects: await getProjects,
                runbooks: sortedRunbooks,
                environments: await getEnvironments,
                nodes: await getNodes,
                tenants: await getTenants,
                spaces: await spaces,
                taskTypes: await getTaskTypes,
            });
            this.doRefresh = await this.startRefreshLoop(() => this.searchInternal(this.state.filter, this.state.currentPageIndex), 5000);
        });
    }

    isRestrictedDocumentView(): boolean {
        return !!this.props.restrictToProjectId || !!this.props.restrictToRunbookId || !!this.props.restrictToTenantId;
    }

    search(filter: Filter) {
        this.setState({ filter, hasLoadedOnce: false, currentPageIndex: 0 }, async () => this.doRefresh());
    }

    async searchInternal<K extends keyof Filter>(filter: Filter, currentPageIndex: number) {
        this.setState({ currentPageIndex });

        const searchFilter = {
            states: getTaskStatesFromFilterState(filter.state!),
            project: this.props.restrictToProjectId ? this.props.restrictToProjectId! : filter.project!,
            runbook: this.props.restrictToRunbookId ? this.props.restrictToRunbookId! : filter.runbook!,
            environment: filter.environment,
            name: filter.name,
            node: filter.node,
            tenant: this.props.restrictToTenantId ? this.props.restrictToTenantId : filter.tenant,
            spaces: getSpacesFilter(),
            skip: this.state.tasks ? currentPageIndex * this.state.tasks.ItemsPerPage : 0,
            ids: filter.ids && filter.ids.length ? filter.ids.join(",") : undefined!,
            hasPendingInterruptions: filter.hasPendingInterruptions ? true : undefined!,
            includeSystem: filter.includeSystem,
            hasWarningsOrErrors: filter.hasWarningsOrErrors ? true : undefined!,
        };

        const tasks = await repository.Tasks.list(searchFilter as any);

        return {
            tasks,
            hasLoadedOnce: true,
        };

        function getSpacesFilter() {
            const hasTaskViewInAnySpace = session!.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView);

            if (filter.spaces.length === 0) {
                if (hasTaskViewInAnySpace) {
                    return ["all"];
                } else {
                    return [];
                }
            }

            return filter.spaces;
        }
    }

    clear() {
        return this.search(createEmptyTaskFilter());
    }

    restrictedViewMode() {
        return !!this.props.restrictToProjectId || !!this.props.restrictToRunbookId || !!this.props.restrictToTenantId;
    }

    render() {
        const list = this.state.tasks && (
            <TaskResourceDataTable
                initialData={this.state.tasks}
                onRow={(item: any) => this.buildRow(item)}
                onRowRedirectUrl={(task: TaskResource<any>) => routeLinks.task(task.Id).root}
                onNewItems={this.props.onNewItems}
                rowColumnClassName={styles.taskDetailsCell}
                headerColumns={["", "State", "Start Time", "Completed Time", "Duration"]}
                onEmpty={this.handleOnEmpty}
                showPagingInNumberedStyle={true}
                currentPageIndex={this.state.currentPageIndex}
                onPageSelected={async (skip: number, p: number) => {
                    this.setState({ hasLoadedOnce: false, currentPageIndex: p }, async () => this.doRefresh());
                }}
                rowClassName={styles.taskRow}
            />
        );

        const filterSections = [
            {
                render: (
                    <div>
                        <div className={styles.checkboxFiltersContainer}>
                            <AdvancedFilterCheckbox label="Awaiting manual intervention" value={this.state.filter.hasPendingInterruptions!} onChange={hasPendingInterruptions => this.search({ ...this.state.filter, hasPendingInterruptions })} />
                            {this.renderIncludeSystem()}
                            <AdvancedFilterCheckbox label="Has warnings or errors" value={this.state.filter.hasWarningsOrErrors!} onChange={hasWarningsOrErrors => this.search({ ...this.state.filter, hasWarningsOrErrors })} />
                        </div>
                        {this.renderSpaceSelector()}
                        <Select
                            value={this.state.filter.name}
                            allowFilter={true}
                            onChange={(name: string) => this.search({ ...this.state.filter, name })}
                            items={this.state.taskTypes.map(t => ({ value: t.Id, text: t.Name }))}
                            allowClear={true}
                            fieldName="task type"
                            hintText="All task types"
                            label="By task type"
                        />
                        {!this.restrictedViewMode() && (
                            <Select
                                value={this.state.filter.node}
                                onChange={node => this.search({ ...this.state.filter, node })}
                                items={this.state.nodes.map(n => ({ value: n.Id, text: n.Name }))}
                                allowClear={true}
                                label="By node"
                                hintText="All nodes"
                            />
                        )}
                        {this.renderSpaceSpecificSelectors()}
                    </div>
                ),
            },
        ];

        const stateFilter = (
            <div className={styles.states}>
                <Select
                    value={this.state.filter.state}
                    onChange={(state: TaskFilterState) => this.search({ ...this.state.filter, state })}
                    items={Object.keys(TaskFilterState).map(t => ({ value: t, text: t }))}
                    allowClear={true}
                    fieldName="task state"
                    hintText="All task states"
                />
            </div>
        );

        return (
            <>
                <TasksQueryStringFilters filter={this.state.filter} onFilterChange={filter => this.search(filter)} getFilter={getTaskFilter} getQuery={getTaskQuery} />
                {this.renderWithLayout(
                    <>
                        {!this.isRestrictedView && this.state.tasks && this.state.tasks.TotalCounts && (
                            <StatisticsPanel
                                totals={this.state.tasks.TotalCounts}
                                setFilter={partialStatisticsFilter => {
                                    const filterWithPreservedSpacePartitions = {
                                        spaces: this.state.filter.spaces,
                                        includeSystem: this.state.filter.includeSystem,
                                    };
                                    this.search({ ...filterWithPreservedSpacePartitions, ...partialStatisticsFilter });
                                }}
                            />
                        )}
                        <FilterLayout
                            filter={this.state.filter}
                            defaultFilter={createEmptyTaskFilter()}
                            additionalHeaderFilters={[stateFilter]}
                            onFilterReset={resetFilter => {
                                this.search(resetFilter);
                            }}
                            filterSections={filterSections}
                            renderContent={() => list}
                        />
                    </>
                )}
            </>
        );
    }

    private renderWithLayout(children: React.ReactNode) {
        if (this.props.renderLayout) {
            return this.props.renderLayout({ busy: this.state.busy!, errors: this.state.errors!, children });
        }
        return (
            <PaperLayout
                title="Tasks"
                busy={this.state.busy}
                enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce}
                errors={this.state.errors}
                sectionControl={
                    !this.props.hideScriptConsoleAction && (
                        <PermissionCheck permission={[Permission.AdministerSystem, Permission.TaskCreate]} wildcard={true}>
                            <NavigationButton label="Script Console" href={routeLinks.tasks.console} type={NavigationButtonType.Primary} />
                        </PermissionCheck>
                    )
                }
                fullWidth={true}
            >
                {children}
            </PaperLayout>
        );
    }

    private renderSpaceSpecificSelectors = () => {
        // These are linked to access in the current space, because that's where the data will come from
        // we need to revisit how these will work going forward to make the filtering easier to do cross-space
        const isWithinASpace = client.spaceId;

        return (
            isWithinASpace && (
                <>
                    {!this.props.restrictToProjectId && (
                        <PermissionCheck permission={Permission.ProjectView} wildcard={true}>
                            <Select
                                value={this.state.filter.project}
                                onChange={project => this.search({ ...this.state.filter, project })}
                                items={this.state.projects.map(p => ({ value: p.Id, text: p.Name }))}
                                allowClear={true}
                                allowFilter={true}
                                fieldName="project"
                                hintText="All projects"
                            />
                        </PermissionCheck>
                    )}
                    {!this.props.restrictToRunbookId && (
                        <PermissionCheck permission={Permission.RunbookView} wildcard={true}>
                            <Select
                                value={this.state.filter.runbook}
                                onChange={runbook => this.search({ ...this.state.filter, runbook })}
                                items={this.state.runbooks.map(r => {
                                    const defaultSelectItem = {
                                        value: r.Id,
                                        text: r.Name,
                                    };
                                    if (!!this.props.restrictToProjectId || !!this.props.restrictToRunbookId) {
                                        return defaultSelectItem;
                                    }

                                    const runbookProject = this.state.projects.find(p => p.Id === r.ProjectId);
                                    if (!runbookProject) {
                                        Logger.error(`Failed to find project for runbook. This should not happen.`);
                                        return defaultSelectItem;
                                    }
                                    return {
                                        value: r.Id,
                                        text: `${runbookProject.Name} - ${r.Name}`,
                                    };
                                })}
                                allowClear={true}
                                allowFilter={true}
                                fieldName="runbook"
                                hintText="All runbooks"
                            />
                        </PermissionCheck>
                    )}
                    <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                        <Select
                            value={this.state.filter.environment}
                            onChange={environment => this.search({ ...this.state.filter, environment })}
                            items={this.state.environments.map(e => ({ value: e.Id, text: e.Name }))}
                            allowClear={true}
                            allowFilter={true}
                            fieldName="environment"
                            hintText="All environments"
                        />
                    </PermissionCheck>
                    {!this.props.restrictToTenantId && (
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <Select
                                    value={this.state.filter.tenant}
                                    onChange={tenant => this.search({ ...this.state.filter, tenant })}
                                    items={this.state.tenants.map(t => ({ value: t.Id, text: t.Name }))}
                                    allowClear={true}
                                    allowFilter={true}
                                    fieldName="tenant"
                                    hintText="All tenants"
                                    label="By tenant"
                                />
                            </PermissionCheck>
                        </FeatureToggle>
                    )}
                </>
            )
        );
    };

    private renderIncludeSystem = () => {
        if (this.restrictedViewMode()) {
            return null;
        }

        const hasSystemTaskView = session.currentPermissions!.scopeToSystem().hasPermissionInAnyScope(Permission.TaskView);
        if (hasSystemTaskView) {
            return <AdvancedFilterCheckbox label="Include system tasks" value={this.state.filter.includeSystem} onChange={includeSystem => this.search({ ...this.state.filter, includeSystem })} />;
        }
        return null;
    };

    private renderSpaceSelector = () => {
        if (this.restrictedViewMode()) {
            return null;
        }

        const hasTaskViewInAnySpace = session.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView);
        if (!hasTaskViewInAnySpace) {
            return (
                <div style={{ margin: "1rem 0 0 0" }}>
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        You do not have {Permission.TaskView} permission in any given Space.
                    </Callout>
                </div>
            );
        }
        return (
            <SpaceMultiSelect
                items={this.state.spaces}
                label={"By space"}
                hintText={this.state.filter.spaces.length ? undefined : "All spaces"}
                onChange={(spaces: string[]) => this.search({ ...this.state.filter, spaces })}
                value={this.state.filter.spaces}
            />
        );
    };

    private doRefresh: Refresh = () => Promise.resolve();

    private handleOnEmpty = () => {
        return <div>No tasks found</div>;
    };

    private buildRow = (task: TaskResource<any>) => {
        return [
            this.renderCell(task),
            task.State,
            task.State === TaskState.Queued ? DateFormatter.dateToLongFormat(task.QueueTime) + " (" + moment(task.QueueTime).fromNow() + ")" : DateFormatter.dateToLongFormat(task.StartTime),
            DateFormatter.dateToLongFormat(task.CompletedTime),
            task.State === TaskState.Queued ? null : task.Duration,
        ];
    };

    private renderCell(task: TaskResource<any>) {
        if (this.props.renderCell) {
            return this.props.renderCell({ task });
        }
        return (
            // mark.siedle: We want this InternalLink here so users have the option of standard
            // anchor-behaviour (ie. right click) that you don't get with the onClick from our SimpleDataTable component.
            <InternalLink to={(!!task.SpaceId ? routeLinks.forSpace(task.SpaceId) : routeLinks).task(task).root}>
                <TaskDetails task={task} stripTopBottomPadding={true} />
            </InternalLink>
        );
    }
}

interface StatisticsPanelProps {
    totals: { [state: string]: number };
    setFilter(filter: Partial<Filter>): void;
}

const StatisticsPanel: React.StatelessComponent<StatisticsPanelProps> = props => {
    const addSIfNeeded = (length: number) => {
        return length > 1 ? "s" : "";
    };

    const stat = () => {
        function setFilter(e: React.MouseEvent<HTMLAnchorElement>, filter: Partial<Filter>) {
            e.preventDefault();
            props.setFilter(filter);
        }
        const result: JSX.Element[] = [];

        if (props.totals) {
            if (props.totals.Interrupted > 0) {
                result.push(
                    <span key="interrupted">
                        {props.totals.Interrupted} task{addSIfNeeded(props.totals.Interrupted)}{" "}
                        <a href="#" onClick={e => setFilter(e, { hasPendingInterruptions: true })}>
                            awaiting intervention
                        </a>
                    </span>
                );
            }
            if (props.totals.Executing > 0) {
                result.push(
                    <span key="executing">
                        {props.totals.Executing} task{addSIfNeeded(props.totals.Executing)}{" "}
                        <a href="#" onClick={e => setFilter(e, { state: TaskFilterState.Executing })}>
                            running
                        </a>
                    </span>
                );
            }
            if (props.totals.Cancelling > 0) {
                result.push(
                    <span key="cancelling">
                        {props.totals.Cancelling} task{addSIfNeeded(props.totals.Cancelling)}{" "}
                        <a href="#" onClick={e => setFilter(e, { state: TaskFilterState.Cancelling })}>
                            cancelling
                        </a>
                    </span>
                );
            }
            if (props.totals.Queued > 0) {
                result.push(
                    <span key="queued">
                        {props.totals.Queued} task{addSIfNeeded(props.totals.Queued)}{" "}
                        <a href="#" onClick={e => setFilter(e, { state: TaskFilterState.Queued })}>
                            waiting in queue
                        </a>
                    </span>
                );
            }
        }

        if (result.length === 0) {
            return "No active tasks";
        }

        if (result.length === 1) {
            return result;
        }

        const delimited = result.map((v, i) => {
            if (i === result.length - 1) {
                return v;
            }
            if (i === result.length - 2) {
                return [v, <span> and </span>];
            }
            return [v, <span>, </span>];
        });
        return delimited;
    };

    return <div className={styles.stats}>{stat()}</div>;
};

function createEmptyTaskFilter(): Filter {
    const hasTaskViewInCurrentSpace = session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.TaskView);
    const shouldFilterToCurrentSpace = client.spaceId && hasTaskViewInCurrentSpace;
    return getTaskFilter({
        spaces: shouldFilterToCurrentSpace ? [client.spaceId!] : [],
        includeSystem: shouldFilterToCurrentSpace ? "" : "true",
    });
}

export default Tasks;
