import * as React from "react";
import ToolTip from "components/ToolTip/index";
import Chip from "../Chips/Chip";
import { tagClass } from "uiTestClasses";
import { LocationDescriptor } from "history";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { useRequiredContext } from "hooks";
import { OctopusThemeContext } from "components/Theme";
const styles = require("./styles.less");

interface TagProps {
    description: string;
    tagName: string;
    tagColor: string;
    borderColor?: string;
    small?: boolean;
    showTooltip?: boolean;
    tabIndex?: number;
    to?: LocationDescriptor;
    onClick?: () => void;
    onRequestDelete?: (event: object) => void;
}

const Tag: React.StatelessComponent<TagProps> = props => {
    const themeContext = useRequiredContext(OctopusThemeContext, "OctopusThemeContext");

    const { tagName, tagColor, tabIndex, description, small = false } = props;
    const tagDescription = description ? tagName + ": " + description : "Tag: " + tagName;

    if (!small) {
        const theTag = (
            <Chip
                className={tagClass}
                tabIndex={tabIndex}
                onClick={props.onClick}
                onRequestDelete={props.onRequestDelete}
                backgroundColor={tagColor}
                bordercolor={props.borderColor}
                description={tagDescription}
                labelColor={themeContext.whiteConstant}
            >
                {tagName}
            </Chip>
        );
        if (props.to) {
            return (
                <InternalLink to={props.to} className={styles.clickableTag}>
                    {theTag}
                </InternalLink>
            );
        }
        return theTag;
    }
    return (
        <ToolTip content={tagName}>
            <em className={`${"fa fa-square tag-swatch"} ${tagClass}`} style={{ color: tagColor, marginLeft: "0.0625rem", marginRight: "0.0625rem" }} />
        </ToolTip>
    );
};

export default Tag;
