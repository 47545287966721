/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { createErrorsFromOctopusError } from "components/DataBaseComponent/Errors";
import ActionPreview from "./ActionPreview";
import MachineMultiSelect from "components/MultiSelect/MachineMultiSelect";
import RadioButton from "components/form/RadioButton/RadioButton";
import { DeploymentMachineInfo, DeploymentTargetType, DeploymentType } from "../Preview";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import { ActionToggleInfo, MachineDeploymentPreview } from "../deploymentStepsWorker";
import { DeploymentResource, ReleaseChanges, IExecutionResource } from "client/resources/deploymentResource";
import * as _ from "lodash";
import { EnvironmentResource } from "client/resources/environmentResource";
import { EnvironmentChip, TenantChip } from "components/Chips/index";
import { TenantResource } from "client/resources/tenantResource";
import { DataTableBody } from "components/DataTable/DataTableBody";
import { DataTableRow } from "components/DataTable/DataTableRow";
import { DataTableRowColumn } from "components/DataTable/DataTableRowColumn";
import cn from "classnames";
import MachineIconHelper from "utils/MachineIconHelper/MachineIconHelper";
import { MachineModelHealthStatus } from "client/resources/machineResource";
import { OctopusError } from "client/resources/octopusError";
import WarningIcon from "components/WarningIcon/WarningIcon";
import ToolTip from "components/ToolTip/index";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { Callout, CalloutType } from "components/Callout/Callout";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import routeLinks from "routeLinks";
import { DeploymentRequestModel } from "../deploymentRequestModel";
import Form from "client/resources/form";
import CardExpandable from "components/form/Sections/CardExpandable";
import { IProcessResource } from "client/resources/deploymentProcessResource";
import ReleaseChangesDetail from "../../ReleaseChanges/ReleaseChangesDetail";
import { DeploymentModelType } from "areas/projects/components/Runbooks/RunbookRunNowLayout";
const styles = require("./style.less");

interface DeploymentResultItemProps {
    deployment: DeploymentRequestModel;
    stepActionIdsToSkip: string[];
    environment: EnvironmentResource;
    isMissingVariable: boolean;
    tenant?: TenantResource;
    promptVariableForm: Form;
    stepsForSelectedDeployment: ActionToggleInfo[];
    actions: ActionToggleInfo[];
    process: IProcessResource;
    releaseChanges: ReleaseChanges[];
    changesMarkdown: string;
    modelType: DeploymentModelType;
    isExpandedByDefault?: boolean;
    onIncludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onExcludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onAllTargetsSelected(deployment: DeploymentMachineInfo): void;
}

interface DeploymentResultItemState extends DataBaseComponentState {
    deploymentTargetType: DeploymentTargetType;
    expanded: boolean;
    machinesApplicableToThisDeployment: MachineDeploymentPreview[];
    numberOfSteps: number;
}

export default class DeploymentResultItem extends DataBaseComponent<DeploymentResultItemProps, DeploymentResultItemState> {
    private machineIconHelper = new MachineIconHelper();

    constructor(props: DeploymentResultItemProps) {
        super(props);
        this.state = {
            deploymentTargetType: this.determineTargetType(props.deployment),
            expanded: this.props.isExpandedByDefault!,
            machinesApplicableToThisDeployment: [],
            numberOfSteps: 0,
        };
    }

    determineTargetType = (deployment: DeploymentRequestModel) => {
        if (deployment && deployment.request.SpecificMachineIds.length > 0) {
            return DeploymentTargetType.IncludeSpecific;
        }
        if (deployment && deployment.request.ExcludedMachineIds.length > 0) {
            return DeploymentTargetType.ExcludeSpecific;
        }
        return DeploymentTargetType.AllApplicable;
    };

    async componentDidMount() {
        await this.loadData(this.props);
    }

    async componentWillReceiveProps(nextProps: DeploymentResultItemProps) {
        if (
            _.isEqual(nextProps.deployment, this.props.deployment) &&
            _.isEqual(nextProps.stepActionIdsToSkip, this.props.stepActionIdsToSkip) &&
            _.isEqual(nextProps.deployment, this.props.deployment) &&
            _.isEqual(nextProps.stepActionIdsToSkip, this.props.stepActionIdsToSkip) &&
            _.isEqual(nextProps.stepsForSelectedDeployment, this.props.stepsForSelectedDeployment)
        ) {
            return;
        }
        await this.loadData(nextProps);
    }

    render() {
        return this.buildDeploymentSummary();
    }

    private async loadData(props: DeploymentResultItemProps) {
        if (props.stepsForSelectedDeployment) {
            const allMachines = _.uniqBy(
                _.flatMap(props.stepsForSelectedDeployment, s => s.details.Machines),
                m => m.Id
            );

            const numberOfSteps = props.stepsForSelectedDeployment.length - props.stepActionIdsToSkip.length - props.stepsForSelectedDeployment.filter(s => s.details.IsDisabled).length;
            this.setState({ machinesApplicableToThisDeployment: allMachines, numberOfSteps });
        }
    }

    private makeDeploymentMachinesInfo(machineIds: string[], deploymentType: any) {
        return {
            id: this.props.deployment.tenantId ? this.props.deployment.tenantId : this.props.deployment.environmentId,
            machineIds,
            deploymentType,
        };
    }

    private getDeploymentType() {
        let deploymentType = DeploymentType.Environment;
        if (this.props.deployment.tenantId) {
            deploymentType = DeploymentType.Tenant;
        }
        return deploymentType;
    }

    private buildDeploymentSummary() {
        const deployment = this.props.deployment;
        const env = this.props.environment;
        const deploymentType = this.getDeploymentType();
        const variableInfo = this.getDeploymentMissingVariableInfo(deploymentType);

        return (
            <DataTableBody className={styles.deploymentResultItem}>
                <DataTableRow
                    className={styles.deploymentsTableRow}
                    onClick={() =>
                        this.setState(prev => {
                            return { expanded: !prev.expanded };
                        })
                    }
                >
                    <DataTableRowColumn>
                        <div className={styles.targetColumn}>
                            {this.props.deployment && this.successOrErrorIcon()}
                            {env && <div className={styles.environmentOrTenantIcon}>{this.props.tenant ? <TenantChip tenantName={this.props.tenant.Name} /> : <EnvironmentChip environmentName={env.Name} />}</div>}
                        </div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>{deployment.currentVersion}</div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>
                            {this.state.numberOfSteps} {this.state.numberOfSteps === 1 ? "step" : "steps"}
                        </div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>{this.getIncludeExcludeTargetsInfo()}</div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>
                            {this.getTargetsInfo()}
                            {variableInfo}
                        </div>
                    </DataTableRowColumn>
                    <DataTableRowColumn fullWidth={true}>
                        <div className={styles.expandCollapse}>
                            <CardExpandable expanded={this.state.expanded} />
                        </div>
                    </DataTableRowColumn>
                </DataTableRow>
                {this.state.expanded && this.buildDetailsChildRow(deploymentType)}
            </DataTableBody>
        );
    }

    private getIncludeExcludeTargetsInfo() {
        let includeExcludeMachinesInfo = "All included";
        if (this.props.deployment.request.SpecificMachineIds.length > 0) {
            includeExcludeMachinesInfo = `${this.props.deployment.request.SpecificMachineIds.length} included`;
        }
        if (this.props.deployment.request.ExcludedMachineIds.length > 0) {
            includeExcludeMachinesInfo = `${this.props.deployment.request.ExcludedMachineIds.length} excluded`;
        }
        return includeExcludeMachinesInfo;
    }

    private buildDetailsChildRow(deploymentType: DeploymentType) {
        const deployment = this.props.deployment;
        return (
            <DataTableRow>
                <DataTableRowColumn colSpan={6}>
                    {this.state.expanded && (
                        <div>
                            {deployment && (
                                <div>
                                    <div className={styles.section}>
                                        {this.successOrErrorDetails(this.props.deployment)}
                                        <ExpandableFormSection
                                            isExpandedByDefault={this.props.isExpandedByDefault}
                                            title="Deployment Targets"
                                            help="Include/Exclude specific deployment targets."
                                            summary={Summary.summary(<div>{this.getIncludeExcludeTargetsInfo()}</div>)}
                                            errorKey="deploymentTargets"
                                        >
                                            <RadioButtonGroup
                                                value={this.state.deploymentTargetType}
                                                onChange={(val: DeploymentTargetType) => {
                                                    this.setState({ deploymentTargetType: val });
                                                    if (val === DeploymentTargetType.AllApplicable) {
                                                        const deploymentInfo: DeploymentMachineInfo = this.makeDeploymentMachinesInfo([], deploymentType);
                                                        this.props.onAllTargetsSelected(deploymentInfo);
                                                    }
                                                }}
                                            >
                                                <RadioButton value={DeploymentTargetType.AllApplicable} label="Include all applicable deployment targets" isDefault={true} />
                                                <RadioButton value={DeploymentTargetType.IncludeSpecific} label="Include specific deployment targets" />
                                                <div>
                                                    {this.state.deploymentTargetType === DeploymentTargetType.IncludeSpecific && (
                                                        <div>
                                                            <MachineMultiSelect
                                                                value={this.props.deployment.request.SpecificMachineIds}
                                                                items={this.state.machinesApplicableToThisDeployment}
                                                                onChange={machineIds => {
                                                                    const deploymentInfo = this.makeDeploymentMachinesInfo(machineIds, deploymentType);
                                                                    this.props.onIncludeSpecificMachinesSelected(deploymentInfo);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <RadioButton value={DeploymentTargetType.ExcludeSpecific} label="Exclude specific deployment targets" />
                                                <div>
                                                    {this.state.deploymentTargetType === DeploymentTargetType.ExcludeSpecific && (
                                                        <div>
                                                            <MachineMultiSelect
                                                                value={this.props.deployment.request.ExcludedMachineIds}
                                                                items={this.state.machinesApplicableToThisDeployment}
                                                                onChange={machineIds => {
                                                                    const deploymentInfo = this.makeDeploymentMachinesInfo(machineIds, deploymentType);
                                                                    this.props.onExcludeSpecificMachinesSelected(deploymentInfo);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </RadioButtonGroup>
                                        </ExpandableFormSection>
                                        {this.props.modelType === DeploymentModelType.Deployment &&
                                            this.props.changesMarkdown && [
                                                <ExpandableFormSection title="Changes" summary={this.getReleaseChangesSummary()} help={this.getReleaseChangesText()} errorKey="releaseNotes">
                                                    <ReleaseChangesDetail changesMarkdown={this.props.changesMarkdown} />
                                                </ExpandableFormSection>,
                                            ]}
                                    </div>
                                    <ActionPreview deploymentInfo={this.props.deployment ? this.props.deployment.request : null!} stepActionIdsToSkip={this.props.stepActionIdsToSkip} actions={this.props.actions} process={this.props.process} />
                                </div>
                            )}
                        </div>
                    )}
                </DataTableRowColumn>
            </DataTableRow>
        );
    }

    private getReleaseChangesSummary() {
        return Summary.summary(<div>{this.getReleaseChangesText()}</div>);
    }

    private getReleaseChangesText() {
        const releaseCount = this.props.releaseChanges.length;
        const workItemCount = this.props.releaseChanges.map(c => c.WorkItems.length).reduce((total, count) => total + count, 0);
        return releaseCount + " release(s), containing " + workItemCount + " work item(s)";
    }

    private getTargetsInfo() {
        if (this.state.machinesApplicableToThisDeployment) {
            let healthyTargets = 0;
            let unavailableTargets = 0;
            let unhealthyTargets = 0;

            this.state.machinesApplicableToThisDeployment.forEach(m => {
                if (m.isUnavailable || m.HealthStatus === MachineModelHealthStatus.Unavailable) {
                    unavailableTargets++;
                }
                if (m.HealthStatus === MachineModelHealthStatus.Unhealthy) {
                    unhealthyTargets++;
                }
                if (m.HealthStatus === MachineModelHealthStatus.Healthy || m.HealthStatus === MachineModelHealthStatus.HasWarnings) {
                    healthyTargets++;
                }
            });

            const targetSummary: any = [];

            if (healthyTargets > 0) {
                const healthyIcon = this.machineIconHelper.healthStatusIcons[MachineModelHealthStatus.Healthy];
                targetSummary.push(
                    <div className={styles.summaryCount}>
                        {healthyIcon && <img src={healthyIcon} className={styles.healthStatusIcon} alt={"healthy"} />}
                        {healthyTargets + " HEALTHY"}
                    </div>
                );
            }

            if (unavailableTargets > 0) {
                const unavailableIcon = this.machineIconHelper.healthStatusIcons[MachineModelHealthStatus.Unavailable];
                targetSummary.push(
                    <div className={styles.summaryCount}>
                        {unavailableIcon && <img src={unavailableIcon} className={styles.healthStatusIcon} alt={"Unavailable"} />}
                        {unavailableTargets + " UNAVAILABLE"}
                    </div>
                );
            }

            if (unhealthyTargets > 0) {
                const unhealthyIcon = this.machineIconHelper.healthStatusIcons[MachineModelHealthStatus.Unhealthy];
                targetSummary.push(
                    <div className={styles.summaryCount}>
                        {unhealthyIcon && <img src={unhealthyIcon} className={styles.healthStatusIcon} alt={"Unhealthy"} />}
                        {unhealthyTargets + " UNHEALTHY"}
                    </div>
                );
            }

            return <div className={styles.summaryCountContainer}>{targetSummary}</div>;
        }
    }

    private getDeploymentMissingVariableInfo(deploymentType: DeploymentType) {
        if (deploymentType === DeploymentType.Tenant) {
            if (this.props.isMissingVariable && this.props.deployment) {
                return (
                    <div>
                        <WarningIcon />
                        Tenant missing variables
                    </div>
                );
            }
        }
        return null;
    }

    private successOrErrorIcon() {
        let deploymentResultMessage = "";
        const response = this.props.deployment.response;
        if (response) {
            if (response instanceof OctopusError) {
                // a class of `OctopusError` e.g. 403 errors don't have the 'Errors' array
                if (response.Errors!.length === 0) {
                    deploymentResultMessage += response.ErrorMessage;
                } else {
                    response.Errors!.forEach(err => (deploymentResultMessage += err));
                }

                return (
                    <div className={styles.iconContainer}>
                        <ToolTip content={deploymentResultMessage}>
                            <span className={cn(styles.icon, styles.error)}>
                                <em className="fa fa-exclamation-triangle" />
                            </span>
                        </ToolTip>
                    </div>
                );
            }
            return (
                <div className={styles.iconContainer}>
                    <ToolTip content={this.props.modelType === DeploymentModelType.Deployment ? "Deployment created" : "Runbook Run created"}>
                        <span className={cn(styles.icon, styles.success)}>
                            <em className="fa fa-check" />
                        </span>
                    </ToolTip>
                </div>
            );
        }
    }

    private successOrErrorDetails(result: DeploymentRequestModel) {
        if (!result || !result.response) {
            return null;
        }

        if (this.isError(result.response)) {
            const convertedError = createErrorsFromOctopusError(result.response);
            return (
                <ErrorPanel
                    message={convertedError.message}
                    errors={convertedError.errors}
                    parsedHelpLinks={convertedError.parsedHelpLinks}
                    helpLink={convertedError.helpLink}
                    helpText={convertedError.helpText}
                    statusCode={convertedError.statusCode}
                />
            );
        }

        const deployment = result.response as DeploymentResource;

        return (
            <Callout type={CalloutType.Success} title={this.props.modelType === DeploymentModelType.Deployment ? `Deployment created` : `Runbook Run created`}>
                <InternalLink to={routeLinks.task(deployment).root} openInSelf={false}>
                    View task
                </InternalLink>
            </Callout>
        );
    }

    private isError(response: IExecutionResource | OctopusError): response is OctopusError {
        return (response as OctopusError).ErrorMessage !== undefined;
    }
}
