/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import CommunityActionTemplateList from "components/ActionTemplates/CommunityActionTemplateList";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import Section from "components/Section";

interface CommunityTemplatesState extends DataBaseComponentState {
    templates: ActionTemplateSearchResource[];
    isLoaded: boolean;
    filterText?: string;
    projectIdToComeBackTo?: string;
}

class CommunityTemplates extends DataBaseComponent<{}, CommunityTemplatesState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            templates: null!,
            isLoaded: false,
            filterText: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const templates = await repository.ActionTemplates.search();
            this.setState({
                templates: templates.filter(t => !t.IsInstalled),
                isLoaded: true,
            });
        });
    }

    render() {
        const stepTemplatesLink = routeLinks.library.stepTemplates;
        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Choose Step Template" breadcrumbTitle={"Step Templates"} breadcrumbPath={routeLinks.library.stepTemplates.root} fullWidth={true} flatStyle={true} busy={this.state.busy} errors={this.state.errors}>
                    {this.state.isLoaded && (
                        <>
                            <Section>
                                <FilterSearchBox
                                    hintText="Filter by name, category or description..."
                                    onChange={value => this.setState({ filterText: value.toLowerCase() })}
                                    fullWidth={true}
                                    autoFocus={true}
                                    containerClassName={styles.filterSearchBoxContainer}
                                />
                            </Section>
                            <CommunityActionTemplateList
                                templates={this.state.templates}
                                installationActionName="Install"
                                onPostSelectionUrlRequested={stepTemplatesLink.installedStep}
                                onDetailsUrlRequested={template => stepTemplatesLink.communityTemplate(template).details}
                                filter={this.state.filterText}
                            />
                        </>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }
}

export default CommunityTemplates;
