/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ActionButton, ActionButtonType } from "components/Button";
import BaseComponent from "components/BaseComponent";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { ResourceCollection } from "client/resources";
import { client } from "clientInstance";
import { each } from "lodash";
import { Section } from "components/Section/Section";
const styles = require("./style.less");
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import NumberedPagingBar from "./NumberedPagingBar";
import { PagingCollection } from "client/resources/pagingCollection";

export interface HasId {
    Id: string;
}

export interface PagingPage {
    index: number;
    isActive: boolean;
    skip: number;
    number: string;
}

export interface PagingBaseProps<R extends HasId> {
    showFilterWithinSection?: boolean;
    filterHintText?: string;
    filterSearchEnabled?: boolean; // Filtering/searching is opt-in. Search will only show if you supply `apiSearchParams` also.
    autoFocusOnFilterSearch?: boolean;
    apiSearchParams?: string[]; // Various endpoints may support one or more search-specific parameters. eg. Search by "partialName" and/or "packageVersion".
    // ^ This guides what we do with our keywordSearch parameter onSearch.
    additionalRequestParams?: Map<string, any>; // For any additional parameters you need to supply to the request.
    showPagingInNumberedStyle?: boolean; // Lets you toggle between the numbered paging controls vs the 'load more' style.
    initialData?: ResourceCollection<R> | PagingCollection<R>; // List may internally mutate the data during paging, so this gets passed to state.
    currentPageIndex?: number; // Only use this if you want to manage paging yourself, otherwise this is handled automatically.
    onLoadMore?(): Promise<void>; // Only specify this if you want to override the default 'load more' behaviour.
    onPageSelected?(skip: number, p: number): Promise<void>; // Only specify this if you want to override the default 'paging' behaviour.
    onSearch?(keywordSearch: string): Promise<void>; // This will trigger an API search (using the `apiSearchParams` you've told it to).
    onFilter?(filter: string, item: R): boolean; // Filtering only occurs on the data we have in memory (different to search).
    onNewItems?(items: R[]): Promise<R[]>; // Manipulate new items before they are added to the new state.
    onRow(item: R): React.ReactNode;
    onRowRedirectUrl?(item: R): string | null;
}

export interface PagingBaseState<R extends HasId> {
    filter: string;
    keywordSearch: string;
    isShowingSearchResults: boolean;
    loadingMore: boolean;
    isSearching: boolean;
    redirectTo: string;
    data?: ResourceCollection<R> | PagingCollection<R>;
    currentPageIndex?: number;
    itemsPerPage?: number;
}

export abstract class PagingBaseComponent<R extends HasId, Props extends PagingBaseProps<R>, State extends PagingBaseState<R>> extends BaseComponent<Props, State> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: Props) {
        super(props);

        this.onFilter = props.onFilter || this.onFilter;

        this.provideErrorHandling(this.onLoadMore);
        this.provideErrorHandling(this.onPageSelected);
        this.provideErrorHandling(this.onSearch);

        this.state = {
            ...this.state, //Appease the TS typing gods.
            filter: "",
            keywordSearch: "",
            isShowingSearchResults: false,
            loadingMore: true,
            isSearching: false,
            currentPageIndex: this.props.currentPageIndex ? this.props.currentPageIndex : 0,
            itemsPerPage: this.props.initialData!.ItemsPerPage, // Uses the default returned by the API. Do not hardcode this or there
            // can be mismatches between the first and subsequent pages.
        };
    }

    async componentDidMount() {
        if (this.state.data != null) {
            return;
        }

        const initialData = this.props.initialData;
        if (this.props.onNewItems) {
            const updatedItems = await this.props.onNewItems(initialData!.Items);
            initialData!.Items = updatedItems || [];
        }
        this.setState({
            data: initialData,
            loadingMore: false,
        });
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.initialData !== nextProps.initialData) {
            this.setState({
                data: nextProps.initialData,
                loadingMore: false,
                currentPageIndex: this.props.currentPageIndex ? this.props.currentPageIndex : 0,
            });
        }
    }

    isResourceCollection(collection: ResourceCollection<R> | PagingCollection<R> | undefined): collection is ResourceCollection<R> {
        return (collection as ResourceCollection<R>).Links !== undefined;
    }

    protected onFilter: (filter: string, item: R) => boolean = _ => true;

    protected onLoadMore = async () => {
        this.setState({ loadingMore: true });
        try {
            // Have they provided an override?
            if (this.props.onLoadMore != null) {
                await this.props.onLoadMore();
                return;
            }

            if (!this.isResourceCollection(this.state.data)) {
                return;
            }

            const nextPageIndex = this.state.currentPageIndex! + 1;
            this.setState({
                currentPageIndex: nextPageIndex,
            });
            const skip = nextPageIndex * this.state.itemsPerPage!;
            const requestUri = this.state.data.Links["Template"];
            let requestParams = {
                // Don't skip, just increase the take size.
                take: skip + this.state.itemsPerPage!,
            };
            requestParams = this.addCommonRequestParameters(requestParams);
            await this.requestRaceConditioner.avoidStaleResponsesForRequest(client.get<ResourceCollection<any>>(requestUri, requestParams), async response => {
                if (this.props.onNewItems) {
                    const updatedItems = await this.props.onNewItems(response.Items);
                    response.Items = updatedItems || [];
                }
                this.setState({
                    data: response,
                });
            });
        } finally {
            this.setState({ loadingMore: false });
        }
    };

    protected onPageSelected = async (skip: number, p: number) => {
        if (!this.isResourceCollection(this.state.data)) {
            return;
        }

        if (this.state.data && this.state.data.Links) {
            this.setState({ loadingMore: true });
            try {
                // Have they provided an override?
                if (this.props.onPageSelected != null) {
                    await this.props.onPageSelected(skip, p);
                    this.setState({
                        currentPageIndex: p,
                    });
                    return;
                }

                const requestUri = this.state.data.Links["Template"];
                let requestParams = {
                    skip,
                    take: this.state.itemsPerPage,
                };
                requestParams = this.addCommonRequestParameters(requestParams);
                await this.requestRaceConditioner.avoidStaleResponsesForRequest(client.get<ResourceCollection<R>>(requestUri, requestParams), async response => {
                    if (this.props.onNewItems) {
                        const updatedItems = await this.props.onNewItems(response.Items);
                        response.Items = updatedItems || [];
                    }
                    this.setState({
                        data: response!,
                        currentPageIndex: p!,
                    });
                });
            } finally {
                this.setState({ loadingMore: false });
            }
        }
    };

    protected onSearch = async (keywordSearch: any) => {
        this.setState({ keywordSearch, filter: keywordSearch, isSearching: true }, async () => {
            await this.onPageSelected(0, 0); // New search should reset to page 0.

            // Now figure out if we're searching (or have cleared our search).
            let isShowingSearchResults = true;
            if (!keywordSearch) {
                isShowingSearchResults = false;
            }
            this.setState({ isShowingSearchResults, isSearching: false });
        });
    };

    protected renderFilterSearchBox() {
        if (!this.props.filterSearchEnabled) {
            return null;
        }

        // We switch between a search box that either "filters" or "searches". ie. Filtering data in-line vs searching the API.
        const needToShowSearch = this.state.isShowingSearchResults || this.state.data!.Items.length < this.state.data!.TotalResults;
        const searchBoxHintText = this.props.filterHintText || (this.props.filterSearchEnabled && !needToShowSearch ? "Filter..." : "Search...");

        return (
            <div key="filterSearch">
                <FilterSearchBox autoFocus={this.props.autoFocusOnFilterSearch} hintText={searchBoxHintText} debounceDelay={500} onChange={keyword => this.onFilterSearch(keyword)} />
                {this.props.filterSearchEnabled && needToShowSearch && this.props.apiSearchParams && this.props.apiSearchParams.length > 0 && <BusyIndicator show={this.state.isSearching} inline={true} />}
            </div>
        );
    }

    protected async onFilterSearch(keyword: string) {
        const needToShowSearch = this.state.isShowingSearchResults || this.state.data!.Items.length < this.state.data!.TotalResults;
        if (this.props.filterSearchEnabled && !needToShowSearch) {
            this.setState({ filter: keyword });
        } else {
            await this.onSearch(keyword);
        }
    }

    protected renderFilterSearchComponents() {
        if (!this.props.filterSearchEnabled) {
            return null;
        }

        return this.props.showFilterWithinSection ? <Section>{this.renderFilterSearchBox()}</Section> : this.renderFilterSearchBox();
    }

    protected showPagingInNumberedStyle() {
        const data = this.state.data;

        return <NumberedPagingBar totalItems={data!.TotalResults} currentPageIndex={this.state.currentPageIndex!} pageSize={data!.ItemsPerPage} onPageSelected={(s, p) => this.onPageSelected(s, p)} />;
    }

    protected showPagingInLoadMoreStyle() {
        return (
            <div className={styles.loadMoreContainer}>
                <div className={styles.loadMoreActions}>
                    {!this.state.loadingMore && (
                        <React.Fragment>
                            <ActionButton type={ActionButtonType.Secondary} label="Load more" onClick={() => this.onLoadMore()} />
                            <div className={styles.loadMoreSubText}>Or use filters to narrow the search results</div>
                        </React.Fragment>
                    )}
                    {this.state.loadingMore && <BusyIndicator show={true} />}
                </div>
            </div>
        );
    }

    protected navigate(item: R) {
        const redirectTo = getNavigationUrl(this.props, item);
        if (!redirectTo) {
            return;
        }
        this.setState({ redirectTo });
    }

    private addCommonRequestParameters(requestParams: any) {
        // If a keywordSearch has been provided, supply it for all potential apiSearchParams.
        // Eg. Some APIs have filters for both "name", "description" and "packageVersion" etc. So this lets us
        // quickly search them all for the given keyword.
        if (this.state.keywordSearch) {
            each(this.props.apiSearchParams, param => {
                requestParams[param] = this.state.keywordSearch;
            });
        }

        // Consumers may want to supply additional request params for filters etc.
        if (this.props.additionalRequestParams) {
            this.props.additionalRequestParams.forEach((value: any, key: string) => {
                requestParams[key] = value; // Do not encodeURIComponent here. That is the responsibility of our client's url resolving code.
            });
        }

        return requestParams;
    }
}

function getNavigationUrl<R>({ match, onRowRedirectUrl }: { match?: any; onRowRedirectUrl?(item: R): string | null }, item: R): string | null {
    if (onRowRedirectUrl) {
        return onRowRedirectUrl(item);
    } else if (match && (item as any).Id) {
        return `${match.url}/${(item as any).Id}`;
    }
    return null;
}

export { getNavigationUrl };
