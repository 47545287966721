/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import NavigationSidebarLayout from "components/NavigationSidebarLayout/index";
import AreaTitle from "components/AreaTitle";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import routeLinks from "routeLinks";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DisabledChip } from "components/Chips";
import { ProjectContextProvider, ProjectContextState } from "../../context";
import { repository } from "clientInstance";
import { ProjectGroupResource } from "client/resources";
import { connect } from "react-redux";
import { CreateReleaseButton } from "../Releases";
import { ProjectLinks } from "./ProjectLinks";
import configurationSelectors from "areas/configuration/reducers/selectors";

export interface ProjectRouteParams {
    projectSlug: string;
}

interface StateProps {
    projectId?: string;
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled?: boolean;
    isConfigurationAsCodeEnabled?: boolean;
}

interface ProjectLayoutProps extends RouteComponentProps<ProjectRouteParams> {
    busy?: Promise<any> | boolean;
}

type Props = ProjectLayoutProps & StateProps & GlobalConnectedProps;
interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[];
}

class ProjectLayoutInternal extends DataBaseComponent<Props, ProjectLayoutState> {
    private projectId: string;
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!this.props.projectId || this.props.projectId !== this.projectId) {
                await Promise.all([repository.Projects.get(this.props.match.params.projectSlug), repository.ProjectGroups.all()])
                    .then(([project, projectGroups]) => {
                        this.projectId = project.Id;
                        this.setState({ projectGroups });
                    })
                    .catch(err => {
                        this.setState({
                            errors: err,
                        });
                    });
            }
        });
    }

    render() {
        const projectSlug = this.props.match.params.projectSlug;
        const isMultiTenancyEnabled: boolean | undefined = this.props.isMultiTenancyEnabled;
        const isConfigurationAsCodeEnabled: boolean | undefined = this.props.isConfigurationAsCodeEnabled;
        return (
            <ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug}>
                {({ state }) => {
                    const projectLogo = state.model && state.model && state.model.Links.Logo;
                    const showBusyIndicator = !(state.model || (this.state && this.state.errors));
                    return state.model ? (
                        <main id="maincontent">
                            {this.areaTitle(state, false)}
                            {this.renderErrors()}
                            <NavigationSidebarLayout
                                logoUrl={projectLogo}
                                name={state.model.Name}
                                description={state.model.Description}
                                preNavbarComponent={
                                    <div>
                                        {state.model.IsDisabled && (
                                            <div>
                                                <DisabledChip />
                                            </div>
                                        )}
                                        <CreateReleaseButton projectId={state.model.Id} projectSlug={state.model.Slug} />
                                    </div>
                                }
                                navLinks={ProjectLinks(projectSlug, state.model.Id, state.summary, isMultiTenancyEnabled, isConfigurationAsCodeEnabled)}
                                content={this.props.children}
                            />
                        </main>
                    ) : (
                        <>
                            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(showBusyIndicator)} />
                            {this.renderErrors()}
                        </>
                    );
                }}
            </ProjectContextProvider>
        );
    }

    renderErrors() {
        const errors = this.state && this.state.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} />;
    }

    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.props.busy || forceBusy}>{(busy: boolean) => <BusyIndicator show={busy || forceBusy} />}</BusyFromPromise>;
    }

    private areaTitle(state: ProjectContextState, forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && state.model.ProjectGroupId && this.state.projectGroups.find(pg => pg.Id === state.model.ProjectGroupId);
        return !hasAccessibleProjectGroup ? (
            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(forceBusy)} />
        ) : (
            <AreaTitle breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} link={routeLinks.projects.filteredByGroup(state.model.ProjectGroupId)} title={hasAccessibleProjectGroup.Name} busyIndicator={this.renderBusy(forceBusy)} />
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
        isConfigurationAsCodeEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isConfigurationAsCodeEnabled)(state),
    };
};

export default withRouter(connect(mapGlobalStateToProps)(ProjectLayoutInternal));
