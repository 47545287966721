/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { LifecycleResource, Permission, PhaseResource, RetentionPeriod, RetentionUnit, EnvironmentResource } from "client/resources";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { cloneDeep } from "lodash";
import { Text, ExpandableFormSection, Summary, MarkdownEditor, required, SummaryNodeProps } from "components/form";
import LibraryLayout from "../../LibraryLayout";
import Markdown from "components/Markdown";
import Phase from "../Phase/Phase";
import LifecycleMap from "../LifecycleMap";
import { ActionButton, ActionButtonType } from "components/Button";
import RetentionPolicyControl from "areas/library/components/Lifecycle/RetentionPolicy/RetentionPolicyControl";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import SortPhases from "areas/library/components/Lifecycle/SortPhases/SortPhases";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
const styles = require("./style.less");
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../../routeLinks";
import UnstructuredFormSection from "components/form/Sections/UnstructuredFormSection";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import { SimpleList } from "components/List/SimpleList";
import { ProjectResource } from "client/resources/projectResource";
import Logo from "components/Logo/Logo";
import { sortBy } from "lodash";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "../../../../../components/Navigation/InternalRedirect/InternalRedirect";
import RemovableExpandersList from "components/RemovableExpandersList";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { RetentionPolicySummary } from "../RetentionPolicy/RetentionPolicySummary";
import { UrlNavigationTabsContainer, TabItem } from "components/Tabs";
import SimpleDataTable from "components/SimpleDataTable";

interface LifecycleProps extends RouteComponentProps<LifecycleRouteParams> {
    create?: boolean;
}

interface LifecycleRouteParams {
    lifecycleId: string;
}

interface LifecycleState extends OptionalFormBaseComponentState<LifecycleResource> {
    deleted: boolean;
    newId?: string;
    projects?: ProjectResource[];
    environmentsById?: {};
}

class ProjectList extends SimpleList<ProjectResource> {}

class LifecycleLayout extends FormBaseComponent<LifecycleProps, LifecycleState, LifecycleResource> {
    constructor(props: LifecycleProps) {
        super(props);
        this.state = {
            deleted: false,
            environmentsById: {},
        };
    }

    componentDidMount() {
        return this.doBusyTask(this.load);
    }

    render() {
        const title = this.props.create ? "Create Lifecycle" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions =
            !this.props.create && !!this.state.model
                ? [
                      OverflowMenuItems.deleteItemDefault("lifecycle", this.handleDeleteConfirm, { permission: Permission.LifecycleDelete }),
                      [
                          OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null!, {
                              permission: Permission.EventView,
                              wildcard: true,
                          }),
                      ],
                  ]
                : [];

        const saveText: string = this.state.newId ? "Lifecycle created" : "Lifecycle details updated";

        const actions = [];
        actions.push(
            <PermissionCheck key="edit" permission={Permission.LifecycleEdit}>
                <ActionButton type={ActionButtonType.Secondary} className={styles.actionButton} onClick={this.handleAddPhaseClick} label="Add phase" />
            </PermissionCheck>
        );
        if (this.state.model && this.state.model.Phases.length > 1) {
            actions.push(
                <PermissionCheck key="edit" permission={Permission.LifecycleEdit}>
                    <OpenDialogButton label="Reorder phases">
                        <SortPhases phases={this.state.model.Phases} onPhasesSorted={phases => this.onPhasesSorted(phases)} />
                    </OpenDialogButton>
                </PermissionCheck>
            );
        }

        return (
            <LibraryLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Lifecycles"}
                    breadcrumbPath={routeLinks.library.lifecycles}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: this.props.create ? Permission.LifecycleCreate : Permission.LifecycleEdit }}
                    onSaveClick={this.handleSaveClick}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.lifecycles} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.library.lifecycle(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <UrlNavigationTabsContainer defaultValue="details">
                                <TabItem label="Details" value="details">
                                    <ExpandableFormSection
                                        errorKey="Name"
                                        title="Name"
                                        focusOnExpandAll
                                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your lifecycle")}
                                        help="Enter a name for your lifecycle."
                                    >
                                        <Text value={this.state.model.Name} onChange={Name => this.setModelState({ Name })} label="Lifecycle name" validate={required("Please enter a lifecycle name")} error={this.getFieldError("Name")} autoFocus />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your lifecycle.">
                                        <MarkdownEditor value={this.state.model.Description} label="Lifecycle description" onChange={Description => this.setModelState({ Description })} />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="releaseRetentionPolicy,tentacleRetentionPolicy" title="Retention Policy" summary={this.retentionPolicySummary()} help="Change the retention policy.">
                                        <RetentionPolicyControl
                                            releaseRetentionPolicy={this.state.model.ReleaseRetentionPolicy}
                                            tentacleRetentionPolicy={this.state.model.TentacleRetentionPolicy}
                                            onReleaseRetentionPolicyChange={ReleaseRetentionPolicy => this.setModelState({ ReleaseRetentionPolicy })}
                                            onTentacleRetentionPolicyChange={TentacleRetentionPolicy => this.setModelState({ TentacleRetentionPolicy })}
                                        />
                                    </ExpandableFormSection>
                                    <FormSectionHeading title="Phases" />
                                    <RemovableExpandersList
                                        helpElement={this.renderPhasesHelp()}
                                        typeDisplayName={"Phase"}
                                        data={this.state.model.Phases}
                                        listActions={actions}
                                        onRow={(item: PhaseResource, index: number) => {
                                            const lifecycle = this.state.model;
                                            const environmentsById = this.state.environmentsById;
                                            return this.renderPhase(lifecycle!, environmentsById!, item, index);
                                        }}
                                        onRowSummary={(item: PhaseResource) => {
                                            return Summary.summary(item.Name);
                                        }}
                                        onRowHelp={(item: PhaseResource) => {
                                            return "";
                                        }}
                                        onRemoveRowByIndex={this.handlePhaseDeleteByIndex}
                                    />
                                    <FormSectionHeading title="Lifecycle Preview" />
                                    <UnstructuredFormSection>{this.renderLiveLifecyclePreview()}</UnstructuredFormSection>
                                </TabItem>
                                {!this.props.create && (
                                    <TabItem label="Usage" value="usage" onActive={() => this.onUsageTabActive()}>
                                        <ExpandableFormSection key="usageInProjects" errorKey="usageInProjects" title="Projects" expandable={this.state.projects && this.state.projects.length > 0} summary={this.usageSummary()} help={this.usageHelp()}>
                                            <SimpleDataTable<ProjectResource>
                                                data={this.state.projects!}
                                                headerColumns={["Project Name"]}
                                                onRow={usageEntry => [<InternalLink to={routeLinks.project(usageEntry.Slug).variables.root}>{usageEntry.Name}</InternalLink>]}
                                            />
                                        </ExpandableFormSection>
                                    </TabItem>
                                )}
                            </UrlNavigationTabsContainer>
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </LibraryLayout>
        );
    }

    renderPhase(lifecycle: LifecycleResource, environmentsById: Record<string, EnvironmentResource>, phase: PhaseResource, index: number) {
        return (
            <Phase
                key={index}
                phase={phase}
                lifecycle={lifecycle}
                index={index}
                environmentsById={environmentsById}
                onPhaseDeleteClick={() => this.handlePhaseDeleteByIndex(index)}
                onPhaseNameChange={x => this.handlePhaseNameChange(index, x)}
                onAutomaticEnvironmentDeleteClick={i => this.handleAutomaticEnvironmentDelete(index, i)}
                onOptionalEnvironmentDeleteClick={i => this.handleOptionalEnvironmentDelete(index, i)}
                onAddEnvironment={(environmentId, automatic) => this.handleAddEnvironment(index, environmentId, automatic)}
                onPhaseProgressionChange={(isOptional, minimumEnvironments) => this.handlePhaseProgessionChange(index, isOptional, minimumEnvironments)}
                onChangeRetentionPolicy={(release, tentacle) => this.handlePhaseRetentionPolicyChange(index, release!, tentacle!)}
            />
        );
    }

    private usageHelp = () => {
        return this.state.projects && this.state.projects.length > 0
            ? this.state.projects.length > 1
                ? "This lifecycle is being used in the following projects"
                : "This lifecycle is being used in the following project"
            : "This lifecycle is not being used in any projects";
    };

    private usageSummary = () => {
        return this.state.projects && this.state.projects.length > 0
            ? this.state.projects.length > 1
                ? Summary.summary(
                      <span>
                          This lifecycle is being used in <b>{this.state.projects.length}</b> projects
                      </span>
                  )
                : Summary.summary(
                      <span>
                          This lifecycle is being used in <b>1</b> project
                      </span>
                  )
            : Summary.placeholder("This lifecycle is not being used in any projects");
    };

    private async onUsageTabActive() {
        if (this.state.projects || this.props.create) {
            return;
        }
        await this.doBusyTask(async () => {
            const getProjects = repository.Lifecycles.projects(this.state.model!);
            this.setState({
                projects: sortBy(await getProjects, p => p.Name),
            });
        });
    }

    private handlePhaseDeleteByIndex = (index: number) => {
        this.setState(state => {
            const Phases = state.model!.Phases.filter((_, i) => i !== index);
            return {
                model: {
                    ...state.model,
                    Phases,
                },
            };
        });
    };

    private handlePhaseNameChange = (index: number, name: string) => {
        this.setState(state => {
            const phases = [...state.model!.Phases];
            phases[index] = { ...phases[index], Name: name };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };

    private handleAutomaticEnvironmentDelete = (phaseIndex: number, environmentIndex: number) => {
        this.setState(state => {
            const phases = [...state.model!.Phases];
            const targets = state.model!.Phases[phaseIndex].AutomaticDeploymentTargets.filter((_, i) => i !== environmentIndex);
            phases[phaseIndex] = { ...phases[phaseIndex], AutomaticDeploymentTargets: targets };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };

    private handleOptionalEnvironmentDelete = (phaseIndex: number, environmentIndex: number) => {
        this.setState(state => {
            const phases = [...state.model!.Phases];
            const targets = state.model!.Phases[phaseIndex].OptionalDeploymentTargets.filter((_, i) => i !== environmentIndex);
            phases[phaseIndex] = { ...phases[phaseIndex], OptionalDeploymentTargets: targets };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };

    private onPhasesSorted(phases: PhaseResource[]) {
        this.setState(state => ({
            model: {
                ...state.model,
                Phases: phases,
            },
        }));
        return true;
    }

    private renderLiveLifecyclePreview() {
        return this.state.model!.Phases.length === 0 ? <div>Uses default conventions</div> : <LifecycleMap className={styles.summaryMap} environmentsById={this.state.environmentsById!} lifecyclePreview={this.state.model!} />;
    }

    private renderPhasesHelp() {
        return (
            <div>
                {this.state.model!.Phases.length === 0 && (
                    <div>
                        This lifecycle will use the default conventions. They allow deployment to any environment, so long as environments are deployed in the order that they are defined on the{" "}
                        <InternalLink to={routeLinks.infrastructure.root}>environments</InternalLink> page. Use the <em>Add phase</em> button to explicitly define your own phases or to restrict the lifecycle to specific environments.
                    </div>
                )}
                {this.state.model!.Phases.length > 0 && <div>Projects that use this lifecycle can only be deployed according to the phases below.</div>}
            </div>
        );
    }

    private retentionPolicySummary() {
        return RetentionPolicySummary(this.state.model!.ReleaseRetentionPolicy, this.state.model!.TentacleRetentionPolicy);
    }

    private descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description} />) : Summary.placeholder("No lifecycle description provided");
    }

    private handleAddPhaseClick = () => {
        const phase: PhaseResource = {
            Id: null!,
            Name: "",
            MinimumEnvironmentsBeforePromotion: 0,
            AutomaticDeploymentTargets: [],
            OptionalDeploymentTargets: [],
            IsOptionalPhase: false,
        };
        this.setState(state => ({
            model: {
                ...state.model,
                Phases: [...this.state.model!.Phases, phase],
            },
        }));
    };

    private handlePhaseRetentionPolicyChange = (index: number, release: RetentionPeriod, tentacle: RetentionPeriod): boolean => {
        this.setState(state => {
            const phases = [...state.model!.Phases];
            phases[index] = { ...phases[index], ReleaseRetentionPolicy: release, TentacleRetentionPolicy: tentacle };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
        return true;
    };

    private handlePhaseProgessionChange = (index: number, isOptional: boolean, minimumEnvironments: number): void => {
        this.setState(state => {
            const phases = [...state.model!.Phases];
            phases[index] = { ...phases[index], IsOptionalPhase: isOptional, MinimumEnvironmentsBeforePromotion: minimumEnvironments };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };

    private handleAddEnvironment = (index: number, environmentId: string, automatic: boolean) => {
        if (!environmentId) {
            return;
        }

        this.setState(state => {
            const phases = [...state.model!.Phases];
            phases[index] = automatic
                ? { ...phases[index], AutomaticDeploymentTargets: [...phases[index].AutomaticDeploymentTargets, environmentId] }
                : { ...phases[index], OptionalDeploymentTargets: [...phases[index].OptionalDeploymentTargets, environmentId] };

            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
        return true;
    };

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model!.Id == null;
            const result = await repository.Lifecycles.save(this.state.model!);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null!,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.Lifecycles.del(this.state.model!);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    private load = async () => {
        const getEnvironmentsById = repository.Environments.allById();

        if (this.props.create) {
            const lifecycle: LifecycleResource = {
                Id: null!,
                Name: "",
                SpaceId: "",
                Description: "",
                Phases: [],
                ReleaseRetentionPolicy: {
                    ShouldKeepForever: true,
                    QuantityToKeep: 0,
                    Unit: RetentionUnit.Days,
                },
                TentacleRetentionPolicy: {
                    ShouldKeepForever: true,
                    QuantityToKeep: 0,
                    Unit: RetentionUnit.Days,
                },
                Links: null!,
            };
            this.setState({
                model: lifecycle,
                cleanModel: cloneDeep(lifecycle),
                projects: [],
                environmentsById: await getEnvironmentsById,
            });
        } else {
            const lifecycle = await repository.Lifecycles.get(this.props.match.params.lifecycleId);
            this.setState({
                model: lifecycle,
                cleanModel: cloneDeep(lifecycle),
                environmentsById: await getEnvironmentsById,
            });
        }
    };
}

export default LifecycleLayout;
