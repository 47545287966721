/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import CheckboxField from "material-ui/Checkbox";
import FormFieldProps from "../FormFieldProps";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import cn from "classnames";
import InputLabel from "components/form/InputLabel/InputLabel";
import Note from "components/form/Note/Note";
import { withTheme } from "components/Theme";
const styles = require("./style.less");

interface CheckboxProps extends FormFieldProps<boolean> {
    title?: string | JSX.Element;
    noMargin?: boolean;
    hintText?: string;
    autoFocus?: boolean;
    note?: string | JSX.Element;
    disabled?: boolean;
    label?: string | JSX.Element;
    className?: string;
    error?: string;
    warning?: string;
    iconStyle?: string;
    validate?(value: boolean): string;
    onValidate?(value: string): void;
}

export default class Checkbox extends React.Component<CheckboxProps, {}> {
    checkBoxField: CheckboxField | null;

    handleChange = (event: any, isChecked: boolean) => {
        if (this.props.validate) {
            const validateResult = this.props.validate(isChecked);
            if (this.props.onValidate) {
                this.props.onValidate(validateResult);
            }
        }
        if (this.props.onChange) {
            this.props.onChange(isChecked);
        }
    };

    componentDidMount() {
        if (this.props.autoFocus) {
            // this digs into the Checkbox implementation to set focus
            const internalSwitch = this.checkBoxField?.refs.enhancedSwitch as any;
            internalSwitch.refs.checkbox.focus();
            internalSwitch.tabPressed = true;
            internalSwitch.handleFocus();
        }
    }

    render() {
        return withTheme(theme => {
            const { title, label, className, value, noMargin, onChange, hintText, note, error, warning, iconStyle, ...otherProps } = this.props;

            const isMessageProvided = (error && error.length > 0) || (warning && warning.length > 0);
            const message = isMessageProvided && (error || warning);
            const messageColor = isMessageProvided ? (message === error ? theme.dangerText : theme.alertText) : undefined;

            return (
                <div className={cn(className, { [styles.checkboxMargin]: !noMargin })}>
                    {title && <InputLabel label={title} />}
                    <CheckboxField
                        ref={ref => (this.checkBoxField = ref)}
                        label={label}
                        checked={value}
                        onCheck={this.handleChange}
                        labelStyle={{ width: "100%", color: theme.primaryText }}
                        checkedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.CheckBox} style={{ fill: theme.focus }} />}
                        uncheckedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.CheckBoxOutlineBlank} style={{ fill: theme.primaryText }} />}
                        iconStyle={{ marginRight: "0.5rem" }}
                        {...otherProps}
                    />
                    {message && (
                        <div style={{ color: messageColor }} className={styles.message}>
                            {message}
                        </div>
                    )}
                    {note && (
                        <div className={styles.checkboxNote}>
                            <Note>{note}</Note>
                        </div>
                    )}
                </div>
            );
        });
    }
}
