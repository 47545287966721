import URI from "urijs";
import { DeploymentTargetResource, EnvironmentResource, MachineResource } from "../../../../client/resources";
import { WorkerMachineResource, WorkerPoolResource, isWorkerMachine, isDeploymentTarget } from "client/resources";
import { EndpointRegistrationKey } from "../MachineSettings/Endpoints/endpointRegistry";

export default (root: string) => {
    const infraRoot = `${root}/infrastructure`;

    function workerMachineLinks(machineId: string) {
        const workerMachineRoot = `${infraRoot}/workers/${machineId}`;
        return {
            root: workerMachineRoot,
            settings: `${workerMachineRoot}/settings`,
            connection: `${workerMachineRoot}/connection`,
            events: `${workerMachineRoot}/events`,
        };
    }

    function deploymentTargetLinks(machineId: string) {
        const machineRoot = `${infraRoot}/machines/${machineId}`;
        return {
            root: machineRoot,
            settings: `${machineRoot}/settings`,
            deployments: `${machineRoot}/deployments`,
            connection: `${machineRoot}/connection`,
            events: `${machineRoot}/events`,
        };
    }

    return {
        root: infraRoot,
        overview: `${infraRoot}/overview`,
        environments: {
            root: `${infraRoot}/environments`,
            create: `${infraRoot}/environments/create`,
            filtered: (obj: object) => `${infraRoot}/environments${new URI().search(obj).search()}`,
        },
        environment: (environmentId: string | EnvironmentResource) => {
            if (!!environmentId && typeof environmentId !== "string") {
                environmentId = environmentId.Id;
            }
            return `${infraRoot}/environments/${environmentId}`;
        },
        machines: {
            root: `${infraRoot}/machines`,
            new: (environmentId?: string) => `${infraRoot}/machines/new${environmentId ? `/${environmentId}` : ""}`,
            create: (obj: object = {}) => `${infraRoot}/machines/create${new URI().search(obj).search()}`,
            filtered: (obj: object) => `${infraRoot}/machines${new URI().search(obj).search()}`,
            discover: (type: string, environmentId?: string) => `${infraRoot}/machines/discover/${type}${environmentId ? `/${environmentId}` : ""}`,
        },
        machine: (machineId: string | MachineResource) => {
            if (!!machineId && typeof machineId !== "string") {
                if (isWorkerMachine(machineId as MachineResource)) {
                    return workerMachineLinks(machineId.Id);
                } else {
                    return deploymentTargetLinks(machineId.Id);
                }
            } else {
                return deploymentTargetLinks(machineId as string);
            }
        },
        deploymentTarget: (machineId: string | DeploymentTargetResource) => {
            if (!!machineId && typeof machineId !== "string") {
                machineId = machineId.Id;
            }
            return deploymentTargetLinks(machineId as string);
        },
        proxies: {
            root: `${infraRoot}/proxies`,
            create: `${infraRoot}/proxies/create`,
        },
        proxy: (proxyId: string) => `${infraRoot}/proxies/${proxyId}`,
        machinePolicies: {
            root: `${infraRoot}/machinepolicies`,
            create: `${infraRoot}/machinepolicies/create`,
        },
        machinePolicy: (machinePolicyId: string) => `${infraRoot}/machinepolicies/${machinePolicyId}`,
        accounts: {
            root: `${infraRoot}/accounts`,
            create: `${infraRoot}/accounts/create`,
        },
        account: (accountId: string) => `${infraRoot}/accounts/${accountId}`,
        workerMachines: {
            root: `${infraRoot}/workers`,
            new: (workerPoolId?: string) => `${infraRoot}/workers/new${workerPoolId ? `/${workerPoolId}` : ""}`,
            create: (obj: object = {}) => `${infraRoot}/workers/create${new URI().search(obj).search()}`,
            filtered: (obj: object) => `${infraRoot}/workers${new URI().search(obj).search()}`,
            discover: (type: string, workerPoolId?: string) => `${infraRoot}/workers/discover/${type}${workerPoolId ? `/${workerPoolId}` : ""}`,
        },
        workerMachine: (machineId: string | MachineResource | WorkerMachineResource) => {
            if (!!machineId && typeof machineId !== "string") {
                machineId = machineId.Id;
            }
            return workerMachineLinks(machineId as string);
        },
        workerPools: {
            root: `${infraRoot}/workerpools`,
            create: `${infraRoot}/workerpools/create`,
            filtered: (obj: object) => `${infraRoot}/workerpools${new URI().search(obj).search()}`,
        },
        workerPool: (workerPoolId: string | WorkerPoolResource) => {
            if (!!workerPoolId && typeof workerPoolId !== "string") {
                workerPoolId = workerPoolId.Id;
            }
            return `${infraRoot}/workerpools/${workerPoolId}`;
        },
    };
};
