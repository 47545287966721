/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import DebounceValue from "components/DebounceValue/DebounceValue";
import Text from "components/form/Text/Text";
import cn from "classnames";
import MobileDetect from "mobile-detect";
import { TextInput } from "components/form/Text/Text";
import { withTheme } from "components/Theme";
const styles = require("./style.less");

interface FilterSearchBoxProps {
    hintText?: string;
    onChange: (newValue: string) => void;
    onKeyDown?: (keyEvent: any) => void;
    value?: string;
    autoFocus?: boolean;
    containerClassName?: string;
    inputClassName?: string;
    iconColor?: string;
    iconStyle?: any;
    fullWidth?: boolean;
}

class FilterSearchBoxInternal extends React.Component<FilterSearchBoxProps> {
    ref: TextInput | null = null;

    focus() {
        this.ref?.focus();
    }

    render() {
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : this.props.autoFocus;
        return withTheme(theme => (
            <div className={cn(this.props.containerClassName, styles.filterField)}>
                <ThirdPartyIcon iconType={ThirdPartyIconType.Search} className={styles.searchIcon} color={this.props.iconColor ? this.props.iconColor : theme.secondaryText} style={this.props.iconStyle} />
                <Text
                    textInputRef={this.setRef}
                    type="search"
                    hintText={this.props.hintText}
                    value={this.props.value!}
                    onChange={value => this.props.onChange(value)}
                    autoFocus={autoFocus}
                    onKeyDown={this.props.onKeyDown}
                    className={cn(this.props.inputClassName, this.props.fullWidth ? styles.filterTextBoxFullWidth : styles.filterTextBoxStandardWidth)}
                />
            </div>
        ));
    }

    private setRef = (el: TextInput | null) => {
        this.ref = el;
    };
}

export default DebounceValue<FilterSearchBoxProps, string>(FilterSearchBoxInternal);
