import FeedResource from "client/resources/feedResource";
import { repository } from "clientInstance";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { ProcessContextProps } from "areas/projects/components/Process/Contexts/ProcessContext";

export interface ActionWithFeeds {
    feeds: FeedResource[];
}

export const LoadFeedsFromOptionalContext = (doBusyTask: DoBusyTask, processContext: ProcessContextProps | undefined, callback?: (feeds: FeedResource[]) => void) => {
    return doBusyTask(async () => {
        let feeds: FeedResource[] = [];
        if (processContext && processContext.lookupsState.feeds.length > 0) {
            feeds = processContext.lookupsState.feeds;
        } else {
            feeds = await repository.Feeds.all();
        }

        if (callback) {
            callback(feeds);
        }
    });
};
