/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import isBound from "../../form/BoundField/isBound";
import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources";
import { AccountType, AccountResource, InitialisePrimaryPackageReference, RemovePrimaryPackageReference, SetPrimaryPackageReference, GetPrimaryPackageReference, Permission } from "client/resources";
import { repository } from "clientInstance";
import { ExpandableFormSection, Summary, FormSectionHeading, Note, UnstructuredFormSection } from "components/form";
import { BoundAccountSelect } from "../../form/AccountSelect/AccountSelect";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageSelector from "components/PackageSelector/PackageSelector";
import FeedResource from "client/resources/feedResource";
import RadioButton from "components/form/RadioButton/RadioButton";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import CodeEditor, { TextFormat } from "components/CodeEditor/CodeEditor";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import AzureResourceGroupSelector from "./AzureResourceGroupSelector";
import AzureResourceGroupParameters from "./AzureResourceGroupParameters";
import AzureResourceGroupSourceCodeDialog from "./AzureResourceGroupSourceCodeDialog";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import { TargetRoles } from "areas/projects/components/Process/types";
import { BoundSelect } from "components/form/Select/Select";
import { VariableLookupText } from "components/form/VariableLookupText";
import PermissionCheck, { isAllowed } from "../../PermissionCheck/PermissionCheck";
import Callout, { CalloutType } from "../../Callout";
import { noOp } from "utils/noOp";
import { ActionWithFeeds, LoadFeedsFromOptionalContext } from "../commonActionHelpers";
import { withOptionalProcessContext, WithOptionalProcessContextInjectedProps } from "areas/projects/components/Process/Contexts/ProcessContext";

class AzureResourceGroupActionSummary extends BaseComponent<ActionSummaryProps, any> {
    render() {
        return (
            <div>
                Deploy an Azure Resource Manager Template
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

interface AzureResourceGroupProperties {
    "Octopus.Action.Azure.AccountId": string;
    "Octopus.Action.Azure.ResourceGroupName": string;
    "Octopus.Action.Azure.ResourceGroupDeploymentMode": string;
    "Octopus.Action.Azure.TemplateSource": string;
    "Octopus.Action.Azure.ResourceGroupTemplate": string;
    "Octopus.Action.Azure.ResourceGroupTemplateParameters": string;
}

interface AzureResourceGroupActionEditState extends ActionWithFeeds {
    accounts: AccountResource[];
    accountIsBound: boolean;
}

type Props = ActionEditProps<AzureResourceGroupProperties> & WithOptionalProcessContextInjectedProps;

class AzureResourceGroupActionEditInternal extends BaseComponent<Props, AzureResourceGroupActionEditState> {
    parameterValues: {};
    parameters: {};
    invalidTemplateParameters: any[];
    source: any;
    armJsonParamTypeArray = "array";
    armJsonParamTypes = [this.armJsonParamTypeArray, "object", "secureObject"];

    constructor(props: Props) {
        super(props);
        this.state = {
            feeds: [],
            accounts: [],
            accountIsBound: isBound(props.properties["Octopus.Action.Azure.AccountId"], false),
        };
        this.parameterValues = {};
        this.parameters = {};
        this.source = { octopus: "octopus", azureKeyVault: "azureKeyVault" };
    }

    async componentDidMount() {
        await LoadFeedsFromOptionalContext(this.props.doBusyTask, this.props.processContext, (feeds: FeedResource[]) => {
            this.setState({ feeds });
        });

        await this.props.doBusyTask(async () => {
            if (isAllowed({ permission: Permission.AccountView, wildcard: true })) {
                this.setState({
                    accounts: await repository.Accounts.all(),
                });
            }

            if (!this.props.properties["Octopus.Action.Azure.ResourceGroupDeploymentMode"]) {
                this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupDeploymentMode"]: "Incremental" }, true);
            }
            if (!this.props.properties["Octopus.Action.Azure.TemplateSource"]) {
                this.props.setProperties({ ["Octopus.Action.Azure.TemplateSource"]: "Inline" }, true);
            } else if (this.props.packages.length === 0 && this.props.properties["Octopus.Action.Azure.TemplateSource"] !== "Inline") {
                this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.state.feeds));
            }
        });
    }

    accountSummary() {
        const summary = [];
        const accountId = this.props.properties["Octopus.Action.Azure.AccountId"];
        if (accountId) {
            const account = this.state.accounts.find(x => x.Id === accountId);
            if (account) {
                summary.push(
                    <span>
                        Using the <strong>{account.Name}</strong> account
                    </span>
                );

                this.props.properties["Octopus.Action.Azure.ResourceGroupName"]
                    ? summary.push(
                          <span>
                              {" "}
                              and the <strong>{this.props.properties["Octopus.Action.Azure.ResourceGroupName"]}</strong> resource group
                          </span>
                      )
                    : summary.push(
                          <span>
                              {" "}
                              and <em>(no resource group selected)</em>
                          </span>
                      );

                this.props.properties["Octopus.Action.Azure.ResourceGroupDeploymentMode"]
                    ? summary.push(
                          <span>
                              {" "}
                              with the <strong>{this.props.properties["Octopus.Action.Azure.ResourceGroupDeploymentMode"]}</strong> deployment mode
                          </span>
                      )
                    : summary.push(
                          <span>
                              {" "}
                              with <em>no deployment mode selected</em>
                          </span>
                      );

                return Summary.summary(React.Children.toArray(summary));
            }
            if (isBound(accountId, false)) {
                return Summary.summary(
                    <span>
                        Account is bound to <strong>{accountId}</strong>
                    </span>
                );
            }
        }
        return Summary.placeholder("No account has been selected");
    }

    templateSourceSummary() {
        const source = this.props.properties["Octopus.Action.Azure.TemplateSource"];
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File inside a package");
        }
        return Summary.placeholder("Template source not specified");
    }

    pathsSummary() {
        const summary = [];

        this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"]
            ? summary.push(
                  <span>
                      Using the template in <strong>{this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"]}</strong>
                  </span>
              )
            : summary.push(
                  <span>
                      {" "}
                      <em>no template path specified</em>
                  </span>
              );

        this.props.properties["Octopus.Action.Azure.ResourceGroupTemplateParameters"]
            ? summary.push(
                  <span>
                      {" "}
                      with the parameters in <strong>{this.props.properties["Octopus.Action.Azure.ResourceGroupTemplateParameters"]}</strong>
                  </span>
              )
            : summary.push(
                  <span>
                      {" "}
                      <em>no parameters path specified</em>
                  </span>
              );

        return Summary.summary(React.Children.toArray(summary));
    }

    onChangeTemplateSource(value: any) {
        this.props.setProperties({
            ["Octopus.Action.Azure.TemplateSource"]: value,
            ["Octopus.Action.Azure.ResourceGroupTemplate"]: "",
            ["Octopus.Action.Azure.ResourceGroupTemplateParameters"]: "",
        });

        // If the inline option is selected, we clear out the package selection
        if (value === "Inline") {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        } else {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.state.feeds));
        }
    }

    getAccountId = () => {
        return isBound(this.props.properties["Octopus.Action.Azure.AccountId"]) ? undefined : this.props.properties["Octopus.Action.Azure.AccountId"];
    };

    render() {
        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);

        return (
            <div>
                <FormSectionHeading title="Azure" />
                <PermissionCheck
                    permission={Permission.AccountView}
                    wildcard={true}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.AccountView} permission is required to change the Azure settings.
                        </Callout>
                    }
                >
                    <ExpandableFormSection errorKey="Octopus.Action.Azure.AccountId" isExpandedByDefault={this.props.expandedByDefault} title="Account" summary={this.accountSummary()} help={"Select the account to use for the connection."}>
                        <BoundAccountSelect
                            variableLookup={{
                                localNames: this.props.localNames,
                            }}
                            resetValue={properties["Octopus.Action.Azure.AccountId"]}
                            label="Account"
                            value={properties["Octopus.Action.Azure.AccountId"]}
                            isBound={this.state.accountIsBound}
                            onIsBoundChanged={(value: boolean) => this.setState({ accountIsBound: value })}
                            type={[AccountType.AzureServicePrincipal]}
                            allowClear={true}
                            onChange={x => this.props.setProperties({ ["Octopus.Action.Azure.AccountId"]: x })}
                            error={this.props.getFieldError("Octopus.Action.Azure.AccountId")}
                            items={this.state.accounts}
                            onRequestRefresh={this.refreshAccounts}
                        />
                        <AzureResourceGroupSelector
                            doBusyTask={this.props.doBusyTask}
                            properties={this.props.properties}
                            setProperties={this.props.setProperties}
                            getFieldError={this.props.getFieldError}
                            projectId={this.props.projectId!}
                            accountId={this.getAccountId()!}
                            isAccountBound={this.state.accountIsBound}
                            localNames={this.props.localNames!}
                        />
                        <BoundSelect
                            variableLookup={{
                                localNames: this.props.localNames,
                            }}
                            resetValue={""}
                            label="Deployment mode"
                            value={this.props.properties["Octopus.Action.Azure.ResourceGroupDeploymentMode"]}
                            items={["Incremental", "Complete"].map(item => ({ value: item, text: item }))}
                            onChange={val => this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupDeploymentMode"]: val })}
                            error={this.props.getFieldError("Octopus.Action.Azure.ResourceGroupDeploymentMode")}
                        />
                    </ExpandableFormSection>
                </PermissionCheck>

                <FormSectionHeading title="Template" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.TemplateSource"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Template Source"
                    fillCardWidth={CardFill.FillRight}
                    summary={this.templateSourceSummary()}
                    help={"Select the source of the template."}
                >
                    <Note>Templates can be entered as source-code, or contained in a package.</Note>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Azure.TemplateSource"]} onChange={(val: string) => this.onChangeTemplateSource(val)} error={this.props.getFieldError("Octopus.Action.Azure.TemplateSource")}>
                        <RadioButton value={"Inline"} label="Source code" />
                        <RadioButton value={"Package"} label="File inside a package" />
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Azure.TemplateSource"] === "Inline" && (
                        <div>
                            <br />
                            {this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"] && (
                                <CodeEditor value={this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"]} language={TextFormat.JSON} allowFullScreen={false} readOnly={true} />
                            )}
                            <div>
                                <OpenDialogButton
                                    label={this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"] ? "Edit Source Code" : "Add Source Code"}
                                    wideDialog={true}
                                    renderDialog={renderProps => (
                                        <AzureResourceGroupSourceCodeDialog
                                            close={renderProps.closeDialog}
                                            open={renderProps.open}
                                            template={this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"]}
                                            saveDone={val => this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupTemplate"]: val })}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </ExpandableFormSection>
                {this.props.properties["Octopus.Action.Azure.TemplateSource"] === "Package" && (
                    <div>
                        <ExpandableFormSection
                            errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Package"
                            summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                            help={"Choose the package that contains the template source."}
                        >
                            <PackageSelector
                                packageId={pkg && pkg.PackageId}
                                feedId={pkg && pkg.FeedId}
                                onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                                onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                                packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                                feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                                projectId={this.props.projectId}
                                feeds={this.state.feeds}
                                localNames={this.props.localNames}
                                refreshFeeds={this.loadFeeds}
                            />
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="Octopus.Action.Azure.ResourceGroupTemplate|Octopus.Action.Azure.ResourceGroupTemplateParameters"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Paths"
                            summary={this.pathsSummary()}
                            help={"Enter the relative paths for the template and parameter files in the package."}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                label="Template file path"
                                value={this.props.properties["Octopus.Action.Azure.ResourceGroupTemplate"]}
                                onChange={(val: string) => this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupTemplate"]: val })}
                                error={this.props.getFieldError("Octopus.Action.Azure.ResourceGroupTemplate")}
                            />
                            <Note>Relative path to the JSON template file contained in the package e.g. template.json or path\template.json</Note>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                label="Parameter file path"
                                value={this.props.properties["Octopus.Action.Azure.ResourceGroupTemplateParameters"]}
                                onChange={val => this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupTemplateParameters"]: val })}
                                error={this.props.getFieldError("Octopus.Action.Azure.ResourceGroupTemplateParameters")}
                            />
                            <Note>Relative path to the JSON parameters file contained in the package e.g. parameters.json or path\parameters.json</Note>
                        </ExpandableFormSection>
                    </div>
                )}

                {this.props.properties["Octopus.Action.Azure.TemplateSource"] === "Inline" && (
                    <div>
                        <FormSectionHeading title="Parameters" />
                        <AzureResourceGroupParameters projectId={this.props.projectId!} localNames={this.props.localNames!} properties={this.props.properties} setProperties={this.props.setProperties} doBusyTask={this.props.doBusyTask} />
                    </div>
                )}
            </div>
        );
    }

    private refreshAccounts = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };
}

export const AzureResourceGroupActionEdit = withOptionalProcessContext(AzureResourceGroupActionEditInternal);

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureResourceGroup",
    summary: (properties, targetRolesAsCSV) => <AzureResourceGroupActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: step => true,
    canBeChild: true,
    edit: AzureResourceGroupActionEdit,
    targetRoleOption: action => TargetRoles.Optional,
    hasPackages: action => false,
    features: {
        optional: ["Octopus.Features.CustomScripts", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
});
