/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ConnectDragSource, DragSource, ConnectDragPreview } from "react-dnd";
import ResizeColumnHandle from "components/ScrollTable/ResizeColumnHandle/ResizeColumnHandle";
import { getEmptyImage } from "react-dnd-html5-backend";

export const resizeHandleType = "column-header-resize-handle";

export interface ResizeHandleDragItem {
    height: number;
    measurements: ColumnMeasurements;
}

export interface ColumnMeasurements {
    rightWidth: number;
    leftWidth: number;
    rightMinimumWidth: number;
    leftMinimumWidth: number;
}

interface DraggableResizeColumnHandleProps extends Partial<ConnectedDraggableResizeColumnHandleProps> {
    isDark: boolean;
    getColumnMeasurements(): ColumnMeasurements;
    onResize(deltaX: number, initialColumnMeasurements: ColumnMeasurements): void;
    onIsDraggingChanged(isDragging: boolean): void;
}

// These props are all injected by `DragSource`
interface ConnectedDraggableResizeColumnHandleProps {
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    connectDragPreview: ConnectDragPreview;
}

export default class DraggableResizeColumnHandleInternal extends React.Component<DraggableResizeColumnHandleProps> {
    private heightProvider: HTMLElement | null = null;

    componentDidMount() {
        // Set drag preview to an empty image, because the preview is rendered using a drag layer,
        // see `ResizeColumnHandleDragLayerProps`
        this.props.connectDragPreview!(getEmptyImage());
    }

    getHeight() {
        return this.heightProvider ? this.heightProvider.offsetHeight : 0;
    }

    render() {
        // Hide it when you are dragging,  because an identical element is drawn instead
        // at the cursor location on the drag preview layer (`ResizeColumnHandleDragLayerProps`)
        if (this.props.isDragging) {
            return null;
        }

        return this.props.connectDragSource!(
            <div ref={heightProvider => (this.heightProvider = heightProvider)}>
                <ResizeColumnHandle isActive={this.props.isDark} />
            </div>
        );
    }
}

export const DraggableResizeColumnHandle = DragSource(
    resizeHandleType,
    {
        beginDrag(props: DraggableResizeColumnHandleProps, monitor, component: DraggableResizeColumnHandleInternal) {
            props.onIsDraggingChanged(true);
            const item: ResizeHandleDragItem = {
                measurements: props.getColumnMeasurements(),
                // The handle's height is based on the parent container's height
                // So when we 'copy' it for the drag preview, we need to explicitly set the height to the same thing
                height: component.getHeight(),
            };
            return item;
        },
        endDrag(props: DraggableResizeColumnHandleProps, monitor) {
            props.onIsDraggingChanged(false);
            const item: ResizeHandleDragItem = monitor.getItem() as any;
            const deltaX = getBoundedDeltaX(monitor.getDifferenceFromInitialOffset()!.x, item.measurements);
            props.onResize(deltaX, item.measurements);
        },
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    })
)(DraggableResizeColumnHandleInternal);

export function getBoundedDeltaX(actualMouseDeltaX: number, measurements: ColumnMeasurements) {
    const boundedRightOffset = measurements.rightWidth - measurements.rightMinimumWidth;
    const boundedLeftOffset = -(measurements.leftWidth - measurements.leftMinimumWidth);
    return Math.min(boundedRightOffset, Math.max(actualMouseDeltaX, boundedLeftOffset));
}
