/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ScopeValues } from "client/resources/variableSetResource";
import { ScopeSpecification } from "areas/variables/ReadonlyVariableResource";
import { AdvancedTenantTagsSelector } from "components/AdvancedTenantSelector/AdvancedTenantSelector";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import RoleMultiSelect from "components/MultiSelect/RoleMultiSelect";
import MachineMultiSelect from "components/MultiSelect/MachineMultiSelect";
import ChannelMultiSelect from "components/MultiSelect/ChannelMultiSelect";
import StepMultiSelect from "components/MultiSelect/StepMultiSelect";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";
import { TagSetResource } from "../../../client/resources";
import * as tenantTagsets from "../../../components/tenantTagsets";
import { keyBy } from "lodash";
import ProcessMultiSelect from "components/MultiSelect/ProcessMultiSelect";
import { VariableType } from "../../../client/resources/variableResource";

const styles = require("./style.less");

interface ScopeSelectorProps {
    value: ScopeSpecification;
    availableScopes: ScopeValues;
    isProjectScoped: boolean; // disables Step and Channel selection if not project scoped
    allowTenantTagSelection: boolean;
    useCompactControls: boolean;
    variableType: VariableType;
    doBusyTask: DoBusyTask;
    onScopeSelected: (scope: ScopeSpecification) => void;
}

interface ScopeSelectorState {
    tagSets: TagSetResource[];
}

export default class ScopeSelector extends React.Component<ScopeSelectorProps, ScopeSelectorState> {
    private firstInput: FocusableComponent | null = null;

    constructor(props: ScopeSelectorProps) {
        super(props);

        this.state = {
            tagSets: null!,
        };
    }

    focusFirstInput() {
        if (this.firstInput) {
            this.firstInput.focus();
        }
    }

    async componentDidMount() {
        if (this.props.allowTenantTagSelection) {
            await this.props.doBusyTask(async () => {
                const tenantTagMap = keyBy(this.props.availableScopes.TenantTags, tag => tag.Id);
                const tagSets = await tenantTagsets.getAll();
                this.setState({ tagSets: tagSets.map(ts => ({ ...ts, Tags: ts.Tags.filter(tag => !!tenantTagMap[tag.CanonicalTagName]) })) });
            });
        }
    }

    render() {
        const showRoleSelect = this.props.variableType !== VariableType.WorkerPool || !!this.props.value.Role;
        const showMachineSelect = this.props.variableType !== VariableType.WorkerPool || !!this.props.value.Machine;

        return (
            <div className={styles.scopeContainer}>
                <div className={this.props.allowTenantTagSelection ? styles.firstColumn : styles.firstColumnFullWidth}>
                    <EnvironmentMultiSelect
                        multiSelectRef={firstInput => (this.firstInput = firstInput)}
                        value={this.props.value.Environment ? [...(this.props.value.Environment as string[])] : []}
                        items={this.props.availableScopes.Environments}
                        onChange={e => this.handleEnvironmentsChanged(e)}
                        openOnFocus={false}
                        hideFloatingLabel={this.props.useCompactControls}
                    />
                    {showRoleSelect && (
                        <RoleMultiSelect
                            value={this.props.value.Role ? [...(this.props.value.Role as string[])] : []}
                            items={this.props.availableScopes.Roles.map(r => r.Id)}
                            onChange={e => this.handleRolesChanged(e)}
                            openOnFocus={false}
                            canAdd={true}
                            hideFloatingLabel={this.props.useCompactControls}
                        />
                    )}
                    {showMachineSelect && (
                        <MachineMultiSelect
                            value={this.props.value.Machine ? [...(this.props.value.Machine as string[])] : []}
                            items={this.props.availableScopes.Machines}
                            onChange={(m: any) => this.handleMachinesChanged(m)}
                            openOnFocus={false}
                            hideFloatingLabel={this.props.useCompactControls}
                        />
                    )}
                    {this.props.isProjectScoped && (
                        <ProcessMultiSelect
                            value={this.props.value.ProcessOwner ? [...(this.props.value.ProcessOwner as string[])] : []}
                            items={this.props.availableScopes.Processes}
                            openOnFocus={false}
                            hideFloatingLabel={this.props.useCompactControls}
                            onChange={this.handleProcessChanged}
                        />
                    )}
                    {this.props.isProjectScoped && (
                        <StepMultiSelect
                            value={this.props.value.Action ? [...(this.props.value.Action as string[])] : []}
                            items={this.props.availableScopes.Actions}
                            onChange={(s: any) => this.handleStepsChanged(s)}
                            openOnFocus={false}
                            hideFloatingLabel={this.props.useCompactControls}
                        />
                    )}
                    {this.props.isProjectScoped && (
                        <ChannelMultiSelect
                            value={this.props.value.Channel ? [...(this.props.value.Channel as string[])] : []}
                            items={this.props.availableScopes.Channels}
                            onChange={(s: any) => this.handleChannelsChanged(s)}
                            openOnFocus={false}
                            hideFloatingLabel={this.props.useCompactControls}
                        />
                    )}
                </div>
                {this.props.allowTenantTagSelection && this.state.tagSets && (
                    <div className={styles.secondColumn}>
                        <AdvancedTenantTagsSelector
                            tagSets={this.state.tagSets}
                            selectedTenantTags={this.props.value.TenantTag ? [...(this.props.value.TenantTag as string[])] : []}
                            doBusyTask={this.props.doBusyTask}
                            onChange={tenantTags => this.handleTenantTagsChanged(tenantTags)}
                            showPreviewButton={true}
                        />
                    </div>
                )}
            </div>
        );
    }

    private handleEnvironmentsChanged = (environments: string[]) => {
        this.changeScope(s => ({ ...s, Environment: environments }));
    };

    private handleRolesChanged = (roles: string[]) => {
        this.changeScope(s => ({ ...s, Role: roles }));
    };

    private handleMachinesChanged = (machines: string[]) => {
        this.changeScope(s => ({ ...s, Machine: machines }));
    };

    private handleStepsChanged = (steps: string[]) => {
        this.changeScope(s => ({ ...s, Action: steps }));
    };

    private handleChannelsChanged = (channels: string[]) => {
        this.changeScope(s => ({ ...s, Channel: channels }));
    };

    private handleTenantTagsChanged = (tenantTags: string[]) => {
        this.changeScope(s => ({ ...s, TenantTag: tenantTags }));
    };

    private handleProcessChanged = (processes: string[]) => {
        this.changeScope(s => ({ ...s, ProcessOwner: processes }));
    };

    private changeScope(updateScope: (scope: ScopeSpecification) => ScopeSpecification) {
        this.props.onScopeSelected(updateScope(this.props.value));
    }
}
