import * as React from "react";
import { UserResource, NamedReferenceItem } from "client/resources";
import { Avatar } from "components/Avatar/Avatar";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../../../routeLinks";
import styles = require("./MemberRow.less");

const workTeamImage = require("../../../../components/Avatar/img/work_team.svg");

type MemberTypes = UserResource | NamedReferenceItem;

function isUser(item: MemberTypes): item is UserResource {
    return !!(item as UserResource).Username;
}

function nameWithUsername(u: UserResource) {
    return !!u && u.DisplayName !== u.Username ? `${u.DisplayName} (${u.Username})` : u.DisplayName;
}

function namedGroup(u: NamedReferenceItem) {
    return u.DisplayIdAndName ? `${u.DisplayName} (${u.Id})` : u.DisplayName;
}

interface MemberRowProps {
    member: MemberTypes;
}

export const MemberRow: React.StatelessComponent<MemberRowProps> = props => {
    const member = props.member;
    const image = isUser(member) ? (
        <Avatar avatarLink={member && member.Links && member.Links.Avatar} isService={member && member.IsService} size={24} />
    ) : (
        <img src={workTeamImage} className={styles.groupImage} alt={member.DisplayIdAndName ? "Role" : "Security group"} />
    );

    const name = isUser(member) ? nameWithUsername(member) : namedGroup(member);

    const nameLink =
        member && member.Id && member.Id.toLowerCase().startsWith("users-") ? ( // only internal link to Octopus controlled user accounts
            <InternalLink to={routeLinks.configuration.user(member.Id)}>{name}</InternalLink>
        ) : (
            name
        );

    return (
        <div className={styles.user}>
            {image}
            <span className={styles.userName}>{nameLink}</span>
        </div>
    );
};
