import { repository } from "../../../clientInstance";
import { NotificationType } from "../notifications.redux";
import { NotificationPoller } from "./NotificationPoller";
import * as notificationLocalState from "./notificationLocalState";
import { isAllowed } from "../../PermissionCheck/PermissionCheck";
import { Permission } from "../../../client/resources/permission";
import routeLinks from "../../../routeLinks";
import moment from "moment";
import { HostingEnvironment } from "../../../client/resources";

export default class LicenceNotificationPoller extends NotificationPoller {
    notificationId: string = "Notification/LicenceCheck";

    notificationCheck = async () => {
        const licenseStatus = await repository.Licenses.getCurrentStatus();

        const isExpired = licenseStatus.DaysToEffectiveExpiryDate < 0;
        const isExpiringSoon = licenseStatus.DaysToEffectiveExpiryDate >= 0 && licenseStatus.DaysToEffectiveExpiryDate < 15;
        const showLicenseExpiringNotification = licenseStatus.HostingEnvironment !== HostingEnvironment.OctopusCloud && (isExpiringSoon || isExpired);

        if (!showLicenseExpiringNotification) {
            return this.dismissNotification();
        }

        if (this.wasDismissedLoadedOverTwoDasyAgo()) {
            notificationLocalState.clearValue(this.notificationId);
        }

        let isAdmin = false;
        let licenseRenewalUrl = "Renew";
        if (isAllowed({ permission: Permission.AdministerSystem })) {
            // Retrieve license details so we can provide a 'Renew Now' link
            const license = await repository.Licenses.getCurrent();
            isAdmin = license != null;
            licenseRenewalUrl = `Renew?licenseKey=${license.SerialNumber}`;
        }

        this.raiseNotification({
            id: this.notificationId,
            text: isExpired ? "Your license has expired." : "Your license is expiring soon.",
            description: "Renew now to continue receiving support and updates.",
            links: isAdmin
                ? [
                      { type: "External", label: "Renew Now", href: licenseRenewalUrl },
                      { type: "Internal", label: "View license details", href: routeLinks.configuration.license },
                  ]
                : [{ type: "None", label: "Contact your Octopus administrator to review and renew your license." }],
            type: isExpired ? NotificationType.error : NotificationType.warn,
        });
    };

    private wasDismissedLoadedOverTwoDasyAgo() {
        const userReadLicenseRenewalNotificationOnDateString = notificationLocalState.getCurrentValue(this.notificationId);
        if (userReadLicenseRenewalNotificationOnDateString === null) {
            return false;
        }

        const dateAsNumber = Date.parse(userReadLicenseRenewalNotificationOnDateString);
        if (isNaN(dateAsNumber)) {
            return true;
        }

        return moment(dateAsNumber).isBefore(moment().subtract(2, "days"));
    }
}
