import InsertVariableButton from "components/form/InsertVariableButton/InsertVariableButton";
import { ScriptingLanguage } from "components/scriptingLanguage";
import * as React from "react";

const styles = require("./style.less");

export interface VariableLookupProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
}

export interface TextInputRef {
    focus: () => void;
    insertAtCursor: (val: string) => void;
}

export interface GlobalConnectedProps {
    textInputRef(input: TextInputRef | null): void;
}

export function withVariableLookup() {
    return <TProps extends {}>(Comp: React.ComponentClass<TProps & GlobalConnectedProps> | React.StatelessComponent<TProps & GlobalConnectedProps>) => {
        const WithVariableLookup: React.FC<TProps & VariableLookupProps> = props => {
            const textInputRef = React.useRef<TextInputRef | null>(null);
            const { localNames, syntax, ...otherProps } = props;
            const insert = React.useCallback(
                (val: string) => {
                    if (textInputRef.current) {
                        textInputRef.current.insertAtCursor(val);
                        textInputRef.current.focus();
                    }
                },
                [textInputRef]
            );

            return (
                <div className={styles.container}>
                    <div className={styles.wide}>
                        <Comp key="component" textInputRef={ref => (textInputRef.current = ref)} {...(otherProps as TProps & GlobalConnectedProps)} />
                    </div>
                    <div key="buttons" className={styles.buttons}>
                        <InsertVariableButton syntax={props.syntax} localNames={props.localNames} onSelected={v => insert(v)} />
                    </div>
                </div>
            );
        };
        return WithVariableLookup;
    };
}
