/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import isBound from "components/form/BoundField/isBound";
import * as React from "react";
import { repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { Permission } from "client/resources/permission";
import { RunbookSnapshotResource } from "client/resources/runbookSnapshotResource";
import { RunbookSnapshotTemplateResource } from "client/resources/runbookSnapshotTemplateResource";
import { Callout, CalloutType } from "components/Callout/Callout";
import { ExpandableFormSection, Summary, Note } from "components/form";
import { default as FormBaseComponent, OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { RunbookProcessResource } from "client/resources/runbookProcessResource";
import Text from "components/form/Text/Text";
import { required } from "components/form/Validators";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import ToolTip from "components/ToolTip/index";
import RadioButton from "components/form/RadioButton/RadioButton";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "components/DataTable";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import { cloneDeep, Dictionary, flatten, groupBy, isEqual, keys } from "lodash";
import { RouteComponentProps } from "react-router";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { ActionButtonType } from "components/Button/ActionButton";
import { ProjectRouteParams } from "../../ProjectLayout/ProjectLayout";
import routeLinks from "routeLinks";
import { packageRowClass, packagesTableClass } from "uiTestClasses";
import { PackageEditInfo, VersionType } from "areas/projects/components/Releases/packageModel";
import FeedResource, { FeedType } from "client/resources/feedResource";
import cn from "classnames";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { ControlExpanders, GlobalDispatchControlExpandersProps } from "components/ControlExpanders/ControlExpanders";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import DebounceValue from "components/DebounceValue/DebounceValue";
import { ResourcesById } from "client/repositories/basicRepository";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
const styles = require("./RunbookSnapshotEdit.less");
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { RunbookRouteProps } from "./RunbookSnapshots";
import PackageListDialogContent from "../../Releases/PackageListDialog/PackageListDialogContent";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { withRunbookContext, WithRunbookContextInjectedProps } from "../RunbookContext";
import StringHelper from "utils/StringHelper/StringHelper";
import { publishingExplainedElement } from "../PublishButton";
import LoadMoreWrapper from "components/LoadMoreWrapper/LoadMoreWrapper";
import * as _ from "lodash";

const versionExpanderKey = "version";

interface RunbookSnapshotModel {
    packages: PackageEditInfo[];
    runbookSnapshot: RunbookSnapshotResource;
}

interface RunbookSnapshotState extends OptionalFormBaseComponentState<RunbookSnapshotModel> {
    originalName: string;
    runbookProcess: RunbookProcessResource;
    template: RunbookSnapshotTemplateResource;
    seeVersionExample: boolean;
    isNew: boolean;
    redirect: boolean;
    deleted: boolean;
    defaultCheckModel: RunbookSnapshotModel;
    feeds: ResourcesById<FeedResource>;
    hasInitialModelUpdateCompleted: boolean; // To stop the user being able to interact with the runbookSnapshot version input before we've finished loading version rules.
}

const DebounceText = DebounceValue(Text);

type RunbookSnapshotEditProps = RouteComponentProps<ProjectRouteParams & RunbookRouteProps & { runbookSnapshotId: string }> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps & GlobalDispatchControlExpandersProps;

class RunbookSnapshotEditInternal extends FormBaseComponent<RunbookSnapshotEditProps, RunbookSnapshotState, RunbookSnapshotModel> {
    runbookSnapshotId: any = null;
    textField: any = null;
    memoizedRepositoryChannelsRuleTest = _.memoize((version: string, versionRange: string, preReleaseTag: string, feedType: FeedType) =>
        repository.Channels.ruleTest(version, {
            versionRange,
            preReleaseTag,
            feedType,
        })
    );

    constructor(props: RunbookSnapshotEditProps) {
        super(props);
        this.runbookSnapshotId = this.props && this.props.match && this.props.match.params ? this.props.match.params.runbookSnapshotId : null;
        this.state = {
            originalName: null!,
            runbookProcess: null!,
            template: null!,
            seeVersionExample: false,
            isNew: true,
            redirect: false,
            deleted: false,
            defaultCheckModel: null!,
            feeds: null!,
            hasInitialModelUpdateCompleted: false,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(prevProps: RunbookSnapshotEditProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            let runbookSnapshot = null;
            let originalName = null;
            let runbookProcessPromise = null;
            let isNew = true;
            let cleanModel: any = null;
            if (this.runbookSnapshotId) {
                runbookSnapshot = await repository.RunbookSnapshots.get(this.runbookSnapshotId);
                originalName = runbookSnapshot.Name;
                isNew = false;
                runbookProcessPromise = this.loadRunbookProcess(runbookSnapshot.FrozenRunbookProcessId);
            } else {
                runbookSnapshot = {
                    ProjectId: project.Id,
                    RunbookId: runbook.Id,
                };
                runbookProcessPromise = this.loadRunbookProcess(runbook.RunbookProcessId);
                cleanModel = {
                    version: null,
                    packages: [],
                    runbookSnapshotNotes: null,
                    runbookSnapshot: null,
                    options: null,
                };
            }

            const model = this.buildModel(runbookSnapshot as RunbookSnapshotResource, []);
            if (isNew) {
                model.runbookSnapshot.Notes = null!;
            }
            const [feeds, runbookProcess] = await Promise.all([repository.Feeds.allById(), runbookProcessPromise]);
            await this.loadTemplate(model, runbookProcess);

            this.setState({
                originalName: originalName!,
                runbookProcess,
                model,
                cleanModel: cleanModel ? cleanModel : cloneDeep(model),
                defaultCheckModel: cloneDeep(model),
                isNew,
                feeds,
                hasInitialModelUpdateCompleted: true,
            });
        });
    }

    render() {
        const runbookRoutes = routeLinks.project(this.props.match.params.projectSlug).operations.runbook(this.props.match.params.runbookId);
        if (this.state.redirect) {
            return <InternalRedirect to={runbookRoutes.runbookSnapshot(this.state.model!.runbookSnapshot.Id).root} push={true} />;
        }
        if (this.state.deleted) {
            return <InternalRedirect to={runbookRoutes.runbookSnapshots} push={true} />;
        }

        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook || !project) {
            return <PaperLayout busy={true} errors={this.state.errors} />;
        }

        const overFlowActions =
            !this.state.isNew && !!this.state.model && !!this.state.model.runbookSnapshot
                ? [
                      OverflowMenuItems.deleteItemDefault(
                          "snapshot",
                          this.handleDeleteConfirm,
                          {
                              permission: Permission.RunbookEdit,
                              project: project && project.Id,
                              wildcard: true,
                          },
                          "The snapshot and any of its steps will be permanently deleted and they will disappear from all dashboards."
                      ),
                      [
                          OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.runbookSnapshot.Id]), null!, {
                              permission: Permission.EventView,
                              wildcard: true,
                          }),
                      ],
                  ]
                : [];

        let title = "Snapshot";
        if (project) {
            title = this.state.isNew ? "Publish new snapshot for " + project.Name : this.state.model && this.state.model.runbookSnapshot ? "Edit " + this.state.model.runbookSnapshot.Name : "Edit snapshot";
        }
        if (this.state.runbookProcess && this.state.runbookProcess.Steps.length === 0) {
            return (
                <PaperLayout
                    busy={this.state.busy}
                    errors={this.state.errors}
                    title={title}
                    breadcrumbTitle={`${runbook && runbook.Name} snapshots`}
                    breadcrumbPath={project ? routeLinks.project(project).operations.runbook(runbook.Id).runbookSnapshots : null!}
                >
                    <Callout title="Note" type={CalloutType.Information}>
                        <div>The runbook does not have any steps.</div>
                    </Callout>
                </PaperLayout>
            );
        }

        const hasLoaded = !!this.state.model;
        let breadcrumbTitle = hasLoaded ? "Snapshots" : StringHelper.ellipsis;
        let breadcrumbPath = project ? routeLinks.project(project).operations.runbook(runbook.Id).runbookSnapshots : null;
        if (hasLoaded && this.state.isNew) {
            breadcrumbTitle = runbook && runbook.Name;
            breadcrumbPath = runbook && routeLinks.project(this.props.match.params.projectSlug).operations.runbook(runbook.Id).root;
        }

        return (
            <FormPaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title={title}
                breadcrumbTitle={breadcrumbTitle}
                breadcrumbPath={breadcrumbPath!}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                disableDirtyFormChecking={this.state.isNew && this.disableDirtyFormCheck()}
                savePermission={{
                    permission: Permission.RunbookEdit,
                    project: project && project.Id,
                    wildcard: true,
                }}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
                saveText="Snapshot saved"
                saveButtonLabel={this.state.isNew ? "Publish" : undefined}
                saveButtonBusyLabel={this.state.isNew ? "Publishing..." : undefined}
            >
                {this.state.model && this.state.hasInitialModelUpdateCompleted && (
                    <TransitionAnimation>
                        {this.state.isNew && (
                            <Callout title={"Why publish?"} type={CalloutType.Information}>
                                {publishingExplainedElement} A snapshot will be taken of the process, variables and packages, allowing changes to be made safely. <ExternalLink href="RunbookPublishing">Learn more</ExternalLink>
                            </Callout>
                        )}
                        <ExpandableFormSection
                            errorKey={versionExpanderKey}
                            title="Name"
                            summary={this.state.model.runbookSnapshot.Name ? Summary.summary(this.state.model.runbookSnapshot.Name) : Summary.placeholder("Please enter a name")}
                            help="Enter a unique version number for this snapshot with at least two parts."
                        >
                            <Text value={this.state.model.runbookSnapshot.Name} onChange={name => this.setChildState2("model", "runbookSnapshot", { Name: name })} label="Name" validate={required("Please enter a name")} />
                        </ExpandableFormSection>
                        {this.state.model.packages && this.state.model.packages.length > 0 && (
                            <ExpandableFormSection errorKey="packages" title="Packages" fillCardWidth={CardFill.FillAll} summary={this.packagesSummary()} help="Select package(s) for this snapshot">
                                <div className={styles.packageTableContainer}>
                                    <DataTable className={cn(styles.packageTable, packagesTableClass)}>
                                        <DataTableHeader>
                                            <DataTableRow>
                                                <DataTableHeaderColumn>
                                                    <div className={styles.actionName}>Step</div>
                                                    Package
                                                </DataTableHeaderColumn>
                                                <DataTableHeaderColumn>
                                                    <ToolTip key="latest" content="The most recent package that we could find in the package feed that matches channel rules">
                                                        <ExternalLink href="LatestPackage">Latest</ExternalLink>
                                                        {this.state.model.packages && this.state.model.packages.length > 1 && (
                                                            <React.Fragment>
                                                                <br />
                                                                <Note>
                                                                    <a href="#" onClick={(e: any) => this.setAllPackageVersionsTo(e, VersionType.latest, null!, false)}>
                                                                        Select all
                                                                    </a>
                                                                </Note>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolTip>
                                                </DataTableHeaderColumn>
                                                <DataTableHeaderColumn>
                                                    Specific
                                                    {this.state.model.packages && this.state.model.packages.length > 1 && this.state.model.runbookSnapshot && this.state.model.runbookSnapshot.Name && (
                                                        <React.Fragment>
                                                            <br />
                                                            <Note>
                                                                <a href="#" onClick={(e: any) => this.setAllPackageVersionsTo(e, VersionType.specific, this.state.model!.runbookSnapshot.Name, true)}>
                                                                    Select current snapshot version
                                                                </a>
                                                            </Note>
                                                        </React.Fragment>
                                                    )}
                                                </DataTableHeaderColumn>
                                            </DataTableRow>
                                        </DataTableHeader>
                                        <DataTableBody>
                                            {this.state.model && this.state.model.packages && (
                                                <LoadMoreWrapper
                                                    items={this.state.model.packages}
                                                    renderLoadMore={children => {
                                                        return (
                                                            <DataTableRow>
                                                                <DataTableRowColumn colSpan={4}>{children}</DataTableRowColumn>
                                                            </DataTableRow>
                                                        );
                                                    }}
                                                    renderItem={(pack, index) => (
                                                        <DataTableRow key={pack.ActionName} className={packageRowClass}>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.packageColumn)}>
                                                                <div className={styles.actionName}>
                                                                    {pack.ActionName}
                                                                    {!!pack.PackageReferenceName && <span>/{pack.PackageReferenceName}</span>}
                                                                </div>
                                                                <ToolTip key="packageId" content={pack.ProjectName ? pack.ProjectName : pack.PackageId + " from " + pack.FeedName}>
                                                                    {pack.ProjectName ? pack.ProjectName : pack.PackageId}
                                                                </ToolTip>
                                                            </DataTableRowColumn>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.latestColumn)}>
                                                                {this.buildRadioButton(pack, pack.LatestVersion, VersionType.latest, this.state.model!)}
                                                            </DataTableRowColumn>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.specificColumn)}>
                                                                <div className={styles.specificVersionDiv}>
                                                                    <div className={styles.inlineDiv}>{this.buildRadioButton(pack, pack.SpecificVersion, VersionType.specific, this.state.model!)}</div>
                                                                    <div className={styles.inlineDiv}>
                                                                        <div className={styles.editVersionArea}>
                                                                            <DebounceText
                                                                                id={pack.ActionName}
                                                                                debounceDelay={500}
                                                                                className={styles.versionTextbox}
                                                                                hintText="Enter a version"
                                                                                value={pack.SpecificVersion}
                                                                                onChange={version => {
                                                                                    this.specificVersionSelected(this.state.model!, pack, version);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.inlineDiv}>{this.packageVersionsButton(pack)}</div>
                                                                </div>
                                                            </DataTableRowColumn>
                                                        </DataTableRow>
                                                    )}
                                                />
                                            )}
                                        </DataTableBody>
                                    </DataTable>
                                </div>
                            </ExpandableFormSection>
                        )}
                        <ExpandableFormSection
                            errorKey="notes"
                            title="Notes"
                            summary={this.state.model.runbookSnapshot.Notes ? Summary.summary("Notes have been provided") : Summary.placeholder("No notes provided")}
                            help={this.buildRunbookSnapshotNoteHelpInfo()}
                        >
                            <MarkdownEditor value={this.state.model.runbookSnapshot.Notes} label="Notes" onChange={runbookSnapshotNotes => this.setChildState2("model", "runbookSnapshot", { Notes: runbookSnapshotNotes })} />
                        </ExpandableFormSection>
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private setAllPackageVersionsTo = (e: any, versionType: VersionType, specificVersion: string, includeConfirmation: boolean) => {
        e.preventDefault();
        if (includeConfirmation && !confirm(`This will set all packages to version ${specificVersion}. Are you sure this version exists for all the packages?`)) {
            return;
        }

        const model = this.state.model;
        const runbookSnapshot = model!.runbookSnapshot;
        runbookSnapshot.SelectedPackages = [];
        for (const selection of this.state.model!.packages) {
            selection.VersionType = versionType;
            selection.SpecificVersion = specificVersion;
            runbookSnapshot.SelectedPackages.push({
                ActionName: selection.ActionName,
                Version: specificVersion,
                PackageReferenceName: selection.PackageReferenceName,
            });
        }

        this.setState({ model });
    };

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const model = this.state.model;
            const runbookSnapshot = model!.runbookSnapshot;
            runbookSnapshot.SelectedPackages = [];
            for (const selection of this.state.model!.packages) {
                let selectedVersion = "";
                if (selection.VersionType === VersionType.latest) {
                    selectedVersion = selection.LatestVersion;
                } else if (selection.VersionType === VersionType.last) {
                    selectedVersion = selection.LastReleaseVersion;
                } else if (selection.VersionType === VersionType.specific) {
                    selectedVersion = selection.SpecificVersion;
                }

                runbookSnapshot.SelectedPackages.push({
                    ActionName: selection.ActionName,
                    Version: selectedVersion,
                    PackageReferenceName: selection.PackageReferenceName,
                });
            }

            const newRunbookSnapshot = await save(runbookSnapshot);
            const newModel = this.buildModel(newRunbookSnapshot, this.state.model!.packages);
            this.setState({
                model: newModel,
                cleanModel: cloneDeep(newModel),
                redirect: true,
            });
        });

        async function save(runbookSnapshot: RunbookSnapshotResource) {
            if (runbookSnapshot.Id) {
                return repository.RunbookSnapshots.modify(runbookSnapshot);
            }

            // New = publishing.
            return repository.RunbookSnapshots.create(runbookSnapshot, { publish: true });
        }
    };

    private packageVersionsButton = (pack: PackageEditInfo) => {
        const openDialog = (disabled: boolean) => (
            <OpenDialogButton type={ActionButtonType.Secondary} wideDialog={true} disabled={disabled} label="Select Version">
                <PackageListDialogContent
                    pack={pack}
                    onVersionSelected={version => {
                        this.specificVersionSelected(this.state.model!, pack, version);
                    }}
                    channelFilters={{}}
                />
            </OpenDialogButton>
        );
        if (this.state.feeds && this.state.feeds[pack.FeedId]) {
            return openDialog(false);
        }
        return <ToolTip content="No feed available. Package step may be using a variable as feed.">{openDialog(true)}</ToolTip>;
    };

    private packagesSummary = () => {
        if (!this.state.model!.packages || this.state.model!.packages.length === 0) {
            return Summary.placeholder("No package is included");
        }

        const packageVersions = this.state.model!.packages.map(p => this.getPackageInfoVersion(p));

        if (packageVersions.length === 1) {
            return Summary.summary(
                packageVersions[0] ? (
                    "1 package included, at version " + packageVersions[0]
                ) : (
                    <span>
                        1 package included, <strong>no version specified</strong>
                    </span>
                )
            );
        }

        const firstVersion = packageVersions.find(p => !!p);
        const noneHaveVersion = !firstVersion;
        const allOnSameVersion = firstVersion && packageVersions.every(p => p === firstVersion);
        const numberWithNoVersion = packageVersions.filter(p => !p).length;
        const packagesIncluded = packageVersions.length + " packages included";
        const noVersionSummary = numberWithNoVersion ? (
            <span>
                ,{" "}
                <strong>
                    {numberWithNoVersion} {numberWithNoVersion === 1 ? "has" : "have"} no version selected
                </strong>
            </span>
        ) : (
            <span />
        );
        const versionSummary = allOnSameVersion ? ", all at version " + firstVersion : noneHaveVersion ? "" : ", with a mix of versions";
        return Summary.summary(
            <span>
                {packagesIncluded}
                {versionSummary}
                {noVersionSummary}
            </span>
        );
    };

    private getPackageInfoVersion(info: PackageEditInfo): string {
        return info.VersionType === VersionType.specific ? info.SpecificVersion : info.LatestVersion;
    }

    private disableDirtyFormCheck = () => {
        // don't want "dirty" to be triggered by the version being auto populated or channel from route param
        return this.state.cleanModel && isEqual(this.state.defaultCheckModel, this.state.model);
    };

    private buildRadioButton(pack: PackageEditInfo, version: string, type: VersionType, model: RunbookSnapshotModel) {
        if (!pack.IsResolvable && type === VersionType.latest) {
            return <div />;
        }
        return (
            <RadioButtonGroup
                className={styles.radioButtonContainer}
                value={type}
                onChange={item => {
                    this.packageVersionChanged(model, pack, version, type);
                }}
            >
                <RadioButton className={styles.myRadioButton} value={pack.VersionType} label={type === VersionType.specific ? "" : version} />
            </RadioButtonGroup>
        );
    }

    private buildRunbookSnapshotNoteHelpInfo = () => {
        const helpInfo = "Enter a summary of what has changed, such as which features were added and which bugs were fixed. " + "These notes will be shown on the snapshot page. You can edit these notes later.";
        return helpInfo;
    };

    private specificVersionSelected = (model: RunbookSnapshotModel, pack: PackageEditInfo, version: string) => {
        pack.SpecificVersion = version;
        this.packageVersionChanged(model, pack, version, VersionType.specific);
    };

    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (!this.state.isNew) {
            await repository.RunbookSnapshots.del(this.state.model!.runbookSnapshot);
            this.setState(state => {
                return {
                    model: null,
                    cleanModel: null,
                    deleted: true,
                };
            });
            return true;
        } else {
            return false;
        }
    };

    private loadRunbookProcess = async (processId: string) => {
        const runbookProcess = await repository.RunbookProcess.get(processId);
        return runbookProcess;
    };

    private async loadTemplate(model: RunbookSnapshotModel, runbookProcess: RunbookProcessResource) {
        const template = await repository.RunbookProcess.getRunbookSnapshotTemplate(runbookProcess, null!);
        if (!model.runbookSnapshot.Id) {
            if (template.NextNameIncrement) {
                model.runbookSnapshot.Name = template.NextNameIncrement;
            }
        }

        const existingSelections: { [actionName: string]: string } = {};
        if (model.runbookSnapshot.SelectedPackages) {
            for (const p of model.runbookSnapshot.SelectedPackages) {
                existingSelections[p.ActionName] = p.Version;
            }
        }

        const selectionByFeed: { [feedId: string]: PackageEditInfo[] } = {};
        const packageSelections = [];
        for (const p of template.Packages) {
            const specificVersion = existingSelections[p.ActionName] ? existingSelections[p.ActionName] : "";
            const isResolvable = p.IsResolvable;
            const lastReleaseVersion = p.VersionSelectedLastRelease;
            const selection: PackageEditInfo = {
                ActionName: p.ActionName,
                PackageReferenceName: p.PackageReferenceName,
                PackageId: p.PackageId,
                ProjectName: p.ProjectName,
                FeedId: p.FeedId,
                FeedName: p.FeedName,
                LatestVersion: "",
                SpecificVersion: specificVersion,
                IsResolvable: isResolvable,
                LastReleaseVersion: lastReleaseVersion,
                VersionType: specificVersion ? VersionType.specific : isResolvable ? VersionType.latest : lastReleaseVersion ? VersionType.last : VersionType.specific,
                IsLastReleaseVersionValid: !isBound(p.FeedId),
            };
            packageSelections.push(selection);

            if (selection.IsResolvable) {
                if (!selectionByFeed[selection.FeedId]) {
                    selectionByFeed[selection.FeedId] = [];
                }
                selectionByFeed[selection.FeedId].push(selection);
            }
        }

        await this.setStateAsync({ ...this.state, template });
        await this.loadVersions(model, selectionByFeed); // This function depends on template being in state.

        model.packages = packageSelections;
        this.setState({ model });
        if (!model.runbookSnapshot.Name) {
            this.props.setExpanderState(versionExpanderKey, true);
        }
    }

    private setVersionSatisfaction = (pkg: PackageEditInfo, versionType: VersionType) => {
        if (versionType) {
            pkg.VersionType = versionType;
        }
    };

    private loadVersions(model: RunbookSnapshotModel, selectionsByFeed: Dictionary<PackageEditInfo[]>): Promise<boolean> {
        const memoizedRepositoryFeedsGet = _.memoize((id: string) => repository.Feeds.get(id));

        const checkForRuleSatisfaction = async (selection: PackageEditInfo, filters: { versionRange?: string; preReleaseTag?: string }, feedType: FeedType) => {
            if (selection.LastReleaseVersion) {
                const result = await this.memoizedRepositoryChannelsRuleTest(selection.LastReleaseVersion, filters.versionRange!, filters.preReleaseTag!, feedType!);
                selection.IsLastReleaseVersionValid = result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag;
            } else {
                selection.IsLastReleaseVersionValid = false;
            }
        };

        const getPackageVersion = async (feedId: string): Promise<any> => {
            const feed = await memoizedRepositoryFeedsGet(feedId);
            const selections = selectionsByFeed[feedId];

            const packageSearchGroups = groupBy(
                selections.map(selection => ({ selection, filter: {} })),
                ({ selection, filter }) => selection.PackageId + JSON.stringify(filter || {})
            );

            const t = Object.values(packageSearchGroups).map(async sameFilteredPackages => {
                const runbookSnapshots = (
                    await repository.Feeds.searchPackageVersions(feed, sameFilteredPackages[0].selection.PackageId, {
                        ...sameFilteredPackages[0].filter,
                        take: 1,
                    })
                ).Items;

                return sameFilteredPackages.map(async ({ selection, filter }) => {
                    await checkForRuleSatisfaction(selection, filter, feed.FeedType);
                    if (runbookSnapshots.length === 0) {
                        // no latest version found
                        selection.IsResolvable = false;
                        // Docker feeds may not conform to semver, in which case there will be no valid versions.
                        // However you can manually enter a version like "latest", and this will be shown as the
                        // last version. It is convenient to select that last version rather than default to
                        // the specific version field.
                        selection.VersionType = VersionType.specific;
                        return this.setVersionSatisfaction(selection, null!);
                    }

                    const pkg = runbookSnapshots[0];
                    selection.LatestVersion = pkg.Version;
                    if (!model.runbookSnapshot.Id) {
                        return this.packageVersionChanged(model, selection, pkg.Version, null!);
                    }

                    return this.setVersionSatisfaction(selection, null!);
                });
            });
            return Promise.all(flatten(await Promise.all(t)));
        };

        return this.doBusyTask(async () => {
            return Promise.all(
                keys(selectionsByFeed)
                    .filter(f => !isBound(f))
                    .map(f => getPackageVersion(f))
            );
        });
    }

    private packageVersionChanged = (m: RunbookSnapshotModel, pkg: PackageEditInfo, version: string, versionType: VersionType) => {
        const model = { ...m };
        if (versionType) {
            pkg.VersionType = versionType;
            if (versionType === VersionType.specific) {
                pkg.SpecificVersion = version;
            }
        }

        if (!isBound(pkg.FeedId) && this.state.feeds) {
            const feed = this.state.feeds[pkg.FeedId];
            if (feed) {
                this.setVersionSatisfaction(pkg, versionType);
            }
        }

        this.setState({ model });
    };

    private buildModel(runbookSnapshot: RunbookSnapshotResource, packageSelections: PackageEditInfo[]): RunbookSnapshotModel {
        const model: RunbookSnapshotModel = {
            packages: packageSelections,
            runbookSnapshot,
        };
        return model;
    }
}

export default withRunbookContext(withProjectContext(ControlExpanders(RunbookSnapshotEditInternal)));
