import releaseRouteLinks from "../Releases/ReleasesRoutes/releaseRouteLinks";
import { ProjectOrSummaryResource } from "client/resources/projectResource";
import { DashboardProjectResource } from "client/resources/dashboardProjectResource";
import { channel, trigger, scheduledTrigger, runbook, process } from "../routeLinkHelpers";
import { deploymentLinks } from "../DeploymentsRoute";
import { opsRouteLinks } from "../OperationsRoute";

export default (root: string) => ({
    v3projectRoutes: {
        newProject: `${root}/projects/new`,
    },
    projects: {
        root: `${root}/projects`,
        filteredByGroup: (projectGroupId: string) => `${root}/projects?projectGroupId=${projectGroupId}`,
    },
    trigger: trigger(root),
    channel: channel(root),
    release: (releaseId: string) => `${root}/releases/${releaseId}`,
    runbookSnapshot: (runbookSnapshotId: string) => `${root}/snapshots/${runbookSnapshotId}`, // Needed for audit-screen document redirection.
    runbook: runbook(root), // Needed for audit-screen document redirection.
    project: (projectSlug: string | ProjectOrSummaryResource | DashboardProjectResource) => {
        if (!!projectSlug && typeof projectSlug !== "string") {
            projectSlug = projectSlug.Slug;
        }
        const specificRoot = `${root}/projects/${projectSlug}`;
        return {
            root: specificRoot,
            ...releaseRouteLinks(`${specificRoot}/releases`),
            overview: `${specificRoot}/overview`,
            settings: {
                root: `${specificRoot}/settings`,
                versionControl: `${specificRoot}/settings/versioncontrol`,
            },
            tasks: `${specificRoot}/tasks`,
            channels: `${specificRoot}/channels`,
            channel: channel(`${specificRoot}/channels`),
            operations: opsRouteLinks(`${specificRoot}/operations`),
            triggers: `${specificRoot}/triggers`,
            trigger: trigger(`${specificRoot}/triggers`),
            scheduledTrigger: scheduledTrigger(`${specificRoot}/triggers`),
            scheduledTriggers: {
                root: `${specificRoot}/triggers`,
                new: `${specificRoot}/triggers/scheduled/create`,
            },
            variables: {
                root: `${specificRoot}/variables`,
                projectTemplates: `${specificRoot}/variables/projectvariabletemplates`,
                commonTemplates: `${specificRoot}/variables/commonvariabletemplates`,
                library: `${specificRoot}/variables/library`,
                preview: `${specificRoot}/variables/preview`,
                all: `${specificRoot}/variables/all`,
            },
            vcsConnectivityCheck: `${specificRoot}/vcs/connectivityCheck`,
            deployments: deploymentLinks(`${specificRoot}/deployments`),
            childStepTemplates: (parentStepId: string) => ({
                root: `${specificRoot}?childStepTemplates=1&parentStepId=${parentStepId}`,
            }),
            stepTemplates: `${specificRoot}?stepTemplates=1`,
        };
    },
});
