import ToolTip from "../ToolTip";
import * as React from "react";
const styles = require("./style.less");
import CloseButton from "components/Button/CloseButton";
import { useSelector } from "react-redux";
import { pageConfigSelector } from "components/PageLayout/reducers/pageLayoutArea";
import ActionButton, { ActionButtonType } from "components/Button";
import { useDevToolsDispatch, useDevToolsState, actions } from "components/DevTools/DevToolsContext";

interface DevBannerProps {
    onClose(): void;
}

const DevBanner: React.FC<DevBannerProps> = ({ onClose }) => {
    const { page } = useSelector(pageConfigSelector);
    const handleClose = React.useCallback(onClose, [onClose]);

    const dispatcher = useDevToolsDispatch();
    const stateContext = useDevToolsState();

    return (
        <div className={styles.devBanner}>
            <ToolTip
                content={
                    <div>
                        <p>
                            Current page: {page && page.Name} <i>({page && page.Id})</i>
                        </p>
                    </div>
                }
            >
                Current Page --{page && `- ${page.Id}`} ---{" "}
                <span>
                    <ActionButton type={ActionButtonType.Ternary} onClick={() => dispatcher?.dispatch(actions.toggleDrawer())} label={stateContext?.drawer.open ? "Close Dev Tools" : "Open Dev Tools"} />
                </span>
            </ToolTip>

            <div className={styles.closeBanner}>
                <CloseButton onClose={handleClose} />
            </div>
        </div>
    );
};

export default DevBanner;
