/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { default as Summary } from "components/form/Sections/Summary";
import { PropertyValueResource, ControlType } from "client/resources";
import selectOptionsToItems, { Options } from "components/form/Select/Options";
import ActionTemplateParameterInput, { ActionTemplateParameterInputProps, isProjectSourceItems } from "./ActionTemplateParameterInput";
import ExpandableFormSection from "../form/Sections/ExpandableFormSection";
import { getHelpText, getLabel, hasParameterChanged } from "./ActionTemplateParameterInputRenderer";
import FeedResource from "client/resources/feedResource";
import { JsonUtils } from "utils/jsonUtils";
import { PackageReference } from "client/resources/packageReference";

type HelpTextMarkupProvider = (label: string) => string;

export default class ActionTemplateParameterInputExpandableFormElement extends React.Component<
    ActionTemplateParameterInputProps & {
        isExpandedByDefault?: boolean;
        customHelpText?: HelpTextMarkupProvider;
    },
    never
> {
    shouldComponentUpdate(newProps: ActionTemplateParameterInputProps) {
        return hasParameterChanged(newProps, this.props);
    }
    render() {
        const { isExpandedByDefault, customHelpText, ...inputProps } = this.props;
        const parameter = inputProps.parameter;
        const options = selectOptionsToItems(parameter.DisplaySettings["Octopus.SelectOptions"]);
        const packages = isProjectSourceItems(inputProps.sourceItems) ? inputProps.sourceItems.packages : inputProps.sourceItems;
        const feeds = packages?.feeds ?? [];
        const type = parameter.DisplaySettings["Octopus.ControlType"];
        const packageParameterIsUsed = packages ? !!packages.items.find(p => p.Properties["PackageParameterName"] === parameter.Name) : false;
        const label = getLabel(parameter);
        return (
            <ExpandableFormSection
                errorKey={parameter.Name}
                title={label}
                isExpandedByDefault={isExpandedByDefault}
                help={getHelpText(parameter, customHelpText, packageParameterIsUsed)}
                summary={createSummaryFor(label, type!, options, feeds, packageParameterIsUsed)(this.props.value)}
            >
                <ActionTemplateParameterInput {...inputProps} />
            </ExpandableFormSection>
        );
    }
}

function createSummaryFor(label: string, type: ControlType, options: Options, feeds: FeedResource[], packageParameterIsUsed?: boolean) {
    return (value: PropertyValueResource | undefined) => {
        if (type === ControlType.Package && !packageParameterIsUsed) {
            return Summary.placeholder("This parameter is not used by the step");
        }

        if (value === undefined || value === null) {
            return Summary.placeholder("Provide " + label);
        }

        if (typeof value === "object") {
            return Summary.summary("********");
        }

        if (type === ControlType.Select) {
            const option = options.find(o => o.value === value);
            if (option) {
                return Summary.summary(option.text);
            }
        }

        if (type === ControlType.Package) {
            if (JsonUtils.tryParseJson(value)) {
                const pkg = JSON.parse(value) as Partial<PackageReference<any>>;
                if (!pkg.PackageId) {
                    return Summary.placeholder("Provide " + label);
                }

                const feed = feeds.find(f => f.Id === pkg.FeedId);
                return Summary.summary(
                    <span>
                        Package <strong>{pkg.PackageId}</strong>
                        <span>
                            &nbsp;from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                        </span>
                    </span>
                );
            }
        }

        return Summary.summary(value);
    };
}
